import { useEffect, useState, useRef } from 'react';
import { useEnvironmentsProviders } from './EnvironmentsProvider';
import Loader from '../../../../ts/components/loader/Loader';
import ReleasesList from './ReleasesList';
import classnames from 'classnames';
import translations from '../../../../ts/translations';
import ButtonPrimary from '../../../../ts/components/buttons/ButtonPrimary';
import EnvironmentSettings from './EnvironmentSettings';
import EnvironmentVariables from './EnvironmentVariables';
import EnvironmentFlows from './EnvironmentFlows';
import { TAB_TYPES } from '../../../../ts/constants';
import { connect } from 'react-redux';
import { updateTab } from '../../../actions/reduxActions/tabs';
import IntegrationEnvironments from '../../../../ts/components/admin/environments/IntegrationEnvironments';
import { isNullOrEmpty } from '../../../../ts/utils/guard';

const Environment = ({ tabs, updateTab }) => {
    const [environment, setEnvironment] = useState(null);
    const [activeTab, setActiveTab] = useState('releases');
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const modalContainerRef = useRef('');

    const url = new URL(location.href);
    const environmentId = url.searchParams.get('tab');

    const {
        environmentsLoading,
        getAllEnvironments,
        saveEnvironment,
        environments,
        addNotification,
    } = useEnvironmentsProviders();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const loadEnvironments = async () => {
            let envs = environments;
            if (isNullOrEmpty(envs)) {
                envs = await getAllEnvironments();
                if (envs) {
                    const env = Object.values(envs).find(
                        (environment) => environment.id === environmentId,
                    );
                    setEnvironment(env);
                    const thisTab = tabs.find(
                        (t) => t.type === TAB_TYPES.environment && t.isActive,
                    );
                    updateTab({
                        key: thisTab.key,
                        title: env.name,
                    });
                }
            }
        };

        loadEnvironments();
    }, []);

    const save = (environment) => {
        setHasSubmitted(true);
        if (isValid(environment)) {
            saveEnvironment(environment);
        }
    };

    const isValid = (environment) => {
        if (isNullOrEmpty(environment.name)) {
            return false;
        }
        if (isNullOrEmpty(environment.defaultPlayerName)) {
            return false;
        }
        if (isNullOrEmpty(environment.defaultThemeName)) {
            return false;
        }

        return true;
    };

    const updateEnvironment = (environment) => {
        setEnvironment(environment);
    };

    const environmentTabs = (
        <ul className="nav nav-tabs">
            <li className={classnames({ active: activeTab === 'releases' })}>
                <button
                    className="nav-tab"
                    onClick={() => setActiveTab('releases')}
                    title={translations.ENVIRONMENT_releases_tab_title}
                    role="tab"
                    name="Releases"
                    type="button"
                >
                    Releases
                </button>
            </li>
            <li className={classnames({ active: activeTab === 'flows' })}>
                <button
                    className="nav-tab"
                    onClick={() => setActiveTab('flows')}
                    title={translations.ENVIRONMENT_flows_tab_title}
                    role="tab"
                    name="Flows"
                    type="button"
                >
                    Flows
                </button>
            </li>
            <li className={classnames({ active: activeTab === 'variables' })}>
                <button
                    className="nav-tab"
                    onClick={() => setActiveTab('variables')}
                    title={translations.ENVIRONMENT_variable_tab_title}
                    role="tab"
                    name="Variables"
                    type="button"
                >
                    Variables
                </button>
            </li>
            <li className={classnames({ active: activeTab === 'settings' })}>
                <button
                    className="nav-tab"
                    onClick={() => setActiveTab('settings')}
                    title={translations.ENVIRONMENT_settings_tab_title}
                    role="tab"
                    name="Settings"
                    type="button"
                >
                    Settings
                </button>
            </li>
            <li className={classnames({ active: activeTab === 'integrationAccounts' })}>
                <button
                    className="nav-tab"
                    onClick={() => setActiveTab('integrationAccounts')}
                    title={translations.ENVIRONMENT_integration_accounts_tab_title}
                    role="tab"
                    name="Integration Accounts"
                    type="button"
                >
                    Integration Accounts
                </button>
            </li>
        </ul>
    );

    return (
        <div ref={modalContainerRef} className="page-wrapper">
            <div className="admin-page">
                <h1>{!environmentsLoading && environment ? environment.name : ''}</h1>
                {environmentTabs}
                {(() => {
                    if (activeTab === 'releases') {
                        if (environmentsLoading) {
                            return <Loader message="Loading environment" />;
                        }
                        if (environment) {
                            return (
                                <ReleasesList
                                    passedEnvironments={environments}
                                    environmentId={environment.id}
                                    modalContainerRef={modalContainerRef}
                                />
                            );
                        }
                    } else if (activeTab === 'flows') {
                        return <EnvironmentFlows environment={environment} />;
                    } else if (activeTab === 'variables') {
                        return (
                            <EnvironmentVariables
                                environmentId={environment.id}
                                modalContainerRef={modalContainerRef}
                            />
                        );
                    } else if (activeTab === 'settings') {
                        return (
                            <EnvironmentSettings
                                environment={environment}
                                updateEnvironment={updateEnvironment}
                                hasSubmitted={hasSubmitted}
                            />
                        );
                    } else if (activeTab === 'integrationAccounts') {
                        return (
                            <IntegrationEnvironments
                                environment={environment}
                                updateEnvironment={updateEnvironment}
                                hasSubmitted={hasSubmitted}
                                addNotification={addNotification}
                            />
                        );
                    }
                })()}
            </div>
            {activeTab === 'settings' || activeTab === 'integrationAccounts' ? (
                <div className="flex column-footer padding-default border-top config-footer">
                    <ButtonPrimary
                        title={translations.ENVIRONMENT_save_button_title}
                        onClick={() => save(environment)}
                        className="flex-child-right"
                    >
                        {translations.ENVIRONMENT_save}
                    </ButtonPrimary>
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = ({ tabs }) => ({
    tabs,
});

const mapDispatchToProps = {
    updateTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(Environment);
