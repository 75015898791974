import { ExStructuredListCol } from '@boomi/exosphere';
import type { ResponseHeaders, SingleFault } from '../../../../../types';

interface Props {
    faultKeyName: string;
    fault: SingleFault;
}

const RootFaultResponseHeaders = ({ faultKeyName, fault }: Props) => {
    return (
        <>
            {Object.keys(fault[faultKeyName]).map((responseHeaderKey) => {
                const responseHeaders = fault[faultKeyName] as ResponseHeaders;
                return (
                    <ExStructuredListCol key={responseHeaderKey}>
                        <div>
                            <b>{responseHeaderKey}</b>
                        </div>
                        <div className="ex-fs-micro">{responseHeaders[responseHeaderKey]}</div>
                    </ExStructuredListCol>
                );
            })}
        </>
    );
};

export default RootFaultResponseHeaders;
