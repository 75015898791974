import {
    useState,
    createContext,
    useContext,
    useReducer,
    type ReactNode,
    type Dispatch,
    type SetStateAction,
    type Reducer,
} from 'react';
import breadCrumbReducer, { BREADCRUMB_ACTION_TYPES } from '../../../../js/reducers/breadcrumbs';
import translations from '../../../../ts/translations';

import { populateServiceValueRequest } from '../../../utils/ajax';
import {
    deleteService,
    getAllServicesV2,
    getServiceTypesAndActions,
} from '../../../sources/service';
import type { BreadcrumbLabels, ServiceViews } from '../ServiceConstants';
import type { AddNotification, Filter, OrderDirection, Paging } from '../../../types';
import type {
    DescribeServiceTypesAndActions,
    ConfigurationAndSelection,
    ServiceElementResponseAPI,
    ServiceValueRequestAPI,
} from '../../../types/service';
import type { BreadCrumbs } from '../ServiceController';
import { stringReplace } from '../../../utils/string';
import { isNullOrEmpty } from '../../../utils/guard';

// ServicesPaging will extend Paging because all props overlap between them.
// Further extending with Filter won't work because Filter and Paging both have page
// props that are not compatible.
interface ServicesPaging extends Paging {
    search: string | undefined;
    orderBy: string | undefined;
    orderDirection: OrderDirection | undefined | null;
}

// Extending this with ServiceElementRequestAPI causes problems.
// This seems to be a standalone structure that happens to also contain
// some of the same props as ServiceElementRequestAPI but also has its own
// props like url, name, description, etc. that don't exist on ServiceElementRequestAPI.
export interface NewServiceData {
    url?: string | undefined;
    name?: string | undefined;
    description?: ReactNode;
    comments?: string | undefined;
    pathToService?: string | undefined;
    baseUrl?: string | undefined;
    atomType?: string;
    httpAuthenticationUsername?: string | null | undefined;
    httpAuthenticationPassword?: string | null | undefined;
    httpAuthenticationClientCertificateReference?: string | null | undefined;
    httpAuthenticationClientCertificatePasswordReference?: string | null | undefined;
    // these are added in switchToRefreshServiceConfig (not sure why)
    username?: string;
    password?: string;
    id?: string | null;
    identityProviderId?: string | null;
    canHaveIdentity?: boolean;
}

export interface ServicesProviderContext {
    fetchServices: (filters: Filter) => Promise<void>;
    services: ServiceElementResponseAPI[];
    paging: ServicesPaging;
    servicesState: ServicesProviderState;
    setServicesState: Dispatch<SetStateAction<ServicesProviderState>>;
    initialState: ServicesProviderState;
    pushBreadCrumb: (id: ServiceViews, content: BreadcrumbLabels) => void;
    breadCrumbs: BreadCrumbs;
    setActiveBreadCrumb: (activeItemId: string) => void;
    tenantId: string;
    addNotification: AddNotification;
    fetchServiceTypesAndActions: (configValueIds: ConfigurationAndSelection[]) => Promise<void>;
    setServiceToDelete: Dispatch<SetStateAction<ServiceElementResponseAPI | null>>;
    serviceToDelete: ServiceElementResponseAPI | null;
    onServiceDeleteConfirm: () => Promise<void>;
    proceedToConfigureOpenApi: (configurationValues: ConfigurationAndSelection[]) => void;
}

interface ServicesProviderProps {
    addNotification: AddNotification;
    tenantId: string;
    children: ReactNode;
}

export interface ServicesProviderState {
    newServiceData: NewServiceData;
    currentServiceData?: ServiceElementResponseAPI | null;
    serviceConfigurationValues?: ServiceValueRequestAPI[] | null;
    serviceTypesAndActions?: DescribeServiceTypesAndActions | null;
    populatedServiceValueRequests?: unknown;
    atomType?: string | null;
    isLoading?: boolean;
    selectedValues?: ConfigurationAndSelection[] | null;
}

const Context = createContext<ServicesProviderContext | undefined>(undefined);

const ServicesProvider = ({ addNotification, tenantId, children }: ServicesProviderProps) => {
    const initialState: ServicesProviderState = {
        // Basic user-entered information about a new service
        newServiceData: {
            url: undefined,
            name: undefined,
            description: undefined,
            httpAuthenticationUsername: null,
            httpAuthenticationPassword: null,
            httpAuthenticationClientCertificateReference: null,
            httpAuthenticationClientCertificatePasswordReference: null,
            canHaveIdentity: false,
        },
        serviceConfigurationValues: null,
        serviceTypesAndActions: null,
        // Information about an installed Service from /service api
        currentServiceData: null,
        // A service value request populated with configuration values for a service, used for getServiceTypesAndActions & installService
        populatedServiceValueRequests: null,
        // The type of atom they selected when installing their service
        atomType: null,
        isLoading: false,
        selectedValues: null,
    };

    const [services, setServices] = useState([] as ServiceElementResponseAPI[]);
    const [servicesState, setServicesState] = useState<ServicesProviderState>(initialState);
    const [serviceToDelete, setServiceToDelete] = useState<ServiceElementResponseAPI | null>(null);

    // Temporary solution until the breadcrumb reducer is ported to TS.
    const [breadCrumbs, setBreadCrumbs] = useReducer<Reducer<BreadCrumbs, unknown>>(
        breadCrumbReducer,
        {
            trail: [
                {
                    id: 'ServiceList',
                    content: 'Connectors',
                    onClick: () => {
                        setActiveBreadCrumb('ServiceList');
                    },
                },
            ],
            activeItemId: 'ServiceList',
        },
    );

    const [paging, updatePaging] = useState<ServicesPaging>({
        page: 1,
        pageSize: 20,
        total: 0,
        search: '',
        orderBy: 'dateModified',
        orderDirection: 'DESC',
    });

    const pushBreadCrumb = (id: ServiceViews, content: BreadcrumbLabels) => {
        setBreadCrumbs({
            type: BREADCRUMB_ACTION_TYPES.push_breadcrumb,
            payload: {
                id,
                content,
                onClick: () => {
                    setActiveBreadCrumb(id);
                },
            },
        });
    };

    const setActiveBreadCrumb = (activeItemId: string) => {
        setBreadCrumbs({
            type: BREADCRUMB_ACTION_TYPES.set_active_breadcrumb,
            payload: {
                activeItemId,
            },
        });
    };

    const onServiceDeleteConfirm = async () => {
        setServicesState({ ...servicesState, isLoading: true });
        const { id, developerName } = serviceToDelete as ServiceElementResponseAPI;
        try {
            await deleteService(id);
            const remainingServices = services.filter((service) => service.id !== id);

            setServices(remainingServices);
            addNotification({
                type: 'success',
                message: stringReplace(translations.SC_successful_delete, developerName),
                isPersistent: false,
            });
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).message,
                isPersistent: true,
            });
        } finally {
            setServicesState({ ...servicesState, isLoading: false });
        }
    };

    const fetchServices = async (filters: Filter) => {
        setServicesState({ ...servicesState, isLoading: true });

        try {
            const servicesResults = await getAllServicesV2(filters);
            const { items, _meta } = servicesResults;

            setServices(items);

            const { search, orderBy, orderDirection } = filters;

            updatePaging({
                ..._meta,
                search,
                orderBy,
                orderDirection,
            });
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).message,
                isPersistent: true,
            });
        } finally {
            setServicesState({ ...servicesState, isLoading: false });
        }
    };

    const proceedToConfigureOpenApi = (configurationValues: ConfigurationAndSelection[]) => {
        setServicesState({ ...servicesState, selectedValues: configurationValues });
        pushBreadCrumb('OpenApiConfiguration', 'Configure OpenAPI');
    };

    const fetchServiceTypesAndActions = async (configValueIds: ConfigurationAndSelection[]) => {
        setServicesState({ ...servicesState, isLoading: true });

        const { currentServiceData, newServiceData, serviceConfigurationValues } = servicesState;

        const doesServiceAlreadyExist = !isNullOrEmpty(currentServiceData);

        const populatedServiceValueRequests = serviceConfigurationValues
            ? populateServiceValueRequest(serviceConfigurationValues, configValueIds)
            : [];

        const basicUsername = doesServiceAlreadyExist
            ? currentServiceData?.httpAuthenticationUsername
            : newServiceData?.httpAuthenticationUsername;

        const basicPassword = doesServiceAlreadyExist
            ? currentServiceData?.httpAuthenticationPassword
            : newServiceData?.httpAuthenticationPassword;

        const httpAuthenticationClientCertificateReference = doesServiceAlreadyExist
            ? currentServiceData?.httpAuthenticationClientCertificateReference
            : newServiceData?.httpAuthenticationClientCertificateReference;
        const httpAuthenticationClientCertificatePasswordReference = doesServiceAlreadyExist
            ? currentServiceData?.httpAuthenticationClientCertificatePasswordReference
            : newServiceData?.httpAuthenticationClientCertificatePasswordReference;

        try {
            const serviceTypesAndActions = await getServiceTypesAndActions({
                uri: doesServiceAlreadyExist ? currentServiceData?.uri : newServiceData?.url,
                basicUsername,
                basicPassword,
                httpAuthenticationClientCertificateReference,
                httpAuthenticationClientCertificatePasswordReference,
                configurationValues: populatedServiceValueRequests,
                id: currentServiceData?.id,
                identityProviderId: newServiceData.identityProviderId,
            });

            pushBreadCrumb('ServicePreview', 'Preview Types and Actions');

            setServicesState({
                ...servicesState,
                serviceTypesAndActions,
                serviceConfigurationValues: populatedServiceValueRequests,
                isLoading: false,
            });
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).message,
                isPersistent: true,
            });

            setServicesState({
                ...servicesState,
                isLoading: false,
            });
        }
    };

    const contextValue = {
        fetchServices,
        services,
        paging,
        servicesState,
        setServicesState,
        initialState,
        pushBreadCrumb,
        breadCrumbs,
        setActiveBreadCrumb,
        tenantId,
        addNotification,
        fetchServiceTypesAndActions,
        setServiceToDelete,
        serviceToDelete,
        onServiceDeleteConfirm,
        proceedToConfigureOpenApi,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useServices = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useServices must be used within a ServicesProvider');
    }
    return context;
};

export { ServicesProvider, useServices };
