import { createRef, Component } from 'react';
import { connect } from 'react-redux';
import {
    fetchActivatedFlows,
    selectExport,
    exportPackage,
    exportWithPasswords,
    sharePackage,
    setExportedFlow,
    setImportToken,
    showImportConfirmation,
    setImportedPackage,
    importPackage,
    importSharedPackage,
    finishImport,
} from '../actions/reduxActions/flows';
import ConfirmModal from '../../ts/components/generic/modal/ConfirmModal';
import translations from '../../ts/translations';
import { pathOr } from 'ramda';
import '../../../css/package.less';
import { addNotification, notifySuccess, notifyError } from '../actions/reduxActions/notification';
import { NOTIFICATION_TYPES } from '../../ts/constants';
import store from '../store/store';
import DependencyTable from '../../ts/components/generic/DependencyTable';
import ClipboardInput from '../../ts/components/inputs/ClipboardInput';
import FormGroup from '../../ts/components/generic/FormGroup';
import ComponentWithTooltip from '../../ts/components/generic/tooltip/ComponentWithTooltip';
import ButtonPrimary from '../../ts/components/buttons/ButtonPrimary';
import { getAllEnvironments } from '../../ts/sources/environments';
import TranslationBase from '../../ts/components/translations/TranslationsBase';
import FileDrop from '../../ts/components/generic/upload/FileDrop';
import ThemeImportExport from '../../ts/components/theme/ThemeImportExport';
import Loader from '../../ts/components/loader/Loader';
import { isNullOrUndefined } from '../../ts/utils/guard';
import { stringReplace } from '../../ts/utils/string';

class Package extends Component {
    state = {
        environments: null,
        environmentId: null,
        isProcessing: false,
    };

    constructor(props) {
        super(props);

        this.onEnvironmentSelected = this.onEnvironmentSelected.bind(this);
        this.onFlowSelected = this.onFlowSelected.bind(this);
        this.onFetchActivatedFlows = this.onFetchActivatedFlows.bind(this);
        this.onExport = this.onExport.bind(this);
        this.onShare = this.onShare.bind(this);
        this.onImport = this.onImport.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.retrieveNameFromFlowPackage = this.getNameFromFlowPackage.bind(this);
        this.onChangeImportToken = this.onChangeImportToken.bind(this);
        this.onIncludePasswordsChange = this.onIncludePasswordsChange.bind(this);
        this.cancelImport = this.cancelImport.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);

        this.downloadRef = createRef();
        this.props.selectExport('');
        this.modalContainerRef = createRef();
    }

    onFetchActivatedFlows(environmentId) {
        this.props.fetchActivatedFlows(this.props.tenantId, environmentId);
    }

    async componentDidMount() {
        if (this.props.tenant?.tenantSettings?.environments) {
            const environments = await getAllEnvironments();

            this.setState({
                environments,
            });
        } else {
            this.props.fetchActivatedFlows(this.props.tenantId);
        }
    }

    componentDidUpdate() {
        if (this.props.package.exportedJSON) {
            this.downloadRef.current.click();
            // Reset exported package for next download.
            this.props.setExportedFlow(null);
        }

        if (this.props.package.importSuccess) {
            const flowName = this.props.package.importSuccess.developerName
                ? ` '${this.props.package.importSuccess.developerName}' `
                : '';
            store.dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.success,
                    message: `Flow ${flowName} imported successfully`,
                    isPersistent: false,
                }),
            );
            this.props.finishImport();
        }
    }

    onChangeImportToken(e) {
        this.props.setImportToken(e.target.value);
    }

    onDownloadClick(e) {
        if (window.navigator.msSaveOrOpenBlob) {
            // is IE

            const download = this.createDownload();

            window.navigator.msSaveOrOpenBlob(download.blob, download.fileName);

            e.preventDefault();
        }
    }

    onEnvironmentSelected(e) {
        this.setState({
            environmentId: e.target.value,
        });
        this.props.selectExport('');

        if (e.target.value) {
            this.onFetchActivatedFlows(e.target.value);
        }
    }

    onFlowSelected(e) {
        const selectedFlowId = e.target.value;
        this.props.selectExport(selectedFlowId);
    }

    /**
     * Generate Sharing Token
     */
    async onShare() {
        if (this.props.package.selectedFlowId) {
            this.setState({
                isProcessing: true,
            });

            await this.props.sharePackage(
                this.props.package.selectedFlowId,
                this.props.tenantId,
                this.props.package.includePasswords,
                this.state.environmentId,
            );

            this.setState({
                isProcessing: false,
            });
        }
    }

    /**
     * Kick off Package Download
     */
    async onExport() {
        if (this.props.package.selectedFlowId) {
            this.setState({
                isProcessing: true,
            });

            await this.props.exportPackage(
                this.props.package.selectedFlowId,
                this.props.tenantId,
                this.props.package.includePasswords,
                this.state.environmentId,
            );

            this.setState({
                isProcessing: false,
            });
        }
    }

    /**
     * Import package via JSON encoded string. Usually read from a file via drag-n-drop
     * @param {boolean} force if true import regardless of any conflicts, otherwise honour conflicts
     */
    onImport(force = false) {
        if (this.props.package.importedJSON) {
            this.props.importPackage(this.props.package.importedJSON, this.props.tenantId, force);
            this.props.showImportConfirmation(false);
        }
    }

    /**
     * Import package via a sharing token
     * @param {boolean} force if true import regardless of any conflicts, otherwise honour conflicts
     */
    onImportShared = (force = false) => {
        if (this.props.package.importToken) {
            this.props.importSharedPackage(
                this.props.package.importToken,
                this.props.tenantId,
                force,
            );
            this.props.showImportConfirmation(false);
        }
    };

    onDrop(files) {
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = (response) => {
                this.confirmImport(response.target.result);
            };
            reader.readAsText(file);
        });
    }

    onIncludePasswordsChange({ target: { checked } }) {
        this.props.exportWithPasswords(checked);
    }

    getNameFromFlowPackage(flowJSON) {
        if (isNullOrUndefined(flowJSON)) {
            return null;
        }
        let flowPackage;
        try {
            flowPackage = JSON.parse(JSON.parse(flowJSON));
        } catch (_error) {
            return null;
        }
        return pathOr(null, ['flowConfiguration', 'developerName'], flowPackage);
    }

    /**
     * The confirmation handles uploading via drag-n-drop or using an import token.
     *
     * @param {string} uploadJSON optional package JSON received from the drag-n-drop.
     */
    confirmImport = (uploadJSON = null) => {
        if (uploadJSON) {
            this.props.setImportedPackage(uploadJSON);
        }
        this.props.showImportConfirmation(true);
    };

    cancelImport = () => {
        this.props.showImportConfirmation(false);
    };

    createDownload() {
        const flowName =
            this.props.flows &&
            this.props.package.selectedFlowId &&
            pathOr(
                null,
                ['developerName'],
                this.props.flows.find((flow) => flow.id.id === this.props.package.selectedFlowId),
            );

        const fileName = `${flowName}.package`;

        const blob = new Blob([this.props.package.exportedJSON || ''], {
            type: 'application/json',
        });

        return {
            fileName,
            blob,
        };
    }

    selectPackageText(event) {
        event.target.select();
    }

    render() {
        const { environments } = this.state;
        const isIE = window.navigator.msSaveOrOpenBlob;

        const download = this.createDownload();

        const downloadUrl = isIE ? '' : URL.createObjectURL(download.blob);

        let environmentOptions = '';
        if (environments) {
            environmentOptions = environments.map((environment) => (
                <option value={environment.id} key={environment.id}>
                    {environment.name}
                </option>
            ));
            environmentOptions.unshift(
                <option value="" key="no environment">
                    {translations.ENVIRONMENT_filters_select_environment}
                </option>,
            );
        }

        const options = this.props.flows.map((flow) => (
            <option value={flow.id.id} key={flow.id.id}>
                {flow.developerName}
            </option>
        ));
        options.unshift(
            <option value="" key="no flow">
                Select a Flow
            </option>,
        );

        const isFlowSelected =
            this.props.package.selectedFlowId !== null && this.props.package.selectedFlowId !== '';

        const importedFlowName = this.getNameFromFlowPackage(this.props.package.importedJSON);

        const message =
            isNullOrUndefined(importedFlowName) === false
                ? stringReplace(
                      // If there is a Flow name found, then show the flow name import text
                      translations.IMPORT_confirm_message_with_flow_name,
                      importedFlowName,
                      this.props.tenantName,
                  )
                : stringReplace(
                      // Otherwise show the import text without the flow name
                      translations.IMPORT_confirm_message,
                      this.props.tenantName,
                  );

        const confirmProps = {
            title: translations.IMPORT_confirm_title,
            messages: [message],
            onCancel: this.cancelImport,
            onConfirm: this.props.package.importedJSON
                ? () => this.onImport(false)
                : () => this.onImportShared(false),
            show: this.props.package.showImportConfirmation,
        };

        const dependencyList = this.props.package.importConflict ? (
            <DependencyTable dependents={this.props.package.importConflictDependents} />
        ) : null;

        const confirmOverwrite = {
            title: translations.IMPORT_confirm_overwrite_title,
            messages: [
                translations.IMPORT_confirm_overwrite_body_1,
                translations.IMPORT_confirm_overwrite_body_2,
                dependencyList,
                translations.IMPORT_confirm_overwrite_body_3,
                translations.IMPORT_confirm_overwrite_body_4,
            ],
            onCancel: this.cancelImport,
            onConfirm: this.props.package.importedJSON
                ? () => this.onImport(true)
                : () => this.onImportShared(true),
            show: this.props.package.importConflict,
        };

        return (
            <div className="import-export" ref={this.modalContainerRef}>
                {this.state.isProcessing ? <Loader message="Processing..." /> : null}
                <h1>Export</h1>
                <div className="export">
                    <div
                        className="form-group"
                        hidden={!this.props.tenant?.tenantSettings?.environments}
                        data-testid="environmentSelectGroup"
                    >
                        <select
                            onChange={this.onEnvironmentSelected}
                            placeholder="Select an Environment"
                            className="select-flow"
                            data-testid="environmentSelect"
                        >
                            {environmentOptions}
                        </select>
                    </div>
                    <div className="form-group">
                        <select
                            value={this.props.package.selectedFlowId || ''}
                            onChange={this.onFlowSelected}
                            placeholder="Select a Flow"
                            className="select-flow"
                            disabled={
                                this.props.tenant?.tenantSettings?.environments &&
                                !this.state.environmentId
                            }
                            data-testid="flowSelect"
                        >
                            {options}
                        </select>
                    </div>
                    <div className="form-group">
                        <ComponentWithTooltip
                            trigger={['hover', 'focus']}
                            tooltipPlacement="bottom-start"
                            tooltipContent={
                                'Select this checkbox to include passwords in the export package. By default, passwords are not included in an exported flow. Any connector passwords will need to be set once a flow has been imported as your connectors may not work as expected until they have been set.'
                            }
                            tooltipClass="import-export-tooltip"
                            tooltipArrowClass="import-export-tooltip-arrow"
                            fadeTime={0.2}
                            arrowClearance={8}
                        >
                            <label>
                                <input
                                    type="checkbox"
                                    checked={this.props.package.includePasswords}
                                    onChange={this.onIncludePasswordsChange}
                                />
                                {'Include passwords in package export'}
                            </label>
                        </ComponentWithTooltip>
                    </div>
                    <ButtonPrimary
                        onClick={this.onShare}
                        disabled={!isFlowSelected || this.state.isProcessing}
                        className="share"
                        title={translations.IMPORT_EXPORT_generate_sharing_token}
                    >
                        {translations.IMPORT_EXPORT_generate_sharing_token}
                    </ButtonPrimary>
                    <ButtonPrimary
                        onClick={this.onExport}
                        disabled={!isFlowSelected || this.state.isProcessing}
                        title={translations.IMPORT_EXPORT_download_package}
                    >
                        {translations.IMPORT_EXPORT_download_package}
                    </ButtonPrimary>
                    <div>
                        <h2>Sharing token</h2>
                        <FormGroup label={translations.IMPORT_EXPORT_sharing_token_label}>
                            <ClipboardInput
                                value={this.props.package.sharingToken || ''}
                                placeholder={translations.IMPORT_EXPORT_sharing_token_placeholder}
                                inputClassName="sharing-token"
                            />
                        </FormGroup>
                    </div>
                    <a
                        href={downloadUrl}
                        className="hidden"
                        download={download.fileName}
                        ref={this.downloadRef}
                        onClick={this.onDownloadClick}
                    >
                        download
                    </a>
                </div>

                <div className="import margin-top-large">
                    <h1>Import</h1>
                    <FileDrop
                        onChange={this.onDrop}
                        placeholder={
                            'Drop exported package file here, or click to browse to import'
                        }
                        fileTypes=".package"
                    />
                    <h2>Import Shared Package</h2>
                    <p>
                        If you've been given an import token, paste it here and the shared package
                        will be imported into your tenant.
                    </p>
                    <div className="shared">
                        <input
                            type="text"
                            className="form-control import-token"
                            onChange={this.onChangeImportToken}
                            placeholder="Enter a shared package token"
                            value={this.props.package.importToken || ''}
                        />
                        <ButtonPrimary
                            type="submit"
                            disabled={!this.props.package.importToken}
                            onClick={() => this.confirmImport()}
                        >
                            Import
                        </ButtonPrimary>
                    </div>
                </div>
                {this.props.tenant?.tenantSettings?.themes && (
                    <ThemeImportExport
                        environmentOptions={environmentOptions}
                        tenant={this.props.tenant}
                        container={this.modalContainerRef.current}
                        notifyError={this.props.notifyError}
                        notifySuccess={this.props.notifySuccess}
                    />
                )}
                <TranslationBase
                    container={this.modalContainerRef.current}
                    setIsProcessing={(isProcessing) => this.setState({ isProcessing })}
                />
                <ConfirmModal {...confirmProps} container={this.modalContainerRef.current} />
                <ConfirmModal {...confirmOverwrite} container={this.modalContainerRef.current} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const sortedFlows = Object.assign([], state.flows.activated).sort((a, b) =>
        a.developerName.toLowerCase().localeCompare(b.developerName.toLowerCase()),
    );

    // Do not include activated property in package object
    const { activated: _activated, ...rest } = state.flows;
    return {
        flows: sortedFlows,
        package: rest,
    };
};

const mapDispatchToProps = {
    fetchActivatedFlows,
    selectExport,
    exportPackage,
    exportWithPasswords,
    sharePackage,
    setExportedFlow,
    setImportToken,
    showImportConfirmation,
    setImportedPackage,
    importPackage,
    importSharedPackage,
    finishImport,
    notifySuccess,
    notifyError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Package);
