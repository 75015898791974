import { createRef } from 'react';
import translations from '../../../../ts/translations';
import LocalRuntimeOrgTenants from './LocalRuntimeOrgTenants';
import { FLOW_ORG_PAGES } from '../../../../ts/constants/organization';

/**
 * @param {Function} onSubmit Callback for saving the runtime
 * @param {Array} connectedTenantIds An array of tenant ID's connected to the runtime
 * @param {Function} onCheck Callback selecting/deselecting connected tenants
 * @param {Function} navigateTo Callback to navigate to a specific page
 *
 * @description Provides a form for creating new runtime connections
 */
const LocalRuntimeCreate = ({ onSubmit, connectedTenantIds, onCheck, navigateTo }) => {
    const connectionNameRef = createRef();

    const submit = () => {
        onSubmit(
            {
                developerName: connectionNameRef.current.value,
                tenants: connectedTenantIds.map((t) => ({ id: t })),
            },
            navigateTo,
        );
    };

    return (
        <>
            <div className="admin-page">
                <h2>{translations.LOCAL_RUNTIME_create_screen_h2}</h2>
                <p>{translations.LOCAL_RUNTIME_create_screen_h2_summary}</p>
                <h3>{translations.LOCAL_RUNTIME_create_screen_h3}</h3>
                <p>{translations.LOCAL_RUNTIME_create_screen_h3_summary}</p>

                {/* Name section */}
                <h4>{translations.LOCAL_RUNTIME_create_screen_connection}</h4>
                <div className="form-group">
                    <label htmlFor="connection-name">
                        Connection Name
                        <span className="input-required"> *</span>
                    </label>
                    <input
                        required
                        className="form-control"
                        type="text"
                        ref={connectionNameRef}
                        size="50"
                        id="connection-name"
                    />
                </div>

                {/* Tenants section */}
                <h4>{translations.LOCAL_RUNTIME_create_screen_shared_tenants}</h4>
                <p>{translations.LOCAL_RUNTIME_create_screen_shared_tenants_summary}</p>
                <p>{translations.LOCAL_RUNTIME_create_screen_number_tenants_selected_summary}</p>
                <LocalRuntimeOrgTenants onCheck={onCheck} connectedTenantIds={connectedTenantIds} />
            </div>

            {/* Footer */}
            <div className="admin-footer">
                <button
                    type="button"
                    className="btn btn-default outcome"
                    onClick={() => navigateTo(FLOW_ORG_PAGES.flowOrgAdminUI)}
                >
                    {translations.LOCAL_RUNTIME_create_screen_cancel}
                </button>
                <button
                    type="button"
                    className="btn btn-primary outcome tenant-save"
                    onClick={() => submit()}
                >
                    {translations.LOCAL_RUNTIME_create_screen_submit}
                </button>
            </div>
        </>
    );
};

export default LocalRuntimeCreate;
