import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Trash } from '@phosphor-icons/react';
import { fetchUsers } from '../../actions/reduxActions/organizations';
import { removeUser as requestLeaveOrganization } from '../../../ts/sources/organization';
import ConfirmModal from '../../../ts/components/generic/modal/ConfirmModal';
import translations from '../../../ts/translations';
import { useAuth } from '../../../ts/components/AuthProvider';
import { NOTIFICATION_TYPES } from '../../../ts/constants';
import { addNotification } from '../../actions/reduxActions/notification';
import Table from '../../../ts/components/generic/Table';
import { stringReplace } from '../../../ts/utils/string';
import { resolveOrgUserRole } from '../../../ts/utils/organization';

/**
 * @description Flow Org. joined user list
 *
 * Display a list of users that have joined the organization.
 */
const JoinedUserList = ({ organizationUsers = [], fetchUsers, addNotification, container }) => {
    const [showConfirmRemove, setShowConfirmRemove] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});

    const { fetchUser, user, tenant } = useAuth();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchUsers();
    }, []);

    const onRemoveClick = (user) => {
        setSelectedUser(user);
        setShowConfirmRemove(true);
    };

    const onRemoveConfirmClick = () => {
        removeUser();
        setSelectedUser({});
        setShowConfirmRemove(false);
    };

    const removeUser = async () => {
        try {
            await requestLeaveOrganization({
                userId: selectedUser.id,
            });

            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: stringReplace(translations.FORG_left_confirmation, {
                    user: selectedUser.email,
                }),
            });

            if (selectedUser.id === user.id) {
                // Currently authorized user has been removed from the org,
                // so this will trigger them to be taken to the non-member org screen.
                fetchUser();
            } else if (
                selectedUser.id !== user.id &&
                resolveOrgUserRole(user, tenant).isUserAdmin
            ) {
                // This will refresh the list of users that are org members
                fetchUsers();
            }
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
            });
        }
    };

    return (
        <div data-testid="admin-user-members">
            <h4>Current Administrators</h4>
            <Table
                wrapperClassName="margin-top"
                columns={[
                    {
                        renderHeader: () => translations.COMMON_TABLE_user,
                        renderCell: ({ item }) => (
                            <span title={`ID: ${item.id}`}>{item.email}</span>
                        ),
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_date_joined,
                        renderCell: ({ item }) =>
                            new Date(item.addedAt).toLocaleString(undefined, {
                                dateStyle: 'medium',
                                timeStyle: 'short',
                            }),
                        size: '11rem',
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_actions,
                        // cannot remove last user from an organization
                        renderCell: ({ item: user }) =>
                            organizationUsers.length === 1 ? (
                                <div className="table-icon" />
                            ) : (
                                <div className="action-btn-wrapper">
                                    <button
                                        title="Remove Admin User"
                                        className="table-icon table-icon-delete"
                                        aria-label="Remove Admin User"
                                        onClick={() => onRemoveClick(user)}
                                        type="button"
                                    >
                                        <Trash />
                                    </button>
                                </div>
                            ),
                        size: '5rem',
                    },
                ]}
                items={organizationUsers}
                pagination={true}
            />
            <ConfirmModal
                show={showConfirmRemove}
                title={translations.FORG_remove_user_title}
                messages={[
                    stringReplace(translations.FORG_remove_user_message, {
                        name: selectedUser.email,
                    }),
                ]}
                onConfirm={onRemoveConfirmClick}
                onCancel={() => setShowConfirmRemove(false)}
                buttonStyle="danger"
                buttonCaption="Remove"
                container={container.current}
            />
        </div>
    );
};

const mapStateToProps = ({ organizationUsers }) => ({
    organizationUsers,
});

const mapDispatchToProps = {
    fetchUsers,
    addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinedUserList);
