import Select, { components } from 'react-select';
// biome-ignore lint/style/noNamespaceImport: Namespace import is the most convenient option for grouping all icons together for iteration
import * as Icons from '@phosphor-icons/react';
import { dissocPath } from 'ramda';
import { useComponents } from './CustomPageComponentsProvider';
import { getSharedStyles } from '../../../../ts/utils/select';

const IconPicker = () => {
    const { editingComponent, setEditingComponent } = useComponents();
    return (
        <Select
            inputId="component-icon"
            className="form-control-long"
            isClearable
            classNamePrefix="icon-picker"
            styles={{
                ...getSharedStyles(),
                menuList: (baseStyles) => ({
                    ...baseStyles,
                    display: 'flex',
                    flexWrap: 'wrap',
                }),
                option: (baseStyles) => ({
                    ...baseStyles,
                    width: '33%',
                    padding: 0,
                }),
            }}
            onChange={(selection) =>
                setEditingComponent({
                    ...editingComponent,
                    icon: selection?.label,
                })
            }
            placeholder="Select an icon for the page builder option"
            value={
                Object.entries(Icons).find(
                    ([key]) => key.toUpperCase() === editingComponent.icon?.toUpperCase(),
                )
                    ? {
                          label: editingComponent.icon,
                          value: Object.entries(Icons).find(
                              ([key]) => key.toUpperCase() === editingComponent.icon?.toUpperCase(),
                          )[1],
                      }
                    : null
            }
            options={Object.entries(Icons)
                .filter(([, value]) => value?.render && value.displayName !== 'IconBase')
                .map(([key, value]) => ({
                    label: key,
                    value: value,
                }))}
            components={{
                Option: (props) => (
                    <components.Option
                        {
                            /* manual fix to avoid react-select performance issue */
                            ...dissocPath(
                                ['innerProps', 'onMouseOver'],
                                dissocPath(['innerProps', 'onMouseMove'], props),
                            )
                        }
                    >
                        <span className="icon-option" title={props.label}>
                            <props.value />
                            <span>{props.label}</span>
                        </span>
                    </components.Option>
                ),
                SingleValue: (props) => (
                    <components.SingleValue className="icon-value" {...props}>
                        <props.data.value />
                        <span>{props.data.label}</span>
                    </components.SingleValue>
                ),
            }}
        />
    );
};

export default IconPicker;
