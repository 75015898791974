import ConnectorDropdown from '../../../../../../../generic/ConnectorDropdown';
import Loader from '../../../../../../../loader/Loader';
import translations from '../../../../../../../../translations';
import { NOTIFICATION_TYPES } from '../../../../../../../../constants';
import FormGroup from '../../../../../../../generic/FormGroup';
import { usePageEditor } from '../../../../../PageEditorProvider';
import ConfigSection from '../../ConfigSection';

interface Props {
    updateConnector: (connectorId: string | null) => void;
    updateResourcePath: (resourcePath: string) => void;
    resourcePath: string | null;
    selectedConnectorId: string | null;
}

const FileDataSourceConfig = ({
    updateConnector,
    updateResourcePath,
    resourcePath,
    selectedConnectorId,
}: Props) => {
    const { addNotification, isLoading } = usePageEditor();

    const onError = (message: unknown) => {
        addNotification({
            type: NOTIFICATION_TYPES.error,
            message: message as string,
            isPersistent: true,
        });
    };

    return (
        <ConfigSection dataTestId="file-data-source-config" title="Data source">
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <FormGroup
                        label={translations.PAGE_BUILDER_file_connector_id_label}
                        htmlFor="connector-dropdown"
                    >
                        <ConnectorDropdown
                            selectedConnectorId={selectedConnectorId}
                            onChange={(e) => updateConnector(e?.value ?? null)}
                            onError={onError}
                        />
                    </FormGroup>
                    <FormGroup
                        label={translations.PAGE_BUILDER_file_resource_path_label}
                        htmlFor="resource-path"
                    >
                        <input
                            className="form-control"
                            id="resource-path"
                            type="text"
                            value={resourcePath || ''}
                            onChange={({ target }) => updateResourcePath(target.value)}
                        />
                    </FormGroup>
                </>
            )}
        </ConfigSection>
    );
};

export default FileDataSourceConfig;
