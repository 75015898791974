import { useEffect, useState } from 'react';
import type { Breakpoint, CriteriaType, ValueElementIdReferenceAPI } from '../../../../../types';
import { BREAKPOINT_OPERATORS, CONTENT_TYPE_TO_RULES } from '../../../../../constants/debugger';
import { useDebug } from '../../DebugProvider';
import { ButtonFlavor, ButtonSize, ButtonType, ExButton } from '@boomi/exosphere';

import ValueSelectorModal from '../../../../values/selector/ValueSelectorModal';
import FormGroup from '../../../../generic/FormGroup';
import { isNullOrEmpty } from '../../../../../utils/guard';
import { guid } from '../../../../../utils/guid';

interface Props {
    breakpointToEdit: Breakpoint | null;
    backToList: () => void;
    screen?: string;
}

export const getOperatorOptions = (breakpoint: Breakpoint) =>
    Object.values(BREAKPOINT_OPERATORS).filter((operator) => {
        return breakpoint.value?.contentType
            ? (CONTENT_TYPE_TO_RULES[breakpoint.value.contentType] || []).includes(
                  operator.criteria,
              )
            : false;
    });

const AddBreakpoint = ({ breakpointToEdit, backToList }: Props) => {
    const [breakpoint, setBreakpoint] = useState<Breakpoint>(
        breakpointToEdit || {
            id: guid(),
            value: null,
            operator: '' as CriteriaType,
            expectedContentValue: '',
        },
    );

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const { addBreakpoint, updateBreakpoint } = useDebug();

    useEffect(() => {
        return () => {
            setBreakpoint({
                id: guid(),
                value: null,
                operator: '' as CriteriaType,
                expectedContentValue: '',
            });
        };
    }, []);

    const updateValue = (value: ValueElementIdReferenceAPI | null, breakpoint: Breakpoint) => {
        setBreakpoint({ ...breakpoint, value });
    };

    const updateOperator = (operator: CriteriaType, breakpoint: Breakpoint) => {
        setBreakpoint({
            ...breakpoint,
            operator,
        });
    };

    const updateExpectedContentValue = (expectedContentValue: string, breakpoint: Breakpoint) => {
        setBreakpoint({ ...breakpoint, expectedContentValue });
    };

    const checkValidation = () =>
        breakpoint.value !== null &&
        !isNullOrEmpty(breakpoint.operator) &&
        !isNullOrEmpty(breakpoint.expectedContentValue);

    const onAdd = () => {
        if (checkValidation()) {
            addBreakpoint(breakpoint);
            backToList();
        } else {
            setHasSubmitted(true);
        }
    };

    const onUpdate = () => {
        if (checkValidation()) {
            updateBreakpoint(breakpoint);
            backToList();
        } else {
            setHasSubmitted(true);
        }
    };

    return (
        <>
            <h2 className="add-breakpoint-header">{`${
                breakpointToEdit ? 'Update' : 'Add an'
            } assertion`}</h2>
            <div className="breakpoint-value-picker">
                <FormGroup
                    label="Which Value do you want to break on?"
                    isRequired
                    validationMessage="Please choose a Value"
                    isValid={breakpoint.value !== null}
                    showValidation={hasSubmitted}
                >
                    <ValueSelectorModal
                        onChangeAsValueReference={(value: ValueElementIdReferenceAPI | null) =>
                            updateValue(value, breakpoint)
                        }
                        includeSystemValues
                        value={breakpoint.value}
                        container={null}
                    />
                </FormGroup>
            </div>
            <span className="flex">
                <FormGroup
                    isRequired
                    isValid={hasSubmitted && !isNullOrEmpty(breakpoint.operator)}
                    validationMessage="Please select an operator"
                    showValidation={hasSubmitted}
                    label="Operator"
                    htmlFor="select-operator"
                    className="input-med"
                >
                    <select
                        className="form-control form-select"
                        id="select-operator"
                        value={breakpoint.operator || ''}
                        onChange={(e) => {
                            updateOperator(e.target.value as CriteriaType, breakpoint);
                        }}
                    >
                        <option value="" key="Select an Operator">
                            Select an Operator
                        </option>
                        {getOperatorOptions(breakpoint).map((oo) => (
                            <option value={oo.criteria} key={oo.criteria}>
                                {oo.label}
                            </option>
                        ))}
                    </select>
                </FormGroup>
            </span>
            <span className="flex">
                <FormGroup
                    isRequired
                    isValid={hasSubmitted && !isNullOrEmpty(breakpoint.expectedContentValue)}
                    validationMessage="Please enter an expected content value"
                    showValidation={hasSubmitted}
                    label="Expected content value"
                    htmlFor="expected-content-value"
                    className="input-med"
                >
                    <input
                        id="expected-content-value"
                        className="form-control"
                        type={
                            breakpoint.value?.contentType === 'ContentDateTime' ||
                            breakpoint.value?.contentType === 'ContentDate'
                                ? 'datetime-local'
                                : 'text'
                        }
                        value={breakpoint.expectedContentValue}
                        onChange={(e) =>
                            updateExpectedContentValue(e.currentTarget.value, breakpoint)
                        }
                    />
                </FormGroup>
            </span>

            <div className="btn-group flex gap-small">
                <ExButton
                    flavor={ButtonFlavor.BRANDED}
                    type={ButtonType.SECONDARY}
                    size={ButtonSize.DEFAULT}
                    onClick={backToList}
                >
                    Cancel
                </ExButton>
                <ExButton
                    flavor={ButtonFlavor.BRANDED}
                    type={ButtonType.PRIMARY}
                    size={ButtonSize.DEFAULT}
                    onClick={breakpointToEdit ? onUpdate : onAdd}
                >
                    {breakpointToEdit ? 'Update' : 'Add'}
                </ExButton>
            </div>
        </>
    );
};

export default AddBreakpoint;
