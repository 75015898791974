import { useEffect, useRef, useState, type ElementRef } from 'react';
import type { ChartDataPoint, ChartLine, StateError, TimeFilters } from '../../../types/dashboard';
import {
    convertDateFromUnixTime,
    convertDateToUnixTime,
    formatChartDate,
    getDashboardLineChartOptions,
    getFromDate,
    getSecondsToIncrement,
    insightDateFilterItems,
    onHandlePointClick,
} from '../../../utils/dashboard';
import { Chart, type ActiveElement, type ChartConfiguration, type ChartEvent } from 'chart.js/auto';
import { View } from '../InsightsDashboard';
import translations from '../../../translations';
import InsightsDateFilter from '../InsightsDateFilter';
import Table, { type TableColumnList } from '../../generic/Table';
import FormGroup from '../../generic/FormGroup';
import Select from 'react-select';
import { getSharedStyles } from '../../../utils/select';
import { useInsights } from '../InsightsProvider';
import { groupBy, prop } from 'ramda';
import { ExEmptyState } from '@boomi/exosphere';
import InsightsDateRange from '../InsightsDateRange';

const filterStateErrors = (
    errorsToFilter: StateError[],
    searchValue: string,
    selectedFlowName: string,
) => {
    const propertySearch = (propertyValue: string, searchInput: string) =>
        propertyValue.toLowerCase().trim().includes(searchInput.toLowerCase().trim());

    const filteringErrors = errorsToFilter
        .filter((se) => {
            if (searchValue !== '') {
                return (
                    propertySearch(se.flowName, searchValue) ||
                    propertySearch(se.message, searchValue) ||
                    propertySearch(se.mapElementName, searchValue)
                );
            }

            return true;
        })
        .filter((se) => {
            if (selectedFlowName !== '') {
                return se.flowName === selectedFlowName;
            }

            return true;
        })
        .sort((eA, eB) => convertDateToUnixTime(eB.dateTime) - convertDateToUnixTime(eA.dateTime));

    return filteringErrors;
};

const getChartData = (
    stateErrors: StateError[],
    toDate: Date,
    fromDate: Date,
    dateRange: TimeFilters,
) => {
    const errorsLine: ChartLine[] = [];
    const errorsPoint: ChartDataPoint[] = [];

    for (
        let time = convertDateToUnixTime(fromDate);
        time <= convertDateToUnixTime(toDate);
        time += getSecondsToIncrement(dateRange, convertDateFromUnixTime(time))
    ) {
        const dataWithinTimeRange = stateErrors.filter((se) => {
            const dataUnix = convertDateToUnixTime(se.dateTime);
            return (
                dataUnix > time &&
                dataUnix < time + getSecondsToIncrement(dateRange, convertDateFromUnixTime(time))
            );
        });

        errorsPoint.push({
            x: formatChartDate(convertDateFromUnixTime(time), dateRange),
            y: dataWithinTimeRange.length,
            rawDate: convertDateFromUnixTime(time),
        });
    }

    errorsLine.push({
        data: errorsPoint,
        label: translations.DASHBOARD_errors_header,
        tension: 0.3,
        backgroundColor: '#C73D58',
        borderColor: '#C73D58',
    });

    return errorsLine;
};

const getChartDataGroupedByFlowId = (stateErrors: StateError[]) => {
    const doughnutData: Record<string, number> = {};
    const groupedStateErrors = groupBy(prop('flowId'), stateErrors);

    for (const [key, value] of Object.entries(groupedStateErrors)) {
        const stateFlowName = stateErrors.find((se) => se.flowId === key)?.flowName;

        if (stateFlowName) {
            doughnutData[stateFlowName] = value.length;
        }
    }

    return doughnutData;
};

const getChartDataGroupedByMapElementId = (stateErrors: StateError[], selectedFlowId: string) => {
    const stateErrorsForFlow = stateErrors.filter((se) => se.flowId === selectedFlowId);

    const doughnutData: Record<string, number> = {};
    const groupedStateErrors = groupBy(prop('mapElementId'), stateErrorsForFlow);

    for (const [key, value] of Object.entries(groupedStateErrors)) {
        const stateMapElementName = stateErrors.find(
            (se) => se.mapElementId === key,
        )?.mapElementName;

        if (stateMapElementName) {
            doughnutData[stateMapElementName] = value.length;
        }
    }
    return doughnutData;
};

const aggregateFlows = (stateErrors: StateError[]) => {
    const flowNames: string[] = [];

    stateErrors.forEach((se) => {
        if (!flowNames.includes(se.flowName)) {
            flowNames.push(se.flowName);
        }
    });

    return flowNames;
};

interface Props {
    setCurrentView: (view: View) => void;
}

const ErrorsInsights = ({ setCurrentView }: Props) => {
    const [filteredErrors, setFilteredErrors] = useState<StateError[]>([]);

    // Filters
    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedFlowName, setSelectedFlowName] = useState<string>('');
    const [isShowingOneFlow, setIsShowingOneFlow] = useState(false);

    const lineCanvasRef = useRef<ElementRef<'canvas'>>(null);
    const doughnutCanvasRef = useRef<ElementRef<'canvas'>>(null);

    const {
        errorsDateFilter,
        setErrorsDateFilter,
        stateErrorsWithFilter,
        customErrorsToDate,
        setCustomErrorsToDate,
    } = useInsights();

    useEffect(() => {
        let lineChart: Chart<
            'line',
            {
                x: string;
                y: number;
            }[],
            unknown
        > | null = null;

        let doughnutChart: Chart<'doughnut', number[], unknown> | null = null;

        const filteringErrors = filterStateErrors(
            stateErrorsWithFilter.stateErrors,
            searchValue,
            selectedFlowName,
        );
        setFilteredErrors(filteringErrors);

        if (filteringErrors.length === 0) {
            return;
        }

        const generateLineChart = (
            stateErrors: StateError[],
            dateFilter: TimeFilters,
            customToDate: Date,
        ) => {
            const fromDate = getFromDate(dateFilter, customToDate);
            const chartLine = getChartData(stateErrors, customToDate, fromDate, dateFilter);

            const context = lineCanvasRef.current?.getContext('2d');

            if (!context) {
                return null;
            }

            const chartConfig: ChartConfiguration<'line', { x: string; y: number }[]> = {
                type: 'line',
                data: { datasets: chartLine },
                options: getDashboardLineChartOptions(
                    (_event: ChartEvent, elements: ActiveElement[]) =>
                        onHandlePointClick(
                            chartLine[0].data[elements[0].index],
                            stateErrorsWithFilter.dateFilter,
                            setErrorsDateFilter,
                            setCustomErrorsToDate,
                        ),
                ),
            };

            return new Chart(context, chartConfig);
        };

        const generateDoughnutChart = (stateErrors: StateError[]) => {
            // With the unique flow ids we can tell if the flow filter is on or there is only one flow.
            // The pie chart becomes pointless so we switch to showing the map element percentages instead.
            const uniqueFlowIds = stateErrors
                .map((item) => item.flowId)
                .filter((value, index, self) => self.indexOf(value) === index);

            // Set this into state as we need it to conditionally render something later
            setIsShowingOneFlow(uniqueFlowIds.length === 1);

            const doughnutData: Record<string, number> =
                uniqueFlowIds.length === 1
                    ? getChartDataGroupedByMapElementId(stateErrors, uniqueFlowIds[0])
                    : getChartDataGroupedByFlowId(stateErrors);

            const context = doughnutCanvasRef.current?.getContext('2d');

            if (!context) {
                return null;
            }

            const doughnutDataLabelList = Object.keys(doughnutData);

            const chartConfig: ChartConfiguration<'doughnut', number[]> = {
                type: 'doughnut',
                data: {
                    datasets: [{ data: Object.values(doughnutData) }],
                    labels: Object.keys(doughnutData),
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        legend: { position: 'top', labels: { boxWidth: 35 }, maxWidth: 150 },
                    },
                    onClick: (_e, elements) => {
                        if (uniqueFlowIds.length > 1) {
                            const label = doughnutDataLabelList[elements[0].index];
                            setSelectedFlowName(label);
                        }
                    },
                },
            };

            return new Chart(context, chartConfig);
        };

        lineChart = generateLineChart(
            filteringErrors,
            stateErrorsWithFilter.dateFilter,
            stateErrorsWithFilter.customToDate,
        );
        doughnutChart = generateDoughnutChart(filteringErrors);

        // Cleanup the chart to prevent it from wildly animating when switching away and then back to Dashboard tab.
        return () => {
            lineChart?.destroy();
            doughnutChart?.destroy();
        };
    }, [
        searchValue,
        selectedFlowName,
        stateErrorsWithFilter,
        setErrorsDateFilter,
        setCustomErrorsToDate,
    ]);

    const columns: TableColumnList<StateError> = [
        {
            renderHeader: () => translations.COMMON_TABLE_message,
            renderCell: ({ item }) => <span title={item.message}>{item.message}</span>,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => <span title={item.flowName}>{item.flowName}</span>,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_map_element_name,
            renderCell: ({ item }) => (
                <span title={item.mapElementName}>{item.mapElementName}</span>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_status_code,
            renderCell: ({ item }) => item.statusCode,
            size: '8rem',
        },
        {
            renderHeader: () => translations.ANOMALY_date_time,
            renderCell: ({ item }) =>
                new Date(item.dateTime).toLocaleString(navigator.language, {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                }),
            size: '12rem',
        },
    ];

    const hasData = filteredErrors.length > 0;

    return (
        <>
            <span className="title-bar">
                <h1>
                    <button
                        className="link-emulate"
                        onClick={() => setCurrentView(View.summary)}
                        type="button"
                    >
                        {translations.DASHBOARD_header}
                    </button>
                    {' > '}
                    {translations.DASHBOARD_errors_header}
                </h1>
            </span>
            <div className="insights-control-bar">
                <div className="insights-control-group">
                    <div className="insights-search">
                        <FormGroup
                            label={translations.DASHBOARD_search}
                            htmlFor="errorInsightsSearch"
                        >
                            <input
                                id="errorInsightsSearch"
                                className="form-control"
                                type="text"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </FormGroup>
                    </div>
                    <div className="inights-filter-bar-control">
                        <FormGroup
                            label={translations.DASHBOARD_flow_name}
                            htmlFor="errorInsightsFlowFilter"
                        >
                            <Select
                                styles={getSharedStyles<{ label: string; value: string }>()}
                                inputId="errorInsightsFlowFilter"
                                name="errorInsightsFlowFilter"
                                isClearable={!!selectedFlowName}
                                placeholder={translations.DASHBOARD_errors_filter_flow_playerholder}
                                options={aggregateFlows(stateErrorsWithFilter.stateErrors).map(
                                    (name) => ({
                                        label: name,
                                        value: name,
                                    }),
                                )}
                                onChange={(e) => {
                                    setSelectedFlowName(e?.value ?? '');
                                }}
                                value={
                                    selectedFlowName !== ''
                                        ? { label: selectedFlowName, value: selectedFlowName }
                                        : null
                                }
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="insights-date-range">
                    <InsightsDateRange
                        toDate={customErrorsToDate}
                        dateRange={errorsDateFilter}
                        onChangeToDate={(toDate) => setCustomErrorsToDate(toDate)}
                    />
                    <InsightsDateFilter
                        selectedDate={errorsDateFilter}
                        setSelectedDate={setErrorsDateFilter}
                        dateFilterItems={insightDateFilterItems}
                    />
                </div>
            </div>
            {/* We have to keep the canvas in the DOM otherwise the chart won't render if switching from a state of no data */}
            <span className={hasData ? '' : 'hidden'}>
                <div className="chart-area margin-bottom-large">
                    <div className="line-chart-section">
                        <h2>{translations.DASHBOARD_error_line_header}</h2>
                        <div className="insights-chart">
                            <canvas ref={lineCanvasRef} data-testid="errors-line-chart-canvas" />
                        </div>
                    </div>
                    <div className="pie-chart-section">
                        <h2>
                            {isShowingOneFlow
                                ? translations.DASHBOARD_error_pie_header_alt
                                : translations.DASHBOARD_error_pie_header}
                        </h2>
                        <div className="insights-pie-chart">
                            <canvas
                                ref={doughnutCanvasRef}
                                data-testid="errors-doughnut-chart-canvas"
                            />
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns}
                    items={filteredErrors}
                    rowClassName={() => 'generic-row generic-row-tall'}
                />
            </span>
            {hasData ? null : (
                <div className="insights-empty-state">
                    <ExEmptyState
                        label={translations.DASHBOARD_no_errors}
                        text={translations.DASHBOARD_no_usage_unfilterd}
                    />
                </div>
            )}
        </>
    );
};

export default ErrorsInsights;
