import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import '../../../../css/organization-invite-form.less';
import { sendUserInvitation } from '../../actions/reduxActions/organizations';
import { GUID_REQUIRED_LENGTH } from '../../../ts/constants';
import translations from '../../../ts/translations';
import { getEmailValidityMessage, getIdValidityMessage } from '../../../ts/utils/validation';
import FormGroup from '../../../ts/components/generic/FormGroup';

const UserInvite = ({ sendInvitation, closeModal }) => {
    const [isValid, setIsValid] = useState(true);
    const [validationMessage, setValidationMessage] = useState('');
    const [useEmailInput, setUseEmailInput] = useState(true);
    const inputRef = useRef(undefined);

    const validateInput = () => {
        const {
            current: inputElement,
            current: { value },
        } = inputRef;

        const message = useEmailInput
            ? getEmailValidityMessage(value)
            : getIdValidityMessage(value);

        inputElement.setCustomValidity(message);
        setIsValid(!message);
        setValidationMessage(message);

        return !message;
    };

    const handleInputChange = () => {
        const { current: inputElement } = inputRef;
        validateInput(inputElement);
    };

    const sendInvite = () => {
        if (!validateInput()) {
            return;
        }
        sendInvitation({
            inviteUserData: {
                [useEmailInput ? 'email' : 'id']: inputRef.current.value,
            },
        });
        closeModal();
    };

    const handleRadioChange = ({ target: { value } }) => {
        setUseEmailInput(value === 'email');
        clearInput();
        inputRef.current.focus();
    };

    const clearInput = () => {
        const { current: inputElement } = inputRef;
        inputElement.value = '';
        setIsValid(true);
        setValidationMessage('');
        inputElement.setCustomValidity('');
    };

    return (
        <form>
            <span className="help-block">{translations.FORG_user_invite_form_help_text}</span>
            <FormGroup
                label={
                    useEmailInput
                        ? translations.FORG_user_invite_form_user_email_input_label
                        : translations.FORG_user_invite_form_user_id_input_label
                }
                htmlFor="user-invite-input"
                isRequired={true}
                isValid={isValid}
                validationMessage={validationMessage}
                showValidation={true}
            >
                <input
                    ref={inputRef}
                    id="user-invite-input"
                    maxLength={useEmailInput ? 255 : GUID_REQUIRED_LENGTH}
                    className="form-control"
                    onChange={handleInputChange}
                    required
                    autoFocus
                />
            </FormGroup>
            <div>
                <label className="organization-invite-radio-button-container">
                    <input
                        onChange={handleRadioChange}
                        type="radio"
                        name="input-type"
                        value="email"
                        defaultChecked
                    />
                    <span className="organization-invite-radio-button-label">
                        {translations.FORG_user_invite_form_use_email_radio_label}
                    </span>
                </label>
                <label className="organization-invite-radio-button-container">
                    <input onChange={handleRadioChange} type="radio" name="input-type" value="id" />
                    <span className="organization-invite-radio-button-label">
                        {translations.FORG_user_invite_form_use_id_radio_label}
                    </span>
                </label>
            </div>
            <span className="help-block">
                {useEmailInput
                    ? translations.FORG_user_invite_form_email_help_text
                    : translations.FORG_invite_form_id_help_text}
            </span>
            <footer className="form-footer">
                <button type="button" className="btn btn-sm btn-default" onClick={closeModal}>
                    {translations.FORG_invite_form_cancel_button_label}
                </button>
                <button type="button" className="btn btn-sm btn-success" onClick={sendInvite}>
                    {translations.FORG_invite_form_send_button_label}
                </button>
            </footer>
        </form>
    );
};

const mapDispatchToProps = {
    sendInvitation: sendUserInvitation,
};

export default connect(null, mapDispatchToProps)(UserInvite);
