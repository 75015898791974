import { useEffect, useState } from 'react';
import { getOrgTenantUsers } from '../../../ts/sources/organization';
import { addNotification } from '../../actions/reduxActions/notification';
import { connect } from 'react-redux';
import Table from '../../../ts/components/generic/Table';
import translations from '../../../ts/translations';

const TenantUsersDetails = ({ item, addNotificationAction }) => {
    const requestedTenantId = item.id;

    const [isLoading, setIsLoading] = useState(false);
    const [tenantUsers, setTenantUsers] = useState([]);
    const [page, setPage] = useState(1);
    const pageSize = 20;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const fetchOrgTenantUsers = async () => {
            try {
                setIsLoading(true);
                const users = await getOrgTenantUsers({ requestedTenantId });
                setTenantUsers(users.items);
            } catch ({ message }) {
                addNotificationAction({
                    type: 'error',
                    message: `Unable to load users. Error: ${message}`,
                    isPersistent: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchOrgTenantUsers();
    }, []);

    return (
        <Table
            isLoading={isLoading}
            columns={[
                {
                    renderHeader: () => translations.COMMON_TABLE_email,
                    renderCell: ({ item: user }) => (
                        <span title={`ID: ${user.id}`}>{user.email}</span>
                    ),
                },
                {
                    renderHeader: () => translations.COMMON_TABLE_first_name,
                    renderCell: ({ item: user }) => user.firstName,
                    size: '175px',
                },
                {
                    renderHeader: () => translations.COMMON_TABLE_last_name,
                    renderCell: ({ item: user }) => user.lastName,
                    size: '175px',
                },
                {
                    renderHeader: () => translations.COMMON_TABLE_role,
                    renderCell: ({ item: user }) => user.role.friendlyName,
                },
            ]}
            items={tenantUsers}
            pagination={{
                page,
                pageSize,
                onPageClick: setPage,
            }}
        />
    );
};

const mapDispatchToProps = {
    addNotificationAction: addNotification,
};

export default connect(null, mapDispatchToProps)(TenantUsersDetails);
