import { useEffect, useState } from 'react';
import translations from '../../../../../ts/translations';
import Select from 'react-select';
import FormGroup from '../../../../../ts/components/generic/FormGroup';
import { getFlows } from '../../../../../ts/sources/flow';
import { getSharedStyles } from '../../../../../ts/utils/select';

const FlowSelectBox = ({
    onChange,
    selectedItem,
    flowId,
    notifyError,
    tenantId,
    isRequired,
    isValid,
    showValidation,
    validationMessage,
    menuPosition,
    menuPortalTarget,
    closeMenuOnScroll,
}) => {
    const [flows, setFlows] = useState([]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        getFlows(tenantId).then(setFlows).catch(notifyError);
    }, []);

    const selectedFlow =
        selectedItem && flows.length > 0
            ? flows.find((flow) => flow.developerName === selectedItem)
            : null;

    const currentSelection =
        selectedFlow !== null
            ? { label: selectedFlow.developerName, value: selectedFlow.id.id }
            : null;

    return (
        <FormGroup
            label={translations.SUBFLOW_select_label}
            htmlFor="flow-select-box"
            isRequired={isRequired}
            isValid={isValid}
            showValidation={showValidation}
            validationMessage={validationMessage}
        >
            <Select
                inputId="flow-select-box"
                styles={{
                    ...getSharedStyles(),
                    container: (baseStyles) => ({ ...baseStyles, width: '300px' }),
                }}
                placeholder={translations.SUBFLOW_select_placeholder}
                onChange={onChange}
                value={currentSelection}
                options={flows
                    // Don't show current (parent) flow in select list
                    .filter((item) => item.id.id !== flowId)
                    .map((flow) => ({ label: flow.developerName, value: flow.id.id }))}
                noOptionsMessage={() => 'No results found'}
                menuPosition={menuPosition}
                menuPortalTarget={menuPortalTarget}
                closeMenuOnScroll={closeMenuOnScroll}
                isClearable
            />
        </FormGroup>
    );
};

export default FlowSelectBox;
