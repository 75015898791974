import { connect } from 'react-redux';
import {
    rejectTenantInvitation,
    fetchReceivedTenantInvitations,
} from '../../actions/reduxActions/organizations';
import translations from '../../../ts/translations';
import { pathEq } from 'ramda';
import TenantName from '../../../ts/components/generic/TenantName';
import { useAuth } from '../../../ts/components/AuthProvider';
import { acceptTenantInvite } from '../../../ts/sources/organization';
import { addNotification } from '../../actions/reduxActions/notification';
import { NOTIFICATION_TYPES } from '../../../ts/constants';
import Table from '../../../ts/components/generic/Table';

/**
 * @description Flow Org. Tenant invitation list
 *
 * Display a list of actionable invitations for the current tenant.
 */
const CurrentTenantInviteList = ({
    invitations = [],
    rejectInvitationAction,
    getReceivedTenantInvitations,
    addNotification,
}) => {
    const { tenant, fetchUser } = useAuth();

    const pendingInvitations = invitations.filter((item) => {
        const matchingTenant = pathEq(['tenant', 'id'], tenant.id, item);
        const actionable =
            item.status !== 'accepted' &&
            item.status !== 'rejected' &&
            item.status !== 'canceled' &&
            item.status !== 'acknowledged';
        return matchingTenant && actionable;
    });

    const acceptTenantInvitation = async ({ organizationId }) => {
        try {
            await acceptTenantInvite({
                organizationId,
            });

            // reload the user to pickup organization changes
            fetchUser();
            getReceivedTenantInvitations();
        } catch (err) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            });
        }
    };

    return (
        <>
            <p>
                <strong>Invitations for tenant: </strong>
                <TenantName name={tenant.developerName} />
            </p>
            <p className="description">{translations.FORG_tenant_invitations_description}</p>
            <Table
                wrapperClassName="margin-top margin-bottom-large"
                columns={[
                    {
                        renderHeader: () => translations.COMMON_TABLE_organization,
                        renderCell: ({ item }) => (
                            <span title={`ID: ${item.organization.id}`}>
                                {item.organization.name}
                            </span>
                        ),
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_status,
                        renderCell: ({ item }) => <span className="capitalize">{item.status}</span>,
                        size: '20%',
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_date_sent,
                        renderCell: ({ item }) =>
                            new Date(item.invitedAt).toLocaleString(undefined, {
                                dateStyle: 'medium',
                                timeStyle: 'short',
                            }),
                        size: '11rem',
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_actions,
                        renderCell: ({ item }) => (
                            <>
                                <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                        acceptTenantInvitation({
                                            organizationId: item.organization.id,
                                        })
                                    }
                                    type="button"
                                >
                                    Join
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={() =>
                                        rejectInvitationAction({
                                            organizationId: item.organization.id,
                                        })
                                    }
                                    type="button"
                                >
                                    Reject
                                </button>
                            </>
                        ),
                        size: '11rem',
                    },
                ]}
                items={pendingInvitations}
                pagination={true}
            />
        </>
    );
};

const mapDispatchToProps = {
    rejectInvitationAction: rejectTenantInvitation,
    getReceivedTenantInvitations: fetchReceivedTenantInvitations,
    addNotification,
};

export default connect(null, mapDispatchToProps)(CurrentTenantInviteList);
