import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { notifyError } from '../../../../actions/reduxActions/notification';
import {
    BUSINESS_RULES_ELEMENTS,
    MAP_ELEMENT_TYPES,
    UI_ELEMENTS,
} from '../../../../../ts/constants';
import translations from '../../../../../ts/translations';
import { isNullOrEmpty } from '../../../../../ts/utils/guard';
import { stringReplace } from '../../../../../ts/utils/string';
import { getByID } from '../../../../../ts/utils/collection';
import ButtonDefault from '../../../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../../../ts/components/buttons/ButtonPrimary';
import FormGroup from '../../../../../ts/components/generic/FormGroup';
import { useGraph } from '../../../graph/GraphProvider';
import Loader from '../../../../../ts/components/loader/Loader';
import ConfigLayoutWrapper from '../../../../../ts/components/flow/elementConfigurations/common/ConfigLayoutWrapper';

const OutcomeSourceRedirect = ({
    dismissMapElementConfig,
    container,
    outcomeId,
    sourceMapElementProp,
    targetMapElementProp,
}) => {
    const { saveMapElements, refreshFlow } = useGraph();
    const [sourceMapElement, setSourceMapElement] = useState(null);
    const [targetMapElement, setTargetMapElement] = useState(null);
    const [outcome, setOutcome] = useState(null);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const title = translations.OUTCOME_confirm_move;
    const message = stringReplace(
        translations.OUTCOME_confirm_move_message,
        targetMapElement?.developerName,
    );
    const elementType = MAP_ELEMENT_TYPES.outcome;

    // Target
    const targetHasBusinessRules = BUSINESS_RULES_ELEMENTS.includes(targetMapElement?.elementType);
    const targetIsUIElement = UI_ELEMENTS.includes(targetMapElement?.elementType);

    // Source
    const sourceHasBusinessRules = BUSINESS_RULES_ELEMENTS.includes(sourceMapElement?.elementType);
    const sourceIsUIElement = UI_ELEMENTS.includes(sourceMapElement?.elementType);

    // Validation
    const isLabelValid = !isNullOrEmpty(outcome?.label);
    const isNameValid = !isNullOrEmpty(outcome?.developerName);
    const formValid = (isLabelValid || !targetIsUIElement) && isNameValid;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setSourceMapElement(sourceMapElementProp);
        setTargetMapElement(targetMapElementProp);
        setOutcome(getByID(outcomeId, sourceMapElementProp?.outcomes));
    }, []);

    const removeOutcome = (outcomes) => {
        const outcomeArr = outcomes;

        return outcomeArr.filter((oc) => {
            return oc.id !== outcomeId;
        });
    };

    const updateOutcomeProperties = (editedOutcome) => {
        if (!targetHasBusinessRules && sourceHasBusinessRules) {
            editedOutcome.comparison = null;
        }

        if (!targetIsUIElement && sourceIsUIElement) {
            editedOutcome.label = null;
            editedOutcome.pageActionBindingType = 'SAVE';
            editedOutcome.pageActionType = null;
        }

        editedOutcome.controlPoints = null;

        return editedOutcome;
    };

    const onSave = async () => {
        setHasSubmitted(true);

        if (formValid) {
            const source = sourceMapElement;
            source.outcomes = removeOutcome(source.outcomes);

            const target = targetMapElement;
            const editedOutcome = updateOutcomeProperties(outcome);

            if (target.outcomes === null) {
                target.outcomes = [];
            }

            editedOutcome.order = target.outcomes.length;
            target.outcomes.push(editedOutcome);

            await saveMapElements([source, target]);
            dismissMapElementConfig();
        }
    };

    const onCancel = () => {
        // Reset the flow canvas
        refreshFlow();
        dismissMapElementConfig();
    };

    const renderBusinessRulesUi = (
        <p>
            <strong>Warning:</strong> Target map element does not have a Business Rules section.
            Moving this outcome will lose all Business Rule data.
        </p>
    );

    const renderIsUiElementUi = (
        <div className="margin-bottom-large">
            <FormGroup
                label="Label"
                isRequired
                isValid={isLabelValid}
                validationMessage="Please enter a label."
                showValidation={hasSubmitted}
                htmlFor="label-input"
            >
                <input
                    id="label-input"
                    value={outcome?.label ?? ''}
                    onChange={({ target: { value } }) => setOutcome({ ...outcome, label: value })}
                    size="25"
                    required
                    className="form-control"
                    type="text"
                />
            </FormGroup>
            <span className="help-block">A label is required to move this outcome.</span>
        </div>
    );

    const renderBody = () => (
        <>
            <p>{message}</p>
            <div className="margin-top">
                {targetIsUIElement && !sourceIsUIElement ? renderIsUiElementUi : null}
                {!targetHasBusinessRules && sourceHasBusinessRules ? renderBusinessRulesUi : null}
            </div>
        </>
    );

    const renderFooter = () => (
        <>
            <ButtonDefault className="flex-child-right" onClick={onCancel}>
                {translations.GRAPH_config_panel_cancel}
            </ButtonDefault>
            <ButtonPrimary className="margin-left" onClick={onSave}>
                {translations.GRAPH_config_panel_save}
            </ButtonPrimary>
        </>
    );

    if (!(sourceMapElement && targetMapElement)) {
        return <Loader />;
    }

    return (
        <ConfigLayoutWrapper
            renderBody={renderBody}
            renderFooter={renderFooter}
            title={title}
            elementType={elementType}
            onHide={onCancel}
            container={container}
        />
    );
};

export default connect(null, {
    onError: notifyError,
})(OutcomeSourceRedirect);
