import ConfirmModal from '../../../../ts/components/generic/modal/ConfirmModal';
import translations from '../../../../ts/translations';
import Table from '../../../../ts/components/generic/Table';
import PageHeader from '../../../../ts/components/generic/PageHeader';
import Sortable from '../../../../ts/components/generic/Sortable';
import { stringReplace } from '../../../../ts/utils/string';
import { useEffect, useState, useRef } from 'react';
import { SYSTEM_TYPES, NOTIFICATION_TYPES } from '../../../../ts/constants';
import { Trash } from '@phosphor-icons/react';
import { useTypes } from './TypesProvider';

const TypesList = () => {
    const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);

    const {
        types,
        fetchTypes,
        createNewType,
        importProfile,
        editType,
        paging,
        setTypeToDelete,
        deleteType,
        typeToDelete,
        addNotification,
        typesLoading,
    } = useTypes();

    const { pageSize, page, total, search, orderBy, orderDirection } = paging;

    const modalContainerRef = useRef(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchTypes({
            limit: pageSize,
            page,
            search,
            orderBy,
            orderDirection,
        });
    }, []);

    const onDelete = (type) => {
        if (SYSTEM_TYPES.includes(type.developerName)) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: 'Deleting system types is not allowed.',
                isPersistent: true,
            });
        } else {
            setTypeToDelete(type);
            setDeleteModalIsVisible(true);
        }
    };

    const onPage = (updatedPage) => {
        fetchTypes({
            limit: pageSize,
            page: updatedPage,
            search,
            orderBy,
            orderDirection,
        });
    };

    const onRefresh = () => {
        fetchTypes({
            limit: pageSize,
            page,
            search,
            orderBy,
            orderDirection,
        });
    };

    const onSort = ({ orderBy, direction }) => {
        fetchTypes({
            limit: pageSize,
            page,
            search,
            orderBy,
            orderDirection: direction,
        });
    };

    const onFilter = (searchQuery) => {
        fetchTypes({
            limit: pageSize,
            page: 1,
            search: searchQuery,
            orderBy,
            orderDirection,
        });
    };

    const columns = [
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection="ASC"
                    direction={paging.orderBy === 'developerName' ? paging.orderDirection : null}
                    onSort={(direction) => onSort({ orderBy: 'developerName', direction })}
                >
                    {translations.COMMON_TABLE_name}
                </Sortable>
            ),
            renderCell: ({ item }) => (
                <button
                    className="link-emulate overflow-ellipsis"
                    onClick={() => editType(item)}
                    title={`Edit ${item.developerName}`}
                    aria-label={`Edit ${item.developerName}`}
                    type="button"
                >
                    {item.developerName}
                </button>
            ),
        },
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection="ASC"
                    direction={paging.orderBy === 'dateModified' ? paging.orderDirection : null}
                    onSort={(direction) => onSort({ orderBy: 'dateModified', direction })}
                >
                    {translations.COMMON_TABLE_last_modified}
                </Sortable>
            ),
            renderCell: ({ item }) =>
                SYSTEM_TYPES.includes(item.developerName)
                    ? null
                    : new Date(item.dateModified).toLocaleString(undefined, {
                          dateStyle: 'medium',
                          timeStyle: 'short',
                      }),
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_connector,
            renderCell: ({ item }) => (
                <div className="overflow-ellipsis">{item.serviceElementDeveloperName}</div>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_summary,
            renderCell: ({ item }) => <div>{item.developerSummary}</div>,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) =>
                SYSTEM_TYPES.includes(item.developerName) ? (
                    // Need to maintain row height that the buttons provide.
                    <div className="table-icon" />
                ) : (
                    <div className="action-btn-wrapper">
                        <button
                            title={`Delete ${item.developerName}`}
                            className="table-icon table-icon-delete"
                            aria-label={`Delete ${item.developerName}`}
                            onClick={() => onDelete(item)}
                            type="button"
                        >
                            <Trash />
                        </button>
                    </div>
                ),
            size: '5rem',
        },
    ];

    const customButtons = [{ name: translations.TYPES_import_profile, onClick: importProfile }];

    return (
        <div className="admin-page flow-wrapper" ref={modalContainerRef}>
            <ConfirmModal
                show={deleteModalIsVisible}
                title={translations.TYPE_delete_confirmation_title}
                messages={[
                    stringReplace(translations.TYPE_delete_confirmation_message, {
                        typeName: typeToDelete?.developerName ?? '',
                    }),
                    translations.GENERAL_cannot_be_undone,
                ]}
                buttonStyle="danger"
                buttonCaption="Delete"
                onCancel={() => setDeleteModalIsVisible(false)}
                onConfirm={() => {
                    deleteType();
                    setDeleteModalIsVisible(false);
                }}
                container={modalContainerRef.current}
            />
            <PageHeader
                title="Types"
                onAdd={createNewType}
                onRefresh={onRefresh}
                onSearch={onFilter}
                searchQuery={paging.search}
                searchPlaceholderText="Search"
                addText="New Type"
                refreshTitle="Refresh Results"
                customButtons={customButtons}
            />

            <Table
                wrapperClassName="margin-top"
                columns={columns}
                items={types}
                pagination={{
                    page,
                    total,
                    pageSize,
                    changePage: onPage,
                }}
                isLoading={typesLoading}
            />
        </div>
    );
};

export default TypesList;
