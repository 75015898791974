import { useState, useEffect } from 'react';
import SearchInput from '../../../../ts/components/generic/SearchInput';
import { getTenants } from '../../../../ts/sources/organization';
import translations from '../../../../ts/translations';
import TenantName from '../../../../ts/components/generic/TenantName';
import Table from '../../../../ts/components/generic/Table';
import { stringContains, stringReplace } from '../../../../ts/utils/string';

export const searchOnTenants = (searchTerm, tenants) =>
    tenants.filter((tenant) => stringContains(tenant.developerName, searchTerm, false));

/**
 * @param {Function} onCheck Callback selecting/deselecting connected tenants
 * @param {Array} connectedTenantIds An array of tenant ID's connected to the runtime
 *
 * @description Displays a searchable table of tenants that are in an organisation
 */
const LocalRuntimeOrgTenants = ({ onCheck, connectedTenantIds }) => {
    const [isLoadingTenants, setIsLoadingTenants] = useState(true);
    const [tenants, setTenants] = useState([]);
    const [tenantsToDisplay, setTenantsToDisplay] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');

    useEffect(() => {
        const fetchOrgTenants = async () => {
            const tenantsInOrg = await getTenants();
            tenantsInOrg.forEach((t) => {
                if (t.subtenants) {
                    t.subtenants.reduce((a, c) => {
                        a.push(c);
                        return a;
                    }, tenantsInOrg);
                }
            });

            setTenants(tenantsInOrg);
            setTenantsToDisplay(tenantsInOrg);
            setIsLoadingTenants(false);
        };
        fetchOrgTenants();
    }, []);

    const onSearch = (searchTerm) => {
        setSearchFilter(searchTerm);
        const queryResult = searchOnTenants(searchTerm, tenants);
        setTenantsToDisplay(queryResult);
    };

    const tenantTableColumns = [
        {
            renderHeader: () =>
                stringReplace(translations.LOCAL_RUNTIME_create_screen_number_tenants_selected, {
                    tenantsSelected: connectedTenantIds.length,
                }),
            renderCell: ({ item: tenant }) => (
                <input
                    key={tenant.id}
                    defaultChecked={connectedTenantIds.find((t) => t === tenant.id)}
                    onChange={(e) => onCheck(e.target.checked, tenant.id)}
                    type="checkbox"
                />
            ),
            size: '6rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_tenant_name,
            renderCell: ({ item }) => <TenantName name={item.developerName} />,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_summary,
            renderCell: ({ item }) => item.summary,
        },
    ];

    return (
        <>
            <SearchInput value={searchFilter} onChange={onSearch} />
            <Table
                wrapperClassName="margin-top"
                items={tenantsToDisplay}
                columns={tenantTableColumns}
                isLoading={isLoadingTenants}
            />
        </>
    );
};

export default LocalRuntimeOrgTenants;
