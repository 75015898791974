import type { FlowResponseAPI } from '../types';
import { fetchAndParse } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export interface FlowSnapshotDeployRequest {
    runtimes: Runtime[];
}

export interface Runtime {
    id: string;
    messageId: string;
    isActive: boolean;
    isDefault: boolean;
}

export interface FlowBuildRequestAPI {
    releaseId: string | null;
    releaseName: string | null;
    flowId: string;
    comment: string | null;
}

export const execute = (uri: string, method: string, body: unknown) => {
    const addSlash = uri[0] !== '/';
    return fetchAndParse<unknown>({
        url: `${addSlash ? '/' : ''}${uri}`,
        method,
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json',
        },
        body,
    });
};

export const buildFlow = (flowBuildRequestAPI: FlowBuildRequestAPI) =>
    fetchAndParse<FlowResponseAPI>({
        url: '/api/draw/1/buildflow',
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: flowBuildRequestAPI,
    });

export const snapshotFlow = ({
    flowId,
    comment = null,
}: {
    flowId: string;
    comment: string | null;
}) =>
    fetchAndParse<FlowResponseAPI>({
        url: `/api/draw/1/flow/snap/${flowId}`,
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: JSON.stringify(comment),
    });

export const activateFlow = (flowId: string, versionId: string) =>
    fetchAndParse<FlowResponseAPI>({
        url: `/api/draw/1/flow/activation/${flowId}/${versionId}/true/true`,
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
    });

export const deployFlow = (flowId: string, versionId: string, request: FlowSnapshotDeployRequest) =>
    fetchAndParse<void>({
        url: `/api/draw/1/flow/snap/${flowId}/${versionId}/deploy`,
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: request,
    });
