import { useState } from 'react';
import Loader from '../../../../ts/components/loader/Loader';
import { connect } from 'react-redux';
import { append } from 'ramda';

import '../../../../../css/local-runtimes.less';

/**
 * @param {Boolean} isChecked
 * @param {String} tenantId
 * @param {Array} associatedTenantIds
 */
export const getCheckedTenants = (isChecked, tenantId, associatedTenantIds) =>
    isChecked
        ? append(tenantId, associatedTenantIds)
        : associatedTenantIds.filter((t) => t !== tenantId);

/**
 *
 * @param {*} WrappedComponent Component to render
 * @param {Function} onSubmit Saving the runtime connection
 *
 * @description A higher-order function used for abstracting common functionality
 * away from the create/edit screens
 */
const LocalRuntimeDetail = (WrappedComponent, onSubmit) => {
    /**
     *
     * @param {Object} [localRuntime] Optional representation of a runtime connection
     * @param {Array} tenants Tenants in the current org
     * @param {Boolean} isLoading
     * @param {Object} props
     */
    // LEGACY eslint exclusion: Remove when altering this code

    const DetailView = ({ localRuntime, isLoading, ...props }) => {
        // The tenant ID's connected to the runtime
        const [associatedTenantIds, updateAssociatedTenantIds] = useState(
            localRuntime ? localRuntime.tenants.map((t) => t.id) : [],
        );

        const onCheck = (isChecked, tenantId) => {
            const checkedTenants = getCheckedTenants(isChecked, tenantId, associatedTenantIds);
            updateAssociatedTenantIds(checkedTenants);
        };

        return (
            <>
                {isLoading ? (
                    <Loader message="Saving Runtime..." />
                ) : (
                    <WrappedComponent
                        localRuntime={localRuntime}
                        onCheck={onCheck}
                        connectedTenantIds={associatedTenantIds}
                        {...props}
                    />
                )}
            </>
        );
    };

    const mapStateToProps = (state) => ({
        localRuntime: state.localRuntimes.runtimeList.find((runtime) => runtime.isEditing),
        isLoading: state.localRuntimes.isLoading,
    });

    const mapDispatchToProps = {
        onSubmit,
    };

    return connect(mapStateToProps, mapDispatchToProps)(DetailView);
};

export default LocalRuntimeDetail;
