import { createContext, useContext, useEffect, useState, type ReactNode } from 'react';
import type { StateError, StateErrorsWithFilter, TimeFilters } from '../../types/dashboard';
import { getStateErrors } from '../../sources/dashboard';
import type { NotifyError } from '../../types';

interface InsightsProviderContext {
    usageDateFilter: TimeFilters;
    setUsageDateFilter: (filter: TimeFilters) => void;
    errorsDateFilter: TimeFilters;
    setErrorsDateFilter: (filter: TimeFilters) => void;
    stateErrorsWithFilter: StateErrorsWithFilter;
    stateErrorsForUsageData: StateError[];
    customUsageToDate: Date;
    setCustomUsageToDate: (toDate: Date) => void;
    customErrorsToDate: Date;
    setCustomErrorsToDate: (toDate: Date) => void;
}

interface InsightsProviderProps {
    children: ReactNode;
    notifyError: NotifyError;
}

const Context = createContext<InsightsProviderContext | undefined>(undefined);

const InsightsProvider = ({ children, notifyError }: InsightsProviderProps) => {
    const [usageDateFilter, setUsageDateFilter] = useState<TimeFilters>('day');
    const [errorsDateFilter, setErrorsDateFilter] = useState<TimeFilters>('day');
    const [customUsageToDate, setCustomUsageToDate] = useState(new Date());
    const [customErrorsToDate, setCustomErrorsToDate] = useState(new Date());

    // I've bundled the dateFilter and customToDate in with the stateErrors so we only rerender the chart page once.
    const [stateErrorsWithFilter, setStateErrorsWithFilter] = useState<StateErrorsWithFilter>({
        stateErrors: [],
        dateFilter: 'day',
        customToDate: new Date(),
    });

    const [stateErrorsForUsageData, setStateErrorsForUsageData] = useState<StateError[]>([]);

    useEffect(() => {
        getStateErrors(errorsDateFilter, customErrorsToDate)
            .then((response) =>
                setStateErrorsWithFilter({
                    stateErrors: response,
                    dateFilter: errorsDateFilter,
                    customToDate: customErrorsToDate,
                }),
            )
            .catch(notifyError);
    }, [errorsDateFilter, notifyError, customErrorsToDate]);

    // The number of Errors per flow is displayed as part of Usage. If this turns out to be too expensive then I'll change this later.
    // Usage and errors can be filtered separately so we need a second Errors engine call that matches the date filters used for Usage.
    useEffect(() => {
        getStateErrors(usageDateFilter, customUsageToDate)
            .then((response) => setStateErrorsForUsageData(response))
            .catch(notifyError);
    }, [usageDateFilter, notifyError, customUsageToDate]);

    const contextValue: InsightsProviderContext = {
        usageDateFilter,
        setUsageDateFilter,
        errorsDateFilter,
        setErrorsDateFilter,
        stateErrorsWithFilter,
        stateErrorsForUsageData,
        customUsageToDate,
        setCustomUsageToDate,
        customErrorsToDate,
        setCustomErrorsToDate,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useInsights = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useInsights must be used within an InsightsProvider');
    }
    return context;
};

export { InsightsProvider, useInsights };
