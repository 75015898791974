import { useState, useEffect, type FormEvent } from 'react';
import translations from '../../translations';
import FormGroup from '../generic/FormGroup';
import { useEnvironmentsProviders } from '../../../js/components/admin/environments/EnvironmentsProvider';
import { ENVIRONMENT_CATEGORY_TYPES } from '../admin/environments/constants';
import type { ReleaseAPI } from '../../types/releases';
import CreatableSelect from 'react-select/creatable';
import { getSharedStyles } from '../../utils/select';

interface Props {
    onReleaseIdChanged: (id: string | null) => void;
    onReleaseNameChanged: (name: string | null) => void;
    onCommentChanged: (e: FormEvent<HTMLTextAreaElement>) => void;
    isThemeBuild: boolean;
}

type ReleaseOption = {
    value: string;
    label: string;
    __isNew__?: boolean;
};

type UseEnvironmentsProviders = {
    getReleases: (filter: { environmentclassificationtypes: string[] }) => Promise<ReleaseAPI[]>;
};

const ActivateBuild = ({
    onReleaseIdChanged,
    onReleaseNameChanged,
    onCommentChanged,
    isThemeBuild,
}: Props) => {
    const { getReleases } = useEnvironmentsProviders() as UseEnvironmentsProviders;
    const [releaseOptions, setReleases] = useState<ReleaseOption[]>([]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        const loadReleases = async () => {
            const filter = {
                environmentclassificationtypes: [
                    ENVIRONMENT_CATEGORY_TYPES.development,
                    ENVIRONMENT_CATEGORY_TYPES.test,
                ],
            };

            const releases: ReleaseAPI[] = await getReleases(filter);

            const releaseOptions = releases?.map((release) => ({
                value: release.id,
                label: release.name,
            }));
            setReleases(releaseOptions);
        };

        loadReleases();
    }, []);

    const onReleaseChange = (release: ReleaseOption | null) => {
        if (!release) {
            onReleaseIdChanged(null);
            onReleaseNameChanged(null);
            return;
        }

        const { value, label, __isNew__ } = release;

        if (__isNew__) {
            onReleaseIdChanged(null);
            onReleaseNameChanged(label);
        } else if (value) {
            onReleaseIdChanged(value);
            onReleaseNameChanged(label);
        }
    };

    return (
        <>
            <p>
                {isThemeBuild
                    ? translations.ENVIRONMENT_build_subtext_theme
                    : translations.ENVIRONMENT_build_subtext}
            </p>

            <FormGroup label="Add to release:" htmlFor="add-to-release-input">
                <CreatableSelect
                    styles={getSharedStyles<ReleaseOption>()}
                    name="add-to-release"
                    inputId="add-to-release-input"
                    isMulti={false}
                    placeholder="Create or search for a release"
                    options={releaseOptions}
                    onChange={onReleaseChange}
                    noOptionsMessage={() => null}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                />
            </FormGroup>

            <FormGroup label="Build notes:" htmlFor="build-notes">
                <textarea
                    id="build-notes"
                    className="form-control form-control-textarea"
                    onChange={onCommentChanged}
                    rows={3}
                    placeholder="Description"
                />
            </FormGroup>
        </>
    );
};

export default ActivateBuild;
