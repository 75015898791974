import Invitations from './Invitations';

/**
 * @description The UI for joined tenants
 *
 * Display the UI for joined tenants.
 */
const LinkedTenantUI = ({ tenant }) => {
    return (
        <div data-testid="linked-tenant-ui" className="flow-organization admin-page">
            <h1>Organization</h1>
            <p>
                This Tenant is in the organization:
                <br />
                <strong>{tenant.organization ? tenant.organization.name : ''}</strong>
            </p>
            <Invitations />
        </div>
    );
};

export default LinkedTenantUI;
