import { changeSAMLSetting } from '../../../../js/actions/reduxActions/currentTenant';
import { connect, type ConnectedProps } from 'react-redux';
import translations from '../../../translations';
import Toggle from '../../inputs/Toggle';
import { useEffect, type ChangeEventHandler } from 'react';
import FormGroup from '../../generic/FormGroup';
import type { SecuritySettingsAPI } from '../../../types/Tenant';

const mapDispatchToProps = {
    changeSAMLSetting,
};

const connector = connect(null, mapDispatchToProps);

type SAMLCookieSettingsProps = {
    settings: SecuritySettingsAPI['samlSettings'];
} & ConnectedProps<typeof connector>;

// Ensure correct empty value for inputs that will be bound to nullable int fields in the Engine, where an empty string is invalid
const nullIfEmpty = (value: string) => {
    return value === '' ? null : value;
};

const SAMLCookieSettings = ({ settings, changeSAMLSetting }: SAMLCookieSettingsProps) => {
    const onChangeCookieType: ChangeEventHandler<HTMLSelectElement> = ({ target: { value } }) => {
        changeSAMLSetting({
            settingPath: ['cookieType'],
            value,
        });
    };

    const onChangeMaxSession: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        changeSAMLSetting({
            settingPath: ['maxSession'],
            value: nullIfEmpty(value),
        });
    };

    const onChangeIdleTimeout: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        changeSAMLSetting({
            settingPath: ['idleTimeout'],
            value: nullIfEmpty(value),
        });
    };

    const onChangeShowUri = (args: {
        event: React.ChangeEvent<HTMLInputElement>;
        isOn: boolean;
    }) => {
        changeSAMLSetting({
            settingPath: ['showUri'],
            value: args.isOn,
        });
    };

    useEffect(() => {
        if (!settings?.cookieType) {
            changeSAMLSetting({
                settingPath: ['cookieType'],
                value: 'persistent',
            });
        }
    }, [changeSAMLSetting, settings?.cookieType]);

    return (
        <>
            <FormGroup htmlFor="saml-cookie-type" label={translations.SAML_cookietype_label}>
                <select
                    id="saml-cookie-type"
                    value={settings?.cookieType ?? ''}
                    onChange={onChangeCookieType}
                    className="form-control"
                >
                    <option value="persistent">{translations.SAML_cookietype_persistent}</option>
                    <option value="transient">{translations.SAML_cookietype_transient}</option>
                </select>
            </FormGroup>
            <p className="help-block">{translations.SAML_cookietype_help}</p>
            <FormGroup htmlFor="saml-max-session" label={translations.SAML_max_session_label}>
                <input
                    id="saml-max-session"
                    type="number"
                    value={settings?.maxSession ?? ''}
                    placeholder={translations.SAML_max_session_placeholder}
                    onChange={onChangeMaxSession}
                    className="form-control"
                />
            </FormGroup>
            <p className="help-block">{translations.SAML_max_session_help}</p>
            <FormGroup
                htmlFor="saml-enable-show-url-toggle"
                label={translations.SAML_max_session_show_uri}
                labelPosition="after"
            >
                <Toggle
                    className="saml-enable-show-url"
                    isOn={settings?.showUri ?? false}
                    onChange={onChangeShowUri}
                    id="saml-enable-show-url-toggle"
                />
            </FormGroup>
            <p className="help-block">{translations.SAML_max_session_show_uri_help}</p>
            <FormGroup htmlFor="saml-idle-timeout" label={translations.SAML_idle_timeout}>
                <input
                    id="saml-idle-timeout"
                    type="number"
                    value={settings?.idleTimeout ?? ''}
                    placeholder={translations.SAML_idle_timeout_placeholder}
                    onChange={onChangeIdleTimeout}
                    className="form-control"
                />
                <p className="help-block">{translations.SAML_idle_timeout_help}</p>
            </FormGroup>
        </>
    );
};

export default connector(SAMLCookieSettings);
