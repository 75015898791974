import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import CodeEditor from '../../ts/components/generic/CodeEditor';
import ButtonPrimary from '../../ts/components/buttons/ButtonPrimary';
import ButtonDanger from '../../ts/components/buttons/ButtonDanger';
import { fetchAPIEndpoints } from '../actions/reduxActions/APIEndpoints';
import { NOTIFICATION_TYPES } from '../../ts/constants';
import { addNotification } from '../actions/reduxActions/notification';
import { execute } from '../../ts/sources/build';
import Loader from '../../ts/components/loader/Loader';
import '../../../css/build.less';
import '../../../css/notification.less';
import { isNullOrWhitespace } from '../../ts/utils/guard';

class Build extends Component {
    constructor(props) {
        super(props);

        this.state = {
            description: '',
            isLoading: false,
            type: 'json',
        };
    }

    inputRef = createRef();

    componentDidMount() {
        this.props.fetchEndpoints();
    }

    onEndpointKeyUp = (event) => {
        const inputText = this.inputRef.current.value;
        if (event.keyCode === 13 && inputText && inputText !== '') {
            this.onRequest('GET');
        }
    };

    onRequest = (type) => {
        const inputText = this.inputRef.current.value;

        if (isNullOrWhitespace(inputText)) {
            return;
        }

        const body = type === 'GET' ? null : this.state.request;

        this.execute(inputText, type, body);
    };

    onRequestChange = (value) => {
        this.setState({ request: value });
    };

    onExecute(response) {
        this.setState({ isLoading: false });

        this.props.addNotification({
            type: NOTIFICATION_TYPES.success,
            message: `${response.method} to ${response.uri} completed successfully`,
            isPersistent: false,
        });

        const stringifiedResponse = JSON.stringify(response.data, null, 4);

        // If we have an Object, it's probably JSON...
        if (response.data instanceof Object) {
            this.setState({ response: stringifiedResponse, type: 'json' });
        } else {
            /**
             * If the response is not JSON then it could either be
             * a string describing a Flow package or the HTML of
             * a player
             */
            try {
                /**
                 * String can be parsed as JSON, so stringify it again
                 * to unescape it (kind of obfuscates the package data)
                 */
                JSON.parse(response.data);
                this.setState({ response: stringifiedResponse, type: 'json' });
            } catch {
                // String cannot be parsed as JSON, so we assume it is just HTML
                this.setState({ response: response.data, type: 'html' });
            }
        }
    }

    onError(response) {
        this.setState({ isLoading: false });

        this.props.addNotification({
            type: NOTIFICATION_TYPES.error,
            message: response.message,
            isPersistent: true,
        });
    }

    execute(uri, method, body) {
        this.setState({ isLoading: true });

        return execute(uri, method, body)
            .then((response) => this.onExecute({ data: response, uri, method }))
            .catch((response) => this.onError(response));
    }

    render() {
        const urls = Object.keys(this.props.endpoints).map((endpoint) => {
            if (this.props.selectedFlow) {
                return endpoint
                    .replace('{flow}', this.props.selectedFlow.id)
                    .replace('/flow/{id}', `/flow/${this.props.selectedFlow.id}`)
                    .replace('{version}', this.props.selectedFlow.versionId)
                    .replace('{editingToken}', this.props.selectedFlow.editingToken);
            }

            return endpoint;
        });

        return (
            <div className="build">
                <div
                    className="build-endpoint"
                    role="button"
                    onKeyUp={this.onEndpointKeyUp}
                    tabIndex={0}
                >
                    <input
                        data-testid="urlInput"
                        ref={this.inputRef}
                        list="endpoints"
                        placeholder={
                            this.props.isLoadingEndpoints
                                ? 'Loading API endpoints'
                                : 'Type or select an API endpoint URL'
                        }
                        className="form-control"
                    />
                    <datalist id="endpoints" data-testid="endpoints">
                        {urls.map((url) => (
                            <option key={url} value={url} />
                        ))}
                    </datalist>
                    <div className="margin-left nowrap">
                        <ButtonPrimary onClick={() => this.onRequest('GET')}>GET</ButtonPrimary>
                        <ButtonPrimary onClick={() => this.onRequest('POST')}>POST</ButtonPrimary>
                        <ButtonPrimary onClick={() => this.onRequest('PUT')}>PUT</ButtonPrimary>
                        <ButtonDanger onClick={() => this.onRequest('DELETE')}>DELETE</ButtonDanger>
                    </div>
                </div>
                <span className="help-block">{this.state.description}</span>
                <div className="request-response-container">
                    <div className="request">
                        <h4 className="text-center">Request</h4>
                        <CodeEditor
                            value={this.state.request || ''}
                            scrollBar
                            highlightActiveLine
                            name="request-editor"
                            onChange={this.onRequestChange}
                        />
                    </div>
                    <div className="response">
                        <h4 className="text-center">Response</h4>
                        <CodeEditor
                            mode={this.state.type}
                            value={this.state.response || ''}
                            highlightActiveLine
                            readOnly
                            name="response-editor"
                        />
                    </div>
                </div>
                {this.state.isLoading ? <Loader /> : null}
            </div>
        );
    }
}

const mapStateToProps = ({ APIEndpoints }) => ({
    endpoints: APIEndpoints.endpoints,
    isLoadingEndpoints: APIEndpoints.isLoading,
    selectedFlow: APIEndpoints.selectedFlow,
});

const mapDispatchToProps = {
    fetchEndpoints: fetchAPIEndpoints,
    addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Build);
