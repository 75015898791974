import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    fetchReceivedTenantInvitations,
    fetchReceivedUserInvitations,
} from '../../actions/reduxActions/organizations';
import CurrentTenantInviteList from './CurrentTenantInviteList';
import CurrentUserInviteList from './CurrentUserInviteList';

const Invitations = ({
    tenantInvitations = [],
    userInvitations = [],
    fetchTenantInvitations,
    fetchUserInvitations,
}) => {
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchTenantInvitations();
        fetchUserInvitations();
    }, []);

    return (
        <>
            <CurrentTenantInviteList invitations={tenantInvitations} />
            <CurrentUserInviteList invitations={userInvitations} />
        </>
    );
};

const mapStateToProps = (state) => ({
    tenantInvitations: state.organizationReceivedTenantInvitations,
    userInvitations: state.organizationReceivedUserInvitations,
});

const mapDispatchToProps = {
    fetchTenantInvitations: fetchReceivedTenantInvitations,
    fetchUserInvitations: fetchReceivedUserInvitations,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invitations);
