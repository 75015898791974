import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ENGINE_API_URL } from '../../../constants';
import { getTenantId } from '../../../utils/tenant';

// This is only needed for testing
export const mockHubInvokeEvent = (
    _methodName: string,
    _argument1: unknown,
    _argument2: unknown,
    _argument3: unknown,
    _argument4: unknown,
    _argument5: unknown,
) => undefined;

export const connection = () =>
    new HubConnectionBuilder()
        .withUrl(`${ENGINE_API_URL ?? ''}/debug?tenantId=${getTenantId()}`, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
        })
        .configureLogging(LogLevel.Information)
        .build();
