import type { HubConnection } from '@microsoft/signalr';
import { type ReactElement, createContext, useContext, useEffect, useReducer, useRef } from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { TAB_TYPES } from '../../../constants';
import { getCallStackItemData } from '../../../sources/debugger';
import type {
    AddNotification,
    Breakpoint,
    CriteriaType,
    DebugAction,
    DebugTabId,
    StackItem,
    SwitchConfirmation,
    Tab,
} from '../../../types';
import { guid } from '../../../utils/guid';
import { getTenantId } from '../../../utils/tenant';
import { connection } from './hubConnect';
import { checkBreaks } from './tabs/breakpoints/evaluteBreakpoints';

interface DebugProviderContext {
    breakpoints: Breakpoint[];
    updateBreakpoint: (breakpoint: Breakpoint) => void;
    addBreakpoint: (breakpoint?: Breakpoint) => void;
    removeBreakpoint: (id: string) => void;
    callStack: StackItem[];
    attachDebugger: (stateId: string) => void;
    setCallStackItemsPinned: (pinned: boolean) => void;
    isDebuggerAttached: boolean;
    onClickCallStackItem: (stackItemId: string) => void;
    callStackItemsArePinned: boolean;
    activeTabId: DebugTabId;
    setActiveTabId: (active: DebugTabId) => void;
    flowId: string;
    dispatch: (value: DebugAction) => void;
    confirmSwitchFlow: (stackItemFlowId: string, stackItemFlowName: string) => void;
    switchFlowConfirmation: SwitchConfirmation | null;
}

const Context = createContext<DebugProviderContext | undefined>(undefined);

const initialState: DebuggerState = {
    breakpoints: [],
    callStack: [],
    isDebuggerAttached: false,
    activeTabId: 'STATE_VALUES',
    callStackItemsArePinned: false,
    switchFlowConfirmation: null,
};

interface Props {
    stateId: string;
    flowId: string;
    startElementId: null | string;
    initialState?: Partial<DebuggerState>;
    addNotification: AddNotification;
    children: ReactElement | ReactElement[];
    zoomToMapElement: (elementId: string) => void;
    updateUrlAndTab: (
        {
            key,
            type,
            title,
            elementId,
            tenantId,
        }: {
            key: string;
            type: string;
            title: string | null;
            elementId: string;
            tenantId: string;
        },
        navigate: NavigateFunction,
    ) => void;
    tabs: Tab[];
}

interface DebuggerState {
    callStack: StackItem[];
    breakpoints: Breakpoint[];
    isDebuggerAttached: boolean;
    switchFlowConfirmation: SwitchConfirmation | null;
    activeTabId: DebugTabId;
    callStackItemsArePinned: boolean;
}

const reducer = (state: DebuggerState, action: DebugAction): DebuggerState => {
    switch (action.type) {
        case 'setDebugStateId':
            return {
                ...state,
                switchFlowConfirmation: null,
            };
        case 'dismissSwitchFlowConfirmation':
            return {
                ...state,
                switchFlowConfirmation: null,
            };
        case 'setIsDebuggerAttached':
            return {
                ...state,
                isDebuggerAttached: action.isAttached,
            };
        case 'setActiveTab':
            return {
                ...state,
                activeTabId: action.activeTabId,
            };
        case 'setCallStackItemsPinned':
            return {
                ...state,
                callStackItemsArePinned: action.pinned,
            };
        case 'addToCallStack': {
            let updatedCallStack = state.callStack;

            if (!state.callStackItemsArePinned) {
                // Deselect any current selection
                updatedCallStack = state.callStack.map((item) => {
                    item.isSelected = false;
                    return item;
                });
            }

            const shouldSelect = !state.callStackItemsArePinned;

            const newCallStackItem: StackItem = {
                id: action.stackItemId,
                flowId: action.stackItemFlowId,
                flowName: action.flowName,
                mapElementId: action.mapElementId,
                developerName: action.mapElementName,
                dateTimeExecuted: new Date().toLocaleString(undefined, {
                    dateStyle: 'medium',
                    timeStyle: 'medium',
                }),
                stateValues: null,
                rootFaults: null,
                breakpointsHit: null,
                isSelected: shouldSelect,
            };

            updatedCallStack = [newCallStackItem, ...updatedCallStack];

            return { ...state, callStack: updatedCallStack };
        }
        case 'setSelectedCallStackItem': {
            const updatedCallStack = state.callStack.map((callStackItem) => ({
                ...callStackItem,
                isSelected: callStackItem.id === action.stackItemId && !callStackItem.isSelected,
            }));
            return { ...state, callStack: updatedCallStack };
        }
        case 'setBreakpointsHit': {
            const updatedCallStack = state.callStack.map((callStackItem) => {
                if (callStackItem.id === action.stackItemId) {
                    return {
                        ...callStackItem,
                        breakpointsHit: action.breakpointHits,
                    };
                }
                return callStackItem;
            });
            return { ...state, callStack: updatedCallStack };
        }
        case 'setCallStackItemData': {
            const updatedCallStack = state.callStack.map((callStackItem) => {
                if (callStackItem.id === action.stackItemId) {
                    // Breakpoints must be evaluated as call stack items come through
                    const breakpointsHit = checkBreaks(
                        action.callStackItemData.stateValues,
                        state.breakpoints,
                    );

                    return {
                        ...callStackItem,
                        stateValues: action.callStackItemData.stateValues,
                        rootFaults: action.callStackItemData.rootFaults,
                        breakpointsHit,
                    };
                }

                return callStackItem;
            });

            return { ...state, callStack: updatedCallStack };
        }
        case 'addBreakpoint': {
            const newBreakpoint = {
                id: guid(),
                value: null,
                operator: '' as CriteriaType,
                expectedContentValue: '',
            };
            return {
                ...state,
                breakpoints: [...state.breakpoints, action.breakpoint || newBreakpoint],
            };
        }
        case 'updateBreakpoint': {
            return {
                ...state,
                breakpoints: action.breakpoints,
            };
        }
        case 'removeBreakpoint': {
            const filteredBreakpoints = state.breakpoints.filter(
                (breakpoint) => breakpoint.id !== action.breakpointId,
            );
            return {
                ...state,
                breakpoints: filteredBreakpoints,
            };
        }
        case 'switchFlow': {
            return {
                ...state,
                switchFlowConfirmation: action.switchFlowConfirmation,
            };
        }
        case 'clearCallStack':
            return {
                ...state,
                callStack: [],
            };
    }
};

const DebugProvider = ({
    stateId,
    flowId,
    startElementId,
    initialState: suppliedInitialState,
    addNotification,
    zoomToMapElement,
    updateUrlAndTab,
    tabs,
    children,
}: Props) => {
    const [debugState, dispatch] = useReducer(reducer, {
        ...initialState,
        ...(suppliedInitialState ?? {}),
    });

    const { activeTabId, isDebuggerAttached, callStack, breakpoints, callStackItemsArePinned } =
        debugState;

    const tab = tabs.find((tab) => tab.isActive) as Tab;

    const navigate = useNavigate();

    const hubConnection = useRef<Partial<HubConnection>>({
        start: () => Promise.resolve(),
        on: () => undefined,
        send: () => Promise.resolve(),
        stop: () => Promise.resolve(),
    });

    const setActiveTabId = (activeTabId: DebugTabId) => {
        dispatch({
            type: 'setActiveTab',
            activeTabId,
        });
    };

    const setIsDebuggerAttached = (isAttached: boolean) => {
        dispatch({
            type: 'setIsDebuggerAttached',
            isAttached,
        });
    };

    const setCallStackItemsPinned = (pinned: boolean) => {
        dispatch({
            type: 'setCallStackItemsPinned',
            pinned,
        });
    };

    const updateBreakpointHits = (updatedBreakpoints: Breakpoint[]) => {
        debugState.callStack.forEach((callStackItem) => {
            if (callStackItem.stateValues) {
                const breakpointHits = checkBreaks(callStackItem.stateValues, updatedBreakpoints);

                dispatch({
                    type: 'setBreakpointsHit',
                    breakpointHits,
                    stackItemId: callStackItem.id,
                });
            }
        });
    };

    const updateBreakpoint = (breakpointToUpdate: Breakpoint) => {
        const updatedBreakpoints = debugState.breakpoints.map((breakpoint) => {
            if (breakpoint.id === breakpointToUpdate.id) {
                return breakpointToUpdate;
            }

            return breakpoint;
        });

        dispatch({
            type: 'updateBreakpoint',
            breakpoints: updatedBreakpoints,
        });

        // Breakpoints must be re-evaluated when a breakpoint changes
        updateBreakpointHits(updatedBreakpoints);
    };

    const removeBreakpoint = (id: string) => {
        dispatch({
            type: 'removeBreakpoint',
            breakpointId: id,
        });
    };

    const addBreakpoint = (breakpoint?: Breakpoint) => {
        dispatch({
            type: 'addBreakpoint',
            breakpoint: breakpoint,
        });
    };

    const addToCallStack = (
        mapElementId: string,
        stackItemId: string,
        stackItemFlowId: string,
        mapElementName: string,
        flowName: string,
    ) => {
        getStateValues(stackItemId);
        dispatch({
            type: 'addToCallStack',
            mapElementId,
            stackItemId,
            stackItemFlowId,
            mapElementName,
            flowName,
        });
    };

    const confirmSwitchFlow = (stackItemFlowId: string, stackItemFlowName: string) => {
        if (stackItemFlowId !== flowId) {
            updateUrlAndTab(
                {
                    key: tab.key,
                    type: TAB_TYPES.flow,
                    title: stackItemFlowName,
                    elementId: stackItemFlowId,
                    tenantId: getTenantId(),
                },
                navigate,
            );
        }
        dispatch({ type: 'dismissSwitchFlowConfirmation' });
    };

    const isFlowTabAlreadyOpen = (idOfFlowToSwitchTo: string) => {
        return !tabs.some((tab) => tab.elementId === idOfFlowToSwitchTo);
    };

    const onClickCallStackItem = (stackItemId: string) => {
        dispatch({ type: 'setSelectedCallStackItem', stackItemId });
    };

    const getStateValues = async (stackItemId: string) => {
        try {
            const callStackItemData = await getCallStackItemData(stackItemId);
            dispatch({
                type: 'setCallStackItemData',
                callStackItemData,
                stackItemId,
            });
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).toString(),
                isPersistent: true,
            });
        }
    };

    const attachDebugger = (stateId: string) => {
        if (hubConnection.current.send) {
            hubConnection.current
                .send('AttachDebugger', stateId)
                .then(() => {
                    setIsDebuggerAttached(true);
                })
                .catch((err: string) =>
                    addNotification({
                        type: 'error',
                        message: err,
                        isPersistent: true,
                    }),
                );
        }
    };

    const selectedCallStackItem = debugState.callStack.find(
        (callStackItem) => callStackItem.isSelected,
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (selectedCallStackItem) {
            // Focus the canvas on the map element associated to the currently selected call stack item
            zoomToMapElement(selectedCallStackItem.mapElementId);
        }
    }, [
        startElementId /* A change to the start element ID indicates a new flow has been rendered */,
    ]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        hubConnection.current = connection();

        if (hubConnection.current.start && hubConnection.current.on) {
            hubConnection.current
                .start()
                .then(() => {
                    return hubConnection.current.send?.('AttachDebugger', stateId);
                })
                .then(() => {
                    return hubConnection.current.on?.(
                        'StackItemReceived',
                        (
                            mapElementId: string,
                            stackItemId: string,
                            stackItemFlowId: string,
                            mapElementName: string,
                            flowName: string,
                        ) => {
                            addToCallStack(
                                mapElementId,
                                stackItemId,
                                stackItemFlowId,
                                mapElementName,
                                flowName,
                            );
                        },
                    );
                })
                .then(() => {
                    setIsDebuggerAttached(true);
                })
                .catch((err: string) =>
                    addNotification({
                        type: 'error',
                        message: err,
                        isPersistent: true,
                    }),
                );
        }

        return () => {
            if (hubConnection.current.stop) {
                hubConnection.current.stop().catch((err: string) =>
                    addNotification({
                        type: 'error',
                        message: err,
                        isPersistent: true,
                    }),
                );
            }
        };
    }, [stateId]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        if (selectedCallStackItem) {
            if (selectedCallStackItem.flowId !== flowId) {
                dispatch({
                    type: 'switchFlow',
                    switchFlowConfirmation: {
                        stackItemId: selectedCallStackItem.id,
                        stackItemFlowId: selectedCallStackItem.flowId,
                        stackItemFlowName: selectedCallStackItem.flowName,
                        canSwitch: isFlowTabAlreadyOpen(selectedCallStackItem.flowId),
                    },
                });
            } else {
                // Focus the canvas on the map element associated to the currently selected call stack item
                zoomToMapElement(selectedCallStackItem.mapElementId);
            }
        }
    }, [selectedCallStackItem?.id]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        updateBreakpointHits(debugState.breakpoints);
    }, [debugState.breakpoints.length]);

    const value: DebugProviderContext = {
        breakpoints,
        addBreakpoint,
        updateBreakpoint,
        removeBreakpoint,
        callStack,
        callStackItemsArePinned,
        setCallStackItemsPinned,
        attachDebugger,
        isDebuggerAttached,
        onClickCallStackItem,
        activeTabId,
        setActiveTabId,
        flowId,
        dispatch,
        confirmSwitchFlow,
        switchFlowConfirmation: debugState.switchFlowConfirmation,
    };

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useDebug = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useDebug must be used within a DebugProvider');
    }
    return context;
};

export { DebugProvider, useDebug };
