import TenantName from '../../../ts/components/generic/TenantName';
import {
    cancelTenantInvitation,
    acknowledgeRejectedTenantInvitation,
} from '../../actions/reduxActions/organizations';
import { connect } from 'react-redux';
import Table from '../../../ts/components/generic/Table';
import translations from '../../../ts/translations';
import { Trash } from '@phosphor-icons/react';

/**
 * @description Flow Org. pending invitation list
 *
 * Display a list of sent tenant invitations and their statuses.
 */
const PendingTenantInviteList = ({
    cancelInvitation,
    acknowledgeRejection,
    invitations = [],
    organizationId,
}) => {
    const pending = invitations.filter(
        (item) =>
            item.status !== 'accepted' &&
            item.status !== 'canceled' &&
            item.status !== 'acknowledged' &&
            item.organization.id === organizationId,
    );

    return (
        <>
            <h4>Tenants with pending or rejected invitations</h4>
            <Table
                wrapperClassName="margin-top"
                columns={[
                    {
                        renderHeader: () => translations.COMMON_TABLE_tenant,
                        renderCell: ({ item }) => (
                            <span title={`ID: ${item.tenant.id}`}>
                                <TenantName name={item.tenant.developerName} />
                            </span>
                        ),
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_status,
                        renderCell: ({ item }) => <span className="capitalize">{item.status}</span>,
                        size: '20%',
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_date_sent,
                        renderCell: ({ item }) =>
                            new Date(item.invitedAt).toLocaleString(undefined, {
                                dateStyle: 'medium',
                                timeStyle: 'short',
                            }),
                        size: '11rem',
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_actions,
                        renderCell: ({ item }) => (
                            <div className="action-btn-wrapper">
                                <button
                                    title="Remove Invitation"
                                    className="table-icon table-icon-delete"
                                    aria-label="Remove Invitation"
                                    onClick={() =>
                                        item.status !== 'rejected'
                                            ? cancelInvitation({
                                                  invitedTenantId: item.tenant.id,
                                              })
                                            : acknowledgeRejection({
                                                  invitedTenantId: item.tenant.id,
                                              })
                                    }
                                    type="button"
                                >
                                    <Trash />
                                </button>
                            </div>
                        ),
                        size: '5rem',
                    },
                ]}
                items={pending}
                pagination={true}
            />
        </>
    );
};

const mapDispatchToProps = {
    cancelInvitation: cancelTenantInvitation,
    acknowledgeRejection: acknowledgeRejectedTenantInvitation,
};

export default connect(null, mapDispatchToProps)(PendingTenantInviteList);
