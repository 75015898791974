import { useComponents } from './CustomPageComponentsProvider';
import ConfirmModal from '../../../../ts/components/generic/modal/ConfirmModal';
import DependencyTable from '../../../../ts/components/generic/DependencyTable';
import ComponentsDependentModal from '../../../../ts/components/admin/customPageComponents/CustomPageComponentsDependentModal';
import { FileArrowDown, Trash, Warning } from '@phosphor-icons/react';
import { componentRegistry } from '../../../../ts/components/page-editor/registry';
import translations from '../../../../ts/translations';
import Sortable from '../../../../ts/components/generic/Sortable';
import Table from '../../../../ts/components/generic/Table';
import PageHeader from '../../../../ts/components/generic/PageHeader';
import FileDrop from '../../../../ts/components/generic/upload/FileDrop';
import { useRef } from 'react';

const ComponentsList = () => {
    const {
        components,
        setCurrentScreen,
        setEditingComponent,
        COMPONENT_SCREENS,
        exportComponent,
        tryImportComponent,
        fetchPageComponents,
        editNewComponent,
        paging,
        updatePaging,
        conflict,
        pendingImport,
        closeConflictModal,
        confirmImportOverwrite,
        tryDeleteComponent,
        componentsLoading,
    } = useComponents();

    const modalContainerRef = useRef(null);

    const columns = [
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection={'ASC'}
                    direction={paging.orderBy === 'developerName' ? paging.orderDirection : null}
                    onSort={(direction) => onSort({ orderBy: 'developerName', direction })}
                >
                    {translations.COMMON_TABLE_name}
                </Sortable>
            ),
            renderCell: ({ item }) => (
                <button
                    className="link-emulate"
                    title={item.developerName}
                    onClick={() => {
                        setCurrentScreen(COMPONENT_SCREENS.componentDetail);
                        setEditingComponent(item);
                    }}
                    type="button"
                >
                    {item.developerName}
                </button>
            ),
        },
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection={'ASC'}
                    direction={paging.orderBy === 'dateModified' ? paging.orderDirection : null}
                    onSort={(direction) => onSort({ orderBy: 'dateModified', direction })}
                >
                    {translations.COMMON_TABLE_last_modified}
                </Sortable>
            ),
            renderCell: ({ item }) =>
                new Date(item.dateModified).toLocaleString(undefined, {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                }),
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_key,
            renderCell: ({ item }) =>
                componentRegistry[item.key.toUpperCase()] ? (
                    <span title="This component overrides a standard component">
                        {item.key}
                        <Warning size={20} className="warning-icon" />
                    </span>
                ) : (
                    item.key
                ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_summary,
            renderCell: ({ item }) => item.developerSummary,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`Export ${item.developerName}`}
                        className="table-icon"
                        onClick={() => exportComponent(item)}
                        aria-label={`Export ${item.developerName}`}
                        type="button"
                    >
                        <FileArrowDown />
                    </button>
                    <button
                        title={`Delete ${item.developerName}`}
                        className="table-icon table-icon-delete"
                        onClick={() => tryDeleteComponent(item)}
                        aria-label={`Delete ${item.developerName}`}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '6rem',
        },
    ];

    const onSort = ({ orderBy, direction }) =>
        updatePaging({
            ...paging,
            orderBy,
            orderDirection: direction,
        });

    const onPage = (updatedPage) =>
        updatePaging({
            ...paging,
            page: updatedPage,
        });

    const onFilter = (searchQuery) =>
        updatePaging({
            ...paging,
            page: 1,
            search: searchQuery,
        });

    const onChangeItemsPerPage = (pageSize) => {
        updatePaging({
            ...paging,
            page: 1,
            pageSize,
        });
    };

    return (
        <div className="admin-page components flow-wrapper" ref={modalContainerRef}>
            <PageHeader
                title="Components"
                onAdd={() => {
                    setCurrentScreen(COMPONENT_SCREENS.componentDetail);
                    editNewComponent();
                }}
                onRefresh={fetchPageComponents}
                onSearch={onFilter}
                searchQuery={paging.search}
                searchPlaceholderText="Search"
                addText="New Component"
                refreshTitle="Refresh Results"
            >
                <FileDrop
                    className="margin-bottom-large"
                    placeholder="Drop exported component file here, or click to browse to import"
                    onChange={tryImportComponent}
                    fileTypes=".component"
                />
            </PageHeader>

            <ConfirmModal
                title="Overwrite existing component?"
                messages={
                    pendingImport && conflict
                        ? [
                              `We have detected that this import will replace a Component that already
                        exists in this Tenant.`,
                              <DependencyTable
                                  key="conflict"
                                  dependents={{
                                      existingElements: { customPageComponentElements: [conflict] },
                                  }}
                              />,
                          ]
                        : []
                }
                onCancel={closeConflictModal}
                onConfirm={confirmImportOverwrite}
                show={conflict}
                container={modalContainerRef.current}
            />
            <ComponentsDependentModal container={modalContainerRef.current} />

            <Table
                wrapperClassName="margin-top"
                columns={columns}
                items={components}
                isLoading={componentsLoading}
                pagination={{
                    page: paging.page,
                    total: paging.total,
                    pageSize: paging.pageSize,
                    changePage: onPage,
                    changeItemsPerPage: onChangeItemsPerPage,
                }}
            />
        </div>
    );
};

export default ComponentsList;
