import { useEffect, useState } from 'react';
import SearchInput from '../../../../ts/components/generic/SearchInput';
import translations from '../../../../ts/translations';
import ConfirmModal from '../../../../ts/components/generic/modal/ConfirmModal';
import {
    editLocalRuntime,
    getLocalRuntimes,
    deleteLocalRuntime,
} from '../../../actions/reduxActions/localRuntimes';
import { connect } from 'react-redux';
import { FLOW_ORG_PAGES } from '../../../../ts/constants/organization';
import { stringContains, stringReplace } from '../../../../ts/utils/string';
import '../../../../../css/local-runtimes.less';
import { Trash, CheckCircle, WarningCircle, Question } from '@phosphor-icons/react';
import Table from '../../../../ts/components/generic/Table';
import { formatDistanceStrict } from 'date-fns';

const deleteRuntime = (removeLocalRuntime, setSelectedRuntime, selectedRuntime) => {
    removeLocalRuntime(selectedRuntime);
    setSelectedRuntime(null);
};

/**
 *
 * @param {Array} localRuntimes Local runtime instances inside of the current org
 * @param {Function} edit
 * @param {Boolean} isLoading
 * @param {Boolean} isDeleting The state of whether a runtime is still being deleted
 * @param {Function} fetchLocalRuntimes Callback to fetch runtime instances
 * @param {Function} removeLocalRuntime Function to delete the selected local runtime
 * @param {Function} navigateTo Callback to navigate to a specific page
 * @param {Object} container Parent container of the modals
 *
 * @description Displays a searchable table of local runtime instances
 */
const LocalRuntimeList = ({
    localRuntimes,
    edit,
    isLoading,
    isDeleting,
    fetchLocalRuntimes,
    removeLocalRuntime,
    navigateTo,
    container,
}) => {
    const [filteredResults, filter] = useState(localRuntimes);
    const [searchFilter, setSearchFilter] = useState('');
    const [selectedRuntime, setSelectedRuntime] = useState(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchLocalRuntimes();

        const interval = setInterval(() => {
            fetchLocalRuntimes();
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        filter(localRuntimes);
    }, [localRuntimes]);

    const onSearch = (searchTerm) => {
        setSearchFilter(searchFilter);
        const queryResult = localRuntimes.filter((runtime) =>
            stringContains(runtime.developerName, searchTerm, false),
        );
        filter(queryResult);
    };
    const nodeStatusCell = (item) => {
        const label = item.status.toLowerCase();
        const labelClass = label.includes('online')
            ? 'label-success'
            : label.includes('offline')
              ? 'label-danger'
              : 'label-default';
        let icon = '';
        if (label.includes('offline')) {
            icon = (
                <span
                    title={translations.LOCAL_RUNTIME_possible_offline_node_title_text}
                    className="table-icon-large"
                >
                    <WarningCircle color="#F4C57F" />
                </span>
            );
        } else if (label.includes('unknown')) {
            icon = (
                <span
                    title={translations.LOCAL_RUNTIME_unknown_status_title_text}
                    className="table-icon-large"
                >
                    <Question color="#8C8C8C" />
                </span>
            );
        } else if (label.includes('online')) {
            icon = (
                <span
                    title={translations.LOCAL_RUNTIME_online_status_title_text}
                    className="table-icon-large"
                >
                    <CheckCircle color="#0EA076" />
                </span>
            );
        }
        return (
            <div className="table-icon-text-wrapper">
                {icon}
                <span className={`label ${labelClass}`}>{label}</span>
            </div>
        );
    };

    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_connection_name,
            renderCell: ({ item }) => (
                <button
                    className="link-emulate"
                    title="Edit Local Runtime"
                    onClick={() => edit(item.id, navigateTo)}
                    aria-label="Edit Local Runtime"
                    type="button"
                >
                    {item.developerName}
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_version,
            renderCell: ({ item }) => {
                const label = item.latestVersion ? item.latestVersion : 'unknown';
                const labelClass = label.includes('unknown') ? 'label-default' : 'label-success';
                return <span className={`label ${labelClass}`}>{label}</span>;
            },
        },
        {
            renderHeader: () => translations.COMMON_TABLE_status,
            renderCell: ({ item }) => {
                return nodeStatusCell(item);
            },
        },
        {
            renderHeader: () => translations.COMMON_TABLE_last_seen,
            renderCell: ({ item }) => {
                const label = item.lastPingAt
                    ? formatDistanceStrict(new Date(item.lastPingAt), new Date(), {
                          addSuffix: true,
                      })
                    : 'Never';
                return <span>{label}</span>;
            },
        },
        {
            renderHeader: () => translations.COMMON_TABLE_shared_tenants,
            renderCell: ({ item }) => item.numberOfTenants,
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <div className="action-btn-wrapper">
                    <button
                        title="Delete Local Runtime"
                        className="table-icon table-icon-delete"
                        aria-label="Delete Local Runtime"
                        onClick={() => setSelectedRuntime(item)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <>
            <h2 className="admin-heading-2" id="localRuntimes">
                {translations.LOCAL_RUNTIME_title}
            </h2>
            <p>{translations.LOCAL_RUNTIME_summary}</p>
            <div className="header-actions">
                <button
                    onClick={() => navigateTo(FLOW_ORG_PAGES.localRuntimeCreate)}
                    className="btn btn-sm btn-primary"
                    type="button"
                >
                    New Local Runtime Connection
                </button>
                <SearchInput value={searchFilter} onChange={onSearch} />
            </div>
            <Table
                wrapperClassName="margin-top runtimes-table"
                items={filteredResults}
                columns={columns}
                isLoading={isLoading}
            />

            <ConfirmModal
                show={selectedRuntime !== null || isDeleting === true}
                title={translations.LOCAL_RUNTIME_delete_modal_title}
                messages={[
                    stringReplace(translations.LOCAL_RUNTIME_delete_modal_message, {
                        connectionName: selectedRuntime?.developerName,
                    }),
                ]}
                buttonStyle="danger"
                buttonCaption="Delete"
                onCancel={() => setSelectedRuntime(null)}
                onConfirm={() =>
                    deleteRuntime(removeLocalRuntime, setSelectedRuntime, selectedRuntime)
                }
                isInProgress={isDeleting}
                container={container.current}
            />
        </>
    );
};

const mapStateToProps = (state, ownProps) => ({
    localRuntimes: state.localRuntimes.runtimeList,
    isLoading: state.localRuntimes.isLoading,
    isDeleting: state.localRuntimes.isDeleting,
    navigateTo: ownProps.navigateTo,
});

const mapDispatchToProps = {
    fetchLocalRuntimes: getLocalRuntimes,
    edit: editLocalRuntime,
    removeLocalRuntime: deleteLocalRuntime,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalRuntimeList);
