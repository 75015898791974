/**
 * Configuration file that holds the set of predefined pages that belong to the
 * Organization main view and the set of side menu links for each page (that has
 * a side menu).
 */

/**
 * Import React components for each page that belongs or should be usable in
 * this Organization main view.
 */
import AdminUI from './AdminUI';
import LinkedTenantUI from './LinkedTenantUI';
import NotLinkedTenantUI from './NotLinkedTenantUI';
import LocalRuntimeDetail from './localRuntimes/LocalRuntimeDetail';
import { createLocalRuntime, updateLocalRuntime } from '../../actions/reduxActions/localRuntimes';
import LocalRuntimeCreate from './localRuntimes/LocalRuntimeCreate';
import LocalRuntimeEdit from './localRuntimes/LocalRuntimeEdit';
import LocalRuntimeDisplayToken from './localRuntimes/LocalRuntimeDisplayToken';
import { FLOW_ORG_PAGES } from '../../../ts/constants/organization';

/**
 * Configuration object holding all the side menu links for each page
 *
 * If a page does not have a side menu then there is no need to add any config
 * for it here.
 *
 * Usually the first link should be set as active and any other links in the
 * same side menu as not active. The actual side menu component will handle
 * the highlighting as links are clicked by the user.
 *
 * @param {Array} <page_id> Property that holds the set of link definitions for a specific page
 * @param {String} label Text shown in the link
 * @param {String} link Link's hash id used for navigating within the parent page
 * @param {Boolean} active Determines if the link is highlighted
 *
 * @example
 *
 *  pageSideMenuItems = {
 *      unique_page_id: [
 *          {
 *              label: 'Link 1',
 *              link: '#link_id',
 *              active: true,
 *          },
 *          {
 *              label: 'Link 2',
 *              link: '#link_2_1',
 *              active: false,
 *          },
 *          ...
 *      ],
 *      ...
 *  };
 */
const pageSideMenuItems = {
    flowOrgAdminUI: [
        {
            label: 'Current Organization',
            link: '#organizationDetails',
            active: true,
        },
        {
            label: 'Organization Invitations',
            link: '#organizationInvitations',
            active: true,
        },
        {
            label: 'Tenants',
            link: '#organizationTenants',
            active: false,
        },
        {
            label: 'Administrators',
            link: '#organizationAdministrators',
            active: false,
        },
        {
            label: 'Local Runtimes',
            link: '#localRuntimes',
            active: false,
        },
    ],
};

/**
 * Configuration array holding all the pages that belong to the same main view
 *
 * @param {String} id A unique page id
 * @param {String} label Name of the page, also used for main page title
 * @param {(null|String)} parent Id of the parent page if current page is a child of it
 * @param {(null|pageSideMenuItems|Array)} menu Set of side menu links for the page (prefer pageSideMenuItems.<unique_page_id>)
 * @param {React.Component} component React component of the current page
 *
 * @example
 *
 *  orgPages = [
 *      {
 *          id: 'uniquePageNameOrId',
 *          label: 'Page Title',
 *          parent: null | 'parentPageNameOrId',
 *          menu: null | pageSideMenuItems.uniquePageNameOrId | [...]
 *          component: React component (imported at the top of the file)
 *      },
 *      ...
 *  ];
 */
const orgPages = [
    {
        id: FLOW_ORG_PAGES.flowOrgAdminUI,
        label: 'Organization Settings',
        parent: null,
        menu: pageSideMenuItems.flowOrgAdminUI,
        component: AdminUI,
    },
    {
        id: FLOW_ORG_PAGES.flowOrgJoinedUI,
        label: 'Organization Settings',
        parent: null,
        menu: null,
        component: LinkedTenantUI,
    },
    {
        id: FLOW_ORG_PAGES.flowOrgNotJoinedUI,
        label: 'Organization Settings',
        parent: null,
        menu: null,
        component: NotLinkedTenantUI,
    },
    {
        id: FLOW_ORG_PAGES.localRuntimeCreate,
        label: 'Create Local Runtime',
        parent: FLOW_ORG_PAGES.flowOrgAdminUI,
        menu: null,
        component: LocalRuntimeDetail(LocalRuntimeCreate, createLocalRuntime),
    },
    {
        id: FLOW_ORG_PAGES.localRuntimeEdit,
        label: 'Edit Local Runtime',
        parent: FLOW_ORG_PAGES.flowOrgAdminUI,
        menu: null,
        component: LocalRuntimeDetail(LocalRuntimeEdit, updateLocalRuntime),
    },
    {
        id: FLOW_ORG_PAGES.localRuntimeDisplayToken,
        label: 'New Local Runtime Token',
        parent: FLOW_ORG_PAGES.flowOrgAdminUI,
        menu: null,
        component: LocalRuntimeDisplayToken,
    },
];

export default orgPages;
