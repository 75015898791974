export const ENVIRONMENT_CATEGORY_TYPES = {
    development: 'Development',
    test: 'Test',
    production: 'Production',
};

export const ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS = {
    all: 'All',
    lastYear: 'Last Year',
    lastThirtyDays: 'Last 30 Days',
    lastSevenDays: 'Last 7 Days',
    lastTwentyFourHours: 'Last 24 Hours',
};
