import { merge, pathOr } from 'ramda';
import { useState } from 'react';
import Breadcrumb from '../../../components/Breadcrumb';
import SearchInput from '../../../components/generic/SearchInput';
import translations from '../../../translations';
import type {
    DescribeServiceActionResponseAPI,
    DescribeServiceTypesAndActions,
    ServiceElementResponseAPI,
} from '../../../types/service';
import type { Paging, TypeElementRequestAPI } from '../../../types';
import { isNullOrEmpty } from '../../../utils/guard';
import { stringContains } from '../../../utils/string';
import Table, { type TableColumnList } from '../../generic/Table';
import Loader from '../../loader/Loader';
import type { ServiceViews } from '../ServiceConstants';
import { type NewServiceData, useServices } from '../contextProviders/ServicesProvider';

type ActionOrType = DescribeServiceActionResponseAPI | TypeElementRequestAPI;
type ActionOrTypeArray = ActionOrType[];
type DataArray = ActionOrTypeArray[];
type RenderFnArray = ((item: { item: ActionOrType }) => string)[];

interface InitialState {
    pagingArray: Paging[];
    queryArray: string[];
}

const initialState: InitialState = {
    pagingArray: [
        {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        {
            page: 1,
            pageSize: 20,
            total: 0,
        },
    ],
    queryArray: ['', '', '', ''],
};

interface ServicePreviewProps {
    currentServiceData: ServiceElementResponseAPI | null | undefined;
    newServiceData: NewServiceData | null | undefined;
    serviceTypesAndActions: DescribeServiceTypesAndActions | null | undefined;
    switchView: (view: ServiceViews) => void;
}

const ServicePreview = ({
    currentServiceData,
    newServiceData,
    serviceTypesAndActions,
    switchView,
}: ServicePreviewProps) => {
    const [previewState, setPreviewState] = useState<InitialState>(initialState);

    const { breadCrumbs, servicesState } = useServices();
    const { isLoading } = servicesState;

    if (isLoading) {
        return <Loader />;
    }

    const onSearch = (query: string, index: number) => {
        const queries = previewState.queryArray;
        queries[index] = query;
        const pagings = previewState.pagingArray;
        pagings[index].page = 1;
        setPreviewState({ queryArray: queries, pagingArray: pagings });
    };

    const onPage = (pageNumber: number, index: number) => {
        const pagings = previewState.pagingArray;
        pagings[index] = merge(pagings[index], { page: pageNumber });
        setPreviewState({ ...previewState, pagingArray: pagings });
    };

    const { queryArray, pagingArray } = previewState;

    const doesServiceAlreadyExist = !isNullOrEmpty(currentServiceData);

    const columnsArray: (
        | TableColumnList<DescribeServiceActionResponseAPI>
        | TableColumnList<TypeElementRequestAPI>
    )[] = [
        [
            // Existing Actions
            {
                renderHeader: () => translations.COMMON_TABLE_name,
                renderCell: ({ item }) => item.developerName,
            },
            {
                renderHeader: () => translations.COMMON_TABLE_uri,
                renderCell: ({ item }) => item.uriPart,
            },
            {
                renderHeader: () => translations.COMMON_TABLE_summary,
                renderCell: ({ item }) => item.developerSummary,
            },
        ] as TableColumnList<DescribeServiceActionResponseAPI>,
        [
            // Existing Types
            {
                renderHeader: () => translations.COMMON_TABLE_name,
                renderCell: ({ item }) => item.developerName,
            },
            {
                renderHeader: () => translations.COMMON_TABLE_summary,
                renderCell: ({ item }) => item.developerSummary,
            },
        ] as TableColumnList<TypeElementRequestAPI>,
        [
            // New Actions
            {
                renderHeader: () => translations.COMMON_TABLE_name,
                renderCell: ({ item }) => item.developerName,
            },
            {
                renderHeader: () => translations.COMMON_TABLE_uri,
                renderCell: ({ item }) => item.uriPart,
            },
        ] as TableColumnList<DescribeServiceActionResponseAPI>,
        [
            // New Types
            {
                renderHeader: () => translations.COMMON_TABLE_name,
                renderCell: ({ item }) => item.developerName,
            },
        ] as TableColumnList<TypeElementRequestAPI>,
    ];

    const dataArray: DataArray = [
        doesServiceAlreadyExist ? pathOr([], ['actions'], currentServiceData) : [],
        doesServiceAlreadyExist ? pathOr([], ['install', 'typeElements'], currentServiceData) : [],
        serviceTypesAndActions?.actions ?? [],
        serviceTypesAndActions?.typeElements ?? [],
    ];

    const queriedDataArray: DataArray = dataArray.map((dataItems, index) => {
        const query = queryArray[index];

        if (isNullOrEmpty(query)) {
            return dataItems;
        }
        const renderCellFns: RenderFnArray = columnsArray[index].map(
            (column) => column.renderCell,
        ) as RenderFnArray;

        // Take an item from the data items array and...
        return dataItems.filter((item: ActionOrType) => {
            // ...render it using renderCell functions and...
            return renderCellFns.some((renderFn) => {
                const renderResult = renderFn({ item });
                // ...then check if the rendered result matches/contains the query
                return stringContains(renderResult, query, false);
            });
        });
    });

    const visibleDataArray: DataArray = queriedDataArray.map((dataItems, index) => {
        const { page, pageSize } = pagingArray[index];
        const pageStart = pageSize * (page - 1);
        const pageEnd = pageStart + pageSize;

        return dataItems.slice(pageStart, pageEnd);
    });

    const renderSearchableTable = (title: string, index: number) => (
        <>
            <h2>
                {title}
                <small>{` Total: ${dataArray[index].length}`}</small>
            </h2>
            <div className="flex">
                <SearchInput onChange={(query) => onSearch(query, index)} value={''} />
            </div>
            <Table<ActionOrType>
                wrapperClassName="margin-top"
                columns={columnsArray[index] as TableColumnList<ActionOrType>}
                items={visibleDataArray[index]}
                testId={title}
                pagination={{
                    page: pagingArray[index].page,
                    total: queriedDataArray[index].length,
                    pageSize: pagingArray[index].pageSize,
                    changePage: (page) => onPage(page, index),
                }}
            />
        </>
    );

    const existingActionsAndTypesSection = doesServiceAlreadyExist ? (
        <>
            {renderSearchableTable('Existing Actions', 0)}
            {renderSearchableTable('Existing Types', 1)}
        </>
    ) : null;

    const renderNameAndUri = () => {
        const serviceName = doesServiceAlreadyExist
            ? currentServiceData?.developerName
            : newServiceData?.name;
        const serviceUri = doesServiceAlreadyExist ? currentServiceData?.uri : newServiceData?.url;

        return (
            <>
                <h2>{serviceName} Preview</h2>
                <p>{serviceUri}</p>
            </>
        );
    };

    return (
        <div className="full-height flex-column">
            <div className="admin-page">
                <div className="margin-bottom" data-testid="connector-breadcrumbs">
                    <Breadcrumb trail={breadCrumbs.trail} activeItemId={breadCrumbs.activeItemId} />
                </div>
                {renderNameAndUri()}
                {existingActionsAndTypesSection}
                {renderSearchableTable(`${doesServiceAlreadyExist ? 'New ' : ''}Actions`, 2)}
                {renderSearchableTable(`${doesServiceAlreadyExist ? 'New ' : ''}Types`, 3)}
            </div>
            <div className="admin-footer">
                <button
                    type="button"
                    className="btn btn-default outcome"
                    onClick={() => switchView('ServiceConfiguration')}
                >
                    {'Back'}
                </button>
            </div>
        </div>
    );
};

export default ServicePreview;
