import { useEffect, useState } from 'react';
import { useAuth } from '../../../ts/components/AuthProvider';
import PageSwitcher from '../../../ts/components/generic/PageSwitcher';
import Loader from '../../../ts/components/loader/Loader';
import { resolveOrgPageId, resolveOrgUserRole } from '../../../ts/utils/organization';
import orgPages from './orgPages';

/**
 * @description The wrapper component that figures out which Org page to render
 */
const InitialOrgPage = () => {
    const [orgPageId, setOrgPageId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { user, fetchUser, tenant } = useAuth();

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        // We need to wait for the user to be loaded every time we enter this screen
        // in case the user has been removed from the current tenant's organization
        (function awaitCurrentUser() {
            setIsLoading(true);
            fetchUser();
            setIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (tenant && user) {
            const userRole = resolveOrgUserRole(user, tenant);
            const pageId = resolveOrgPageId(userRole);
            setOrgPageId(pageId);
        }
    }, [user, tenant]);

    return isLoading ? (
        <Loader />
    ) : orgPageId ? (
        <PageSwitcher
            pages={orgPages}
            wrapperSelector="flow-organisation"
            initialPageId={orgPageId}
            tenant={tenant}
        />
    ) : null;
};

export default InitialOrgPage;
