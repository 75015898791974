import { useEffect, useState } from 'react';
import { useAuth } from '../../../../ts/components/AuthProvider';
import CopyableText from '../../../../ts/components/generic/CopyableText';
import Table from '../../../../ts/components/generic/Table';
import { RUNTIME_URI } from '../../../../ts/constants';
import translations from '../../../../ts/translations';
import { isNullOrUndefined } from '../../../../ts/utils/guard';
import SearchInput from '../../../../ts/components/generic/SearchInput';
import FlowIcon from '../../icons/FlowIcon';
import { useEnvironmentsProviders } from './EnvironmentsProvider';

const EnvironmentFlows = ({ environment }) => {
    const { getEnvironmentFlows, tenantId, environmentFlowsLoading } = useEnvironmentsProviders();
    const { tenant } = useAuth();

    const [flows, setFlows] = useState([]);
    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [total, setTotal] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        loadEnvironmentFlows(1, null);
    }, []);

    async function loadEnvironmentFlows(page, term) {
        const params = {
            environmentId: environment?.id,
            page: page ?? 1,
            pageSize,
            searchTerm: term,
        };

        const response = await getEnvironmentFlows(params);

        setFlows(response.items);
        setTotal(response._meta.total);
    }

    const onSearch = (term) => {
        setPage(1);
        setSearchQuery(term);
        loadEnvironmentFlows(1, term);
    };

    function getFlowUrl(flowId) {
        let url = '';
        if (!tenant?.tenantSettings?.themes) {
            url = `${RUNTIME_URI}/${tenantId}/play/${environment?.defaultPlayerName}?flow-id=${flowId}&environment-id=${environment?.id}`;
        } else if (tenant?.tenantSettings?.themes) {
            url = `${RUNTIME_URI}/${tenantId}/play/theme/${environment?.defaultThemeName}?flow-id=${flowId}&environment-id=${environment?.id}`;
        }
        return url;
    }

    const columns = [
        {
            renderHeader: () => 'Type',
            renderCell: ({ item }) => {
                if (!isNullOrUndefined(item)) {
                    return <FlowIcon width="20" height="20" />;
                }
            },
            size: '60px',
        },
        {
            renderHeader: () => 'Name',
            renderCell: ({ item }) => item.name,
        },
        {
            renderHeader: () => 'Version ID',
            renderCell: ({ item }) => (
                <CopyableText copyableText={item.versionId} hasCopyButton={true} />
            ),
        },
        {
            renderHeader: () => 'Deployment Notes',
            renderCell: ({ item }) => item.summary,
            size: '35rem',
        },
        {
            renderHeader: () => 'Last Modified',
            renderCell: ({ item }) => {
                return new Date(item.updatedAt).toLocaleString();
            },
        },
        {
            renderHeader: () => 'Run/Preview',
            renderCell: ({ item }) => {
                const flowUrl = getFlowUrl(item.flowId);
                return (
                    <a href={flowUrl} target="_blank" rel="noopener noreferrer">
                        {translations.ENVIRONMENT_run_in + environment?.name}
                    </a>
                );
            },
        },
    ];

    return (
        <>
            <SearchInput className="margin-top" onChange={onSearch} value={searchQuery} />

            <Table
                wrapperClassName="table margin-top margin-bottom"
                columns={columns}
                items={flows}
                isLoading={environmentFlowsLoading}
                pagination={{
                    page,
                    total,
                    pageSize,
                    changePage: (nextPage) => {
                        setPage(nextPage);
                        loadEnvironmentFlows(nextPage);
                    },
                }}
            />
        </>
    );
};

export default EnvironmentFlows;
