import {
    type ChangeEventHandler,
    type ElementRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import '../../../../css/forms.less';
import '../../../../css/tenant-name-input.less';
import {
    SUBTENANT_PARENT_NAME_SEPARATOR,
    TENANT_NAME_MAX_LENGTH,
    TENANT_NAME_PREFIX,
} from '../../constants';
import translations from '../../translations';
import { getTenantNameValidityMessage } from '../../utils/validation';
import FormGroup from '../generic/FormGroup';

interface Props {
    inputId: string;
    labelText: string;
    value: string;
    onChange: ({ value, isValid }: { value: string; isValid: boolean }) => void;
    parentTenantName?: string;
    showValidation?: boolean;
    isRequired?: boolean;
    autoFocus?: boolean;
}

const TenantNameInput = ({
    value,
    inputId,
    labelText,
    onChange,
    parentTenantName = '',
    showValidation = true,
    isRequired = true,
    autoFocus = false,
}: Props) => {
    const [validityMessage, setValidityMessage] = useState('');
    const [isValid, setIsValid] = useState(true);
    const inputRef = useRef<ElementRef<'input'>>(null);

    const validateInput = useCallback(
        (value: string) => {
            const message = getTenantNameValidityMessage(value);
            setValidityMessage(message);
            inputRef.current?.setCustomValidity(message);

            const isValid = !message;
            setIsValid(isValid);

            onChange({ value, isValid });
        },
        [onChange],
    );

    // Allow parent to validate default value on request
    useEffect(() => {
        if (showValidation) {
            validateInput(value);
        }
    }, [showValidation, validateInput, value]);

    const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        validateInput(value);
    };

    const formattedParentTenantName = parentTenantName.replace(TENANT_NAME_PREFIX, '');

    return (
        <div className="tenant-name-component-container">
            <FormGroup
                label={labelText}
                htmlFor={inputId}
                isValid={isValid}
                validationMessage={validityMessage}
                showValidation={showValidation}
                isRequired={isRequired}
            >
                <div className="tenant-name-input-addons-container">
                    <input
                        id={inputId}
                        className="form-control tenant-name-input-field"
                        maxLength={TENANT_NAME_MAX_LENGTH}
                        required
                        value={value}
                        onChange={handleChange}
                        autoFocus={autoFocus}
                        ref={inputRef}
                    />
                    {parentTenantName ? (
                        <span
                            data-testid="subtenant-name-suffix"
                            className="tenant-name-input-addon subtenant-name-suffix"
                        >
                            {`${SUBTENANT_PARENT_NAME_SEPARATOR}${formattedParentTenantName}`}
                        </span>
                    ) : null}
                </div>
            </FormGroup>
            <span className="help-block">{translations.TENANT_naming_restrictions_text}</span>
        </div>
    );
};

export default TenantNameInput;
