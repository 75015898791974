import { ExInput } from '@boomi/exosphere';
import type { OpenApiPathInfo } from '../../../types/service';
import translations from '../../../translations';

interface OpenApiTypeInputProps {
    pathInfo: OpenApiPathInfo;
    request: boolean;
    onItemChange: (info: OpenApiPathInfo, rowIndex: number) => void;
    rowIndex: number;
}

export const OpenApiTypeInput = ({
    pathInfo,
    request,
    onItemChange,
    rowIndex,
}: OpenApiTypeInputProps) => {
    const schema = request ? pathInfo.requestSchema : pathInfo.responseSchema;
    const oneOf = schema?.oneOfReferences;
    const requestTypeValue = pathInfo.requestSchema?.developerName ?? pathInfo.requestSchema?.title;
    const responseTypeValue =
        pathInfo.responseSchema?.developerName ?? pathInfo.responseSchema?.title;
    const defaultTypeValue = request ? requestTypeValue : responseTypeValue;

    const onRequestChanged = (e: Event, item: OpenApiPathInfo) =>
        onItemChange(
            {
                ...item,
                requestSchema: {
                    ...item.requestSchema,
                    developerName: (e.target as HTMLInputElement).value,
                },
            },
            rowIndex,
        );

    const onResponseChanged = (e: Event, item: OpenApiPathInfo) =>
        onItemChange(
            {
                ...item,
                responseSchema: {
                    ...item.responseSchema,
                    developerName: (e.target as HTMLInputElement).value,
                },
            },
            rowIndex,
        );

    const onChange = request ? onRequestChanged : onResponseChanged;

    const onOneOfChange = (e: Event, item: OpenApiPathInfo, value: [string, string]) => {
        const inputValue = (e.target as HTMLInputElement).value;
        const path = { ...item };
        if (request && path.requestSchema?.oneOfReferences) {
            path.requestSchema.oneOfReferences[value[0]] = inputValue;
        } else if (path.responseSchema?.oneOfReferences) {
            path.responseSchema.oneOfReferences[value[0]] = inputValue;
        }
        onItemChange(path, rowIndex);
    };

    return (
        <>
            {oneOf &&
                Object.entries(oneOf ?? {}).map((value) => (
                    <ExInput
                        key={value[0]}
                        className="within-table-input"
                        required={false}
                        type="text"
                        value={value[1]}
                        placeholder={translations.VALUE_EDITOR_value_name_placeholder}
                        autofocus={true}
                        data-testId="value-editor-request-name"
                        onInput={(e: Event) => onOneOfChange(e, pathInfo, value)}
                    />
                ))}
            {schema && !oneOf && (
                <ExInput
                    className="within-table-input"
                    required={false}
                    type="text"
                    value={defaultTypeValue}
                    placeholder={translations.VALUE_EDITOR_value_name_placeholder}
                    autofocus={true}
                    data-testId="value-editor-request-name"
                    onInput={(e: Event) => onChange(e, pathInfo)}
                />
            )}
        </>
    );
};
