import { useState } from 'react';
import translations from '../../translations';
import FileDrop from '../generic/upload/FileDrop';
import { importFlowTranslation } from '../../sources/flow';
import { useAuth } from '../AuthProvider';
import GenericModal from '../generic/modal/GenericModal';
import FooterButtons from '../generic/modal/FooterButtons';
import { TargetPlaceholderType, type TranslateFlowImportRequest } from '../../types/translation';

interface Props {
    notifyError: (error: unknown) => void;
    notifySuccess: (message: unknown) => void;
    container: HTMLDivElement;
    setIsProcessing: (isProcessing: boolean) => void;
}

const TranslationImport = ({ notifyError, notifySuccess, container, setIsProcessing }: Props) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [flowTranslation, setFlowTranslation] = useState<string | null>(null);
    const [placeholderType, setPlaceholderType] = useState<TargetPlaceholderType>(
        TargetPlaceholderType.ThrowError,
    );
    const [placeholderText, setPlaceholderText] = useState('');

    const { tenant } = useAuth();

    const cancelImport = () => {
        setFlowTranslation(null);
        setShowConfirmation(false);
    };

    const confirmImport = async () => {
        try {
            if (!tenant?.id) {
                throw new Error('Tenant ID is missing');
            }

            if (flowTranslation) {
                const translateFlowImportRequest: TranslateFlowImportRequest = {
                    translation: flowTranslation,
                    targetPlaceholderType: placeholderType,
                    targetPlaceholderText: placeholderText.trim(),
                };
                setIsProcessing(true);
                await importFlowTranslation({ tenantId: tenant.id, translateFlowImportRequest });
                notifySuccess(translations.TRANSLATIONS_import_success);
            }
        } catch (error) {
            notifyError((error as Error).message);
        } finally {
            setFlowTranslation(null);
            setShowConfirmation(false);
            setIsProcessing(false);
        }
    };

    const onDrop = (files: File[]) => {
        // Only accept one file at a time for now.
        if (files.length === 1) {
            const reader = new FileReader();
            reader.onloadend = (response) => {
                if (response?.target?.result) {
                    // eslint-disable-next-line @typescript-eslint/no-base-to-string
                    setFlowTranslation(response.target.result.toString());
                    setShowConfirmation(true);
                }
            };

            if (files[0]) {
                reader.readAsText(files[0]);
            }
        }
    };

    const getHelpText = () => {
        if (placeholderType === TargetPlaceholderType.ThrowError) {
            return translations.TRANSLATIONS_type_error_help_text;
        }
        if (placeholderType === TargetPlaceholderType.UseSource) {
            return translations.TRANSLATIONS_type_default_language_help_text;
        }
        if (placeholderType === TargetPlaceholderType.CustomText) {
            return translations.TRANSLATIONS_type_custom_text_help_text;
        }

        // It shouldn't be possible to not have one of these options selected.
        throw new Error("No 'handle missing elements' option selected.");
    };

    const renderBody = () => (
        <div className="import-confirm-wrapper">
            <div>{translations.TRANSLATIONS_confirm_import_message}</div>
            <select
                value={placeholderType}
                className="select-placeholder-type margin-top padding-left"
                onChange={(e) => setPlaceholderType(Number.parseInt(e.target.value))}
            >
                <option value={TargetPlaceholderType.ThrowError}>
                    {translations.TRANSLATIONS_type_error}
                </option>
                <option value={TargetPlaceholderType.UseSource}>
                    {translations.TRANSLATIONS_type_default_language}
                </option>
                <option value={TargetPlaceholderType.CustomText}>
                    {translations.TRANSLATIONS_type_custom_text}
                </option>
            </select>
            <div className="help-text">{getHelpText()}</div>
            {placeholderType === TargetPlaceholderType.CustomText ? (
                <input
                    className="placeholder-text margin-top padding-left"
                    value={placeholderText}
                    onChange={(e) => setPlaceholderText(e.target.value)}
                />
            ) : null}
        </div>
    );

    const renderFooter = () => (
        <FooterButtons
            confirm={confirmImport}
            confirmButtonText={translations.COMMON_confirm}
            cancel={cancelImport}
            cancelButtonText={translations.COMMON_cancel}
        />
    );

    return (
        <>
            <h4>{translations.TRANSLATIONS_import_header}</h4>
            <FileDrop
                onChange={onDrop}
                placeholder={translations.TRANSLATIONS_import_dropzone_placeholder}
                fileTypes=".xlf"
            />
            <GenericModal
                title={translations.IMPORT_confirm_title}
                show={showConfirmation}
                onHide={cancelImport}
                container={container}
                renderBody={renderBody}
                renderFooter={renderFooter}
            />
        </>
    );
};

export default TranslationImport;
