import { CaretLeft, CaretRight, DotsThree } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import translations from '../../translations';
import { stringReplace } from '../../utils/string';

export interface PaginationData {
    page: number;
    total: number;
    pageSize: number;
    changePage: (page: number) => void;
}

export interface Props<TItem> {
    pagination?: PaginationData | boolean;
    items: TItem[];
    itemsToDisplay: TItem[];
    setItemsToDisplay: (items: TItem[]) => void;
    resetComparator?: ((currentItems: TItem[], previousItems: TItem[]) => boolean) | undefined;
}

const Pagination = <TItem,>({
    pagination,
    items,
    itemsToDisplay,
    setItemsToDisplay,
    resetComparator,
}: Props<TItem>) => {
    const [page, setPage] = useState(1);
    const itemsPerPage = 20;

    const isClientPagination = !!pagination && typeof pagination === 'boolean';
    const isServerPagination = !!pagination && typeof pagination === 'object';

    const [previousItems, setPreviousItems] = useState(items);

    useEffect(() => {
        // Not applicable if pagination is handled by parent component
        if (!isClientPagination) {
            return;
        }

        const defaultResetComparator = (currentItems: TItem[], previousItems: TItem[]) => {
            // Compare number of items
            if (currentItems.length !== previousItems.length) {
                return true;
            }

            // Compare order of items
            for (let i = 0; i < currentItems.length; ++i) {
                if (JSON.stringify(currentItems[i]) !== JSON.stringify(previousItems[i])) {
                    return true;
                }
            }

            return false;
        };

        const shouldReset =
            resetComparator?.(items, previousItems) ?? defaultResetComparator(items, previousItems);
        if (page > 1 && shouldReset) {
            setPage(1);
        }

        setPreviousItems(items);
    }, [isClientPagination, items, page, previousItems, resetComparator]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const paginateItemsLocally = () => {
            const startIndex = (page - 1) * itemsPerPage;
            let endIndex = startIndex + itemsPerPage;

            if (endIndex > items.length) {
                endIndex = items.length;
            }

            setItemsToDisplay(items.slice(startIndex, endIndex));
        };

        // All items are provided so do the pagination locally.
        if (isClientPagination) {
            paginateItemsLocally();
        }
        // Pagination, if requested, is done in the parent component and handled from outside the table component.
        else {
            setItemsToDisplay(items);
        }
    }, [items, pagination, page, itemsPerPage, isClientPagination, setItemsToDisplay]);

    const displayPagination = () => {
        // Server and local pagination source different numbers so check for each.
        if (
            (isClientPagination && items.length > itemsPerPage) ||
            (isServerPagination && pagination.total > pagination.pageSize)
        ) {
            return true;
        }

        return false;
    };

    const nextOrPrevPage = (
        pageDelta: number,
        currentPage: number,
        onChangePage: (page: number) => void,
    ) => {
        if (pageDelta > 0) {
            onChangePage(currentPage + 1);
        } else if (pageDelta < 0) {
            onChangePage(currentPage - 1);
        }
    };

    const renderOfItemsDisplay = (currentPage: number, pageSize: number, itemsOnPage: number) => {
        const secondNumber =
            pageSize > itemsOnPage
                ? pageSize * (currentPage - 1) + itemsOnPage
                : pageSize * currentPage;

        const itemRangeStr = stringReplace(translations.COMMON_TABLE_items_range, {
            firstNumber: ((currentPage - 1) * pageSize + 1).toString(),
            secondNumber: secondNumber.toString(),
        });

        return (
            <div className="pagination-items-display" data-testid="pagination-of-items">
                <strong>{itemRangeStr}</strong> of{' '}
                <strong>{isServerPagination ? pagination.total : items.length}</strong> items
            </div>
        );
    };

    const renderNumberBtns = (
        maxItems: number,
        pageSize: number,
        currentPage: number,
        onChangePage: (page: number) => void,
    ) => {
        const buttonsNeeded = Math.ceil(maxItems / pageSize);
        const maxBtns = 4;

        let startBtn: number;
        let endBtn: number;

        // Less pages than the max, just set the start and end to the range (probably most of the cases).
        if (buttonsNeeded - 2 <= maxBtns) {
            startBtn = 2;
            endBtn = buttonsNeeded - 1;
        }
        // Towards the end of the pages so set the last button to the end and use the whole maxBtns range to get the start.
        else if (currentPage + maxBtns / 2 > buttonsNeeded - 1) {
            endBtn = buttonsNeeded - 1; // End is one less than the total as the end button should always appear.
            startBtn = endBtn - (maxBtns - 1); // endBtn includes one of the buttons so remove one from the maxBtns.
        }
        // Somewhere in the middle, have the currentPage in the centre, with half maxBtns on either side.
        else if (currentPage - maxBtns / 2 > 2) {
            startBtn = currentPage - maxBtns / 2;
            endBtn = currentPage + maxBtns / 2;
        }
        // Towards the beginning so have the start as the first button and use the whole maxBtns range to get the end.
        else {
            startBtn = 2; // Start is 2 as the first button should always appear.
            endBtn = startBtn + (maxBtns - 1); // startBtn includes one of the buttons so remove one from the maxBtns.
        }

        const buttons = [];

        // Always display the first page button
        buttons.push(
            <button
                key="page-number-1"
                className={currentPage === 1 ? 'pagination-btn selected' : 'pagination-btn'}
                onClick={() => onChangePage(1)}
                disabled={currentPage === 1}
                type="button"
            >
                1
            </button>,
        );

        if (buttonsNeeded > 1) {
            // Check if an ellipse is needed before the buttons are cut off.
            if (buttonsNeeded - 2 > maxBtns && currentPage - maxBtns / 2 > 2) {
                buttons.push(
                    <button
                        className="pagination-btn-dots"
                        disabled={true}
                        key="page-number-elipsis-before"
                        type="button"
                    >
                        <DotsThree />
                    </button>,
                );
            }

            for (let i = startBtn; i <= endBtn; i++) {
                const classes = currentPage === i ? 'pagination-btn selected' : 'pagination-btn';

                buttons.push(
                    <button
                        key={`page-number-${i}`}
                        className={classes}
                        onClick={() => onChangePage(i)}
                        disabled={currentPage === i}
                        type="button"
                    >
                        {i}
                    </button>,
                );
            }

            // Check if an ellipse is needed after the buttons are cut off.
            if (buttonsNeeded - 2 > maxBtns && currentPage + maxBtns / 2 < buttonsNeeded) {
                buttons.push(
                    <button
                        className="pagination-btn-dots"
                        disabled={true}
                        key="page-number-elipsis-after"
                        type="button"
                    >
                        <DotsThree />
                    </button>,
                );
            }

            // Always display the last page button
            buttons.push(
                <button
                    className={
                        currentPage === buttonsNeeded ? 'pagination-btn selected' : 'pagination-btn'
                    }
                    onClick={() => onChangePage(buttonsNeeded)}
                    disabled={currentPage === buttonsNeeded}
                    key={`page-number-${buttonsNeeded}`}
                    type="button"
                >
                    {buttonsNeeded}
                </button>,
            );
        }

        return <div className="pagination-btns-wrapper">{buttons}</div>;
    };

    const renderLocalPaginationControl = () => {
        return (
            <div className="pagination-control-wrapper">
                <button
                    onClick={() => nextOrPrevPage(-1, page, setPage)}
                    className="btn-no-appearance pagination-icon-btn"
                    disabled={page === 1}
                    title="Previous"
                    aria-label="Previous"
                    type="button"
                >
                    <CaretLeft />
                </button>
                {renderNumberBtns(items.length, itemsPerPage, page, setPage)}
                <button
                    onClick={() => nextOrPrevPage(1, page, setPage)}
                    className="btn-no-appearance pagination-icon-btn"
                    disabled={page >= items.length / itemsPerPage}
                    title="Next"
                    aria-label="Next"
                    type="button"
                >
                    <CaretRight />
                </button>
            </div>
        );
    };

    const renderExternalPaginationControl = () => {
        if (!isServerPagination) {
            return;
        }

        return (
            <div className="pagination-control-wrapper">
                <button
                    onClick={() => nextOrPrevPage(-1, pagination.page, pagination.changePage)}
                    className="btn-no-appearance pagination-icon-btn"
                    disabled={pagination.page - 1 === 0}
                    title="Previous"
                    aria-label="Previous"
                    type="button"
                >
                    <CaretLeft />
                </button>
                {renderNumberBtns(
                    pagination.total,
                    pagination.pageSize,
                    pagination.page,
                    pagination.changePage,
                )}
                <button
                    onClick={() => nextOrPrevPage(1, pagination.page, pagination.changePage)}
                    className="btn-no-appearance pagination-icon-btn"
                    disabled={pagination.page - 1 >= pagination.total / pagination.pageSize - 1}
                    title="Next"
                    aria-label="Next"
                    type="button"
                >
                    <CaretRight />
                </button>
            </div>
        );
    };

    return (
        <div className="pagination-wrapper">
            {displayPagination() ? (
                <>
                    {isServerPagination
                        ? renderOfItemsDisplay(pagination.page, pagination.pageSize, items.length)
                        : renderOfItemsDisplay(page, itemsPerPage, itemsToDisplay.length)}
                    {isServerPagination
                        ? renderExternalPaginationControl()
                        : renderLocalPaginationControl()}
                </>
            ) : null}
        </div>
    );
};

export default Pagination;
