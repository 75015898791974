import { useState, createContext, useContext, useEffect } from 'react';
import {
    deleteCustomPageComponent,
    getCustomPageComponentDependents,
    getCustomPageComponents,
    saveCustomPageComponent,
} from '../../../../ts/sources/customPageComponents';
import { uploadFile, downloadFile } from '../../../../ts/utils/ajax';
import { componentRegistry } from '../../../../ts/components/page-editor/registry';
import { isNullOrEmpty } from '../../../../ts/utils/guard';

const Context = createContext(undefined);

const CustomPageComponentsProvider = ({ notifyError, notifySuccess, tenantId, children }) => {
    const COMPONENT_SCREENS = {
        componentList: 'componentList',
        componentDetail: 'componentDetail',
    };

    const [currentScreen, setCurrentScreen] = useState(COMPONENT_SCREENS.componentList);
    const [components, setComponents] = useState([]);
    const [componentsLoading, setComponentsLoading] = useState(true);
    const initialComponent = {
        attributes: {},
        configurationEditors: [],
        designTimeImageURL: null,
        designTimeRenderType: null,
        developerName: null,
        developerSummary: null,
        elementType: 'CUSTOM_PAGE_COMPONENT',
        icon: null,
        id: null,
        key: null,
        scriptURL: null,
        styleSheetURL: null,
    };
    const [editingComponent, setEditingComponent] = useState(null);

    const editNewComponent = () => setEditingComponent(initialComponent);

    const [paging, updatePaging] = useState({
        page: 1,
        pageSize: 20,
        total: 0,
        search: '',
        orderBy: 'dateModified',
        orderDirection: 'DESC',
    });

    const [conflict, setConflict] = useState(null);
    const [pendingImport, setPendingImport] = useState(null);
    const closeConflictModal = () => setConflict(null);

    const [pendingDeleteComponent, setPendingDeleteComponent] = useState(null);
    const [disconnectedDependentsList, setDisconnectedDependentsList] = useState(null);
    const [connectedDependentsList, setConnectedDependentsList] = useState(null);
    const [standardComponentOverridden, setStandardComponentOverridden] = useState(null);

    const clearDependentModalData = () => {
        setStandardComponentOverridden(null);
        setDisconnectedDependentsList(null);
        setConnectedDependentsList(null);
    };

    const fetchPageComponents = async () => {
        setComponentsLoading(true);

        try {
            const componentsResults = await getCustomPageComponents(paging);

            setComponents(componentsResults.items);

            updatePaging({
                ...paging,
                ...componentsResults._meta,
            });
        } catch (error) {
            notifyError(error);
        } finally {
            setComponentsLoading(false);
        }
    };

    const exportComponent = (item) =>
        downloadFile(
            `/api/draw/1/element/customPageComponent/${item.id}`,
            `${item.developerName}.component`,
            tenantId,
        ).catch((error) => notifyError(error));

    const importComponent = (files, overwrite) =>
        uploadFile({
            file: files[0],
            tenantId,
            url: `/api/draw/1/element/customPageComponent?overwriteExisting=${Boolean(overwrite)}`,
            completeHandler: (event) => {
                if (event.currentTarget.status >= 200 && event.currentTarget.status < 400) {
                    notifySuccess('Component successfully imported');
                    fetchPageComponents();
                    setPendingImport(null);
                    return;
                }
                if (event.currentTarget.status === 409) {
                    setConflict(JSON.parse(event.currentTarget.response));
                    setPendingImport(files);
                    return;
                }
                notifyError(JSON.parse(event.currentTarget.responseText));
            },
            sendAsJSON: true,
            // If the status is 409, do not throw an error
            errorHandler: (_error, status) => status === 409 && notifyError,
        });

    const tryImportComponent = (files) => importComponent(files, false);

    const confirmImportOverwrite = () => {
        setConflict(null);
        importComponent(pendingImport, true);
    };

    const tryDeleteComponent = async (component) =>
        await getCustomPageComponentDependents({ tenantId, key: component.key })
            .then((dependents) => {
                setDisconnectedDependentsList(dependents);
                setPendingDeleteComponent(component);
                return null;
            })
            .catch((error) => notifyError(error));

    const deleteComponent = async (id) =>
        await deleteCustomPageComponent({ tenantId, id })
            .then(() => {
                notifySuccess('Component successfully deleted');
                setDisconnectedDependentsList(null);
                fetchPageComponents();
                return null;
            })
            .catch((error) => notifyError(error));

    const saveEditingComponent = async (override = false) => {
        const previousComponent = components.find(
            (component) => component.id === editingComponent.id,
        );
        // Only run these checks if it's a new component (no previousComponent)
        // or if they've changed the key
        if (
            !override &&
            (isNullOrEmpty(previousComponent) || editingComponent.key !== previousComponent.key)
        ) {
            let impact = false;
            // Disconnection impact

            // A key change could disconnect the component from the pages it's used in
            // and cause them to show 'unknown component' errors at runtime
            // Or could return an overridden component to our default one
            // e.g. 'input' could have been overridden, and with this key rename from 'input' to 'other' it no longer will
            if (previousComponent) {
                const disconnectedDependents = await getCustomPageComponentDependents({
                    tenantId,
                    key: previousComponent.key,
                }).catch((error) => notifyError(error));

                if (disconnectedDependents.length > 0) {
                    setDisconnectedDependentsList(disconnectedDependents);
                    impact = true;
                }
            }

            // Connection impact

            // A key change could connect the component from the pages it's not currently used in
            // and cause them to use this custom component now at runtime
            const connectedDependents = await getCustomPageComponentDependents({
                tenantId,
                key: editingComponent.key,
            }).catch((error) => notifyError(error));

            if (connectedDependents.length > 0) {
                setConnectedDependentsList(connectedDependents);
                impact = true;
            }

            // Standard component impact

            // A key change could override a standard component,
            // which is a niche use case,
            // so we warn the user to make sure they understand they will no longer be able to use the standard component
            const standardComponentOverridden =
                componentRegistry[editingComponent.key.toUpperCase()];

            if (standardComponentOverridden) {
                setStandardComponentOverridden(standardComponentOverridden.ui.caption);
                impact = true;
            }

            if (impact) {
                return;
            }
        }

        clearDependentModalData();

        // Otherwise save the component
        await saveCustomPageComponent({
            tenantId,
            request: { ...editingComponent, key: editingComponent.key?.toLowerCase() },
        })
            .then(() => {
                fetchPageComponents();
                setCurrentScreen(COMPONENT_SCREENS.componentList);
                return null;
            })
            .catch((error) => notifyError(error));
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchPageComponents();
    }, [paging.pageSize, paging.page, paging.search, paging.orderBy, paging.orderDirection]);

    const contextValue = {
        COMPONENT_SCREENS,
        currentScreen,
        setCurrentScreen,
        components,
        editingComponent,
        setEditingComponent,
        exportComponent,
        tryImportComponent,
        fetchPageComponents,
        deleteComponent,
        saveEditingComponent,
        editNewComponent,
        paging,
        updatePaging,
        conflict,
        pendingImport,
        closeConflictModal,
        confirmImportOverwrite,
        tryDeleteComponent,
        closeDependentModal: clearDependentModalData,
        pendingDeleteComponent,
        disconnectedDependentsList,
        connectedDependentsList,
        standardComponentOverridden,
        componentsLoading,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useComponents = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useComponents must be used within a CustomPageComponentsProvider');
    }
    return context;
};

export { CustomPageComponentsProvider, useComponents };
