import { lensPath, set } from 'ramda';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { addNotification } from '../../../../js/actions/reduxActions/notification.js';
import '../../../../js/typedefs/service';
import '../../../../js/typedefs/value';
import Breadcrumb from '../../../components/Breadcrumb';
import Table from '../../../components/generic/Table';
import Loader from '../../../components/loader/Loader';
import { CONTENT_TYPES_LIST } from '../../../constants';
import { getValueList } from '../../../sources/value';
import translations from '../../../translations';
import type {
    AddNotification,
    ContentType,
    ServiceElementResponseAPI,
    ServiceValueRequestAPI,
    ValueElementIdAPI,
    ValueElementIdReferenceAPI,
} from '../../../types';
import { getFlag } from '../../../utils/flags';
import { isNullOrEmpty } from '../../../utils/guard';
import ValueSelectorModal from '../../values/selector/ValueSelectorModal';
import { SERVICE_URLS, SUPPORTED_SERVICES, type ServiceViews } from '../ServiceConstants';
import { type NewServiceData, useServices } from '../contextProviders/ServicesProvider';
import { tryGetErrorMessage } from '../../../utils';
import type { ConfigurationAndSelection } from '../../../types';

export interface ConfigValue {
    contentType: string;
    default: string | number | Date | boolean | null;
    description: string;
    name: string;
    required: boolean;
    typeElementId: string;
    value: ValueElementIdReferenceAPI | ValueElementIdAPI | null;
}

/**
 * @param configurationValues The configuration values from the service (no selections)
 * @param doesServiceExist Indicator whether this is a service being first installed or not
 * @param currentServiceData The data about a service being edited
 * @param newServiceData The data about a newly installed service
 * @param setSelections Function to use to set the user's selections
 * @param setLoading Function to use to set the Loading state of the data
 * @param setError Function to use to save full error details
 * @param throwError Adds a notification, used specifically for errors
 * @description Fetch the latest Values in the given tenant and match the values
 * against the service configuration values. Matching the Value information from the Tenant and
 * the hard-coded service configuration information about the SUPPORTED_SERVICES
 * against the small information the service gives back to us (from "configuration"),
 * we build up a full description of each service configuration values
 * and save the full description using setSelections.
 */
export const refreshServiceConfig = async (
    configurationValues: ServiceValueRequestAPI[] | null | undefined,
    doesServiceExist: boolean,
    currentServiceData: ServiceElementResponseAPI | null | undefined,
    newServiceData: NewServiceData | null | undefined,
    setSelections: (values: ConfigValue[]) => void,
    setLoading: (value: boolean) => void,
    setError: (error: string | null) => void,
    throwError: AddNotification,
    selectedValues?: ConfigurationAndSelection[] | null,
) => {
    setLoading(true);

    try {
        const allTenantValues = await getValueList();

        const configValues = configurationValues?.map((serviceValueRequest) => {
            const name = serviceValueRequest.developerName;

            const url = doesServiceExist ? currentServiceData?.uri : newServiceData?.url;
            const supportedService = SUPPORTED_SERVICES.find((service) => service.url === url);
            const supportedServiceConfigValue = supportedService
                ? supportedService.configuration
                    ? supportedService.configuration.find((cf) => cf.name === name)
                    : null
                : null;

            const contentType = serviceValueRequest.contentType;
            const typeElementId = serviceValueRequest.typeElementId;

            // If the service already exists
            const serviceConfigValue =
                doesServiceExist && currentServiceData?.configurationValues
                    ? currentServiceData.configurationValues.find(
                          // Find the configuration value that the user chose previously (if there is one)
                          (configValue) => configValue.developerName === name,
                      )
                    : null;

            // Find the value from the Tenant to get the information about it
            const tenantValue = serviceConfigValue
                ? allTenantValues.find(
                      (value) =>
                          value.id === serviceConfigValue?.valueElementToReferenceId?.id &&
                          value.typeElementPropertyId ===
                              serviceConfigValue.valueElementToReferenceId.typeElementPropertyId,
                  )
                : serviceValueRequest?.valueElementToReferenceId?.id
                  ? allTenantValues.find(
                        (value) =>
                            value.id === serviceValueRequest?.valueElementToReferenceId?.id &&
                            value.typeElementPropertyId ===
                                serviceValueRequest.valueElementToReferenceId.typeElementPropertyId,
                    )
                  : null;

            /**
             * Returns the Configuration setting information, and the selected value (if any)
             * stores the Configuration setting information, and the selected value (if any)
             */
            return {
                name,
                contentType,
                typeElementId,
                value: tenantValue ?? null,
                ...supportedServiceConfigValue,
            };
        });
        setError(null);
        setSelections((selectedValues as ConfigValue[]) ?? (configValues as ConfigValue[]));
        setLoading(false);
    } catch (error) {
        const message = tryGetErrorMessage(error);
        setLoading(false);
        setError(message);
        throwError({
            type: 'error',
            message,
            isPersistent: true,
        });
    }
};

interface ServiceConfigurationProps {
    configurationValues: ServiceValueRequestAPI[] | null | undefined;
    newServiceData: NewServiceData | null | undefined;
    currentServiceData: ServiceElementResponseAPI | null | undefined;
    switchView: (view: ServiceViews) => void;
    installService: (configValueIds: ConfigurationAndSelection[]) => void;
    throwError: AddNotification;
}

/**
 * @param configurationValues The configuration values from the service (no selections)
 * @param newServiceData The data about a newly installed service
 * @param currentServiceData The data about a service being edited
 * @param switchView Command to switch view of ServiceController
 * @param switchToPreview Command to switch view of ServiceController specifically to Preview
 * @param installService Command to install the service ServiceController
 * @param throwError Adds a notification, used specifically for errors
 * @description Render all of the configuration values for this service
 * and let the user select values for them using the value selector
 */
const ServiceConfiguration = ({
    configurationValues,
    newServiceData,
    currentServiceData,
    switchView,
    installService,
    throwError,
}: ServiceConfigurationProps) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoadingTenantValues, setIsLoadingTenantValues] = useState<boolean>(true);

    const { breadCrumbs, fetchServiceTypesAndActions, servicesState, proceedToConfigureOpenApi } =
        useServices();
    const { isLoading } = servicesState;

    const [selectedValues, setSelectedValues] = useState<ConfigValue[]>([]);

    const doesServiceAlreadyExist = isNullOrEmpty(currentServiceData) === false;

    const isOpenApi =
        (currentServiceData?.uri === SERVICE_URLS.openapi ||
            newServiceData?.url === SERVICE_URLS.openapi) &&
        getFlag('OAPI2');

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        refreshServiceConfig(
            configurationValues,
            doesServiceAlreadyExist,
            currentServiceData,
            newServiceData,
            setSelectedValues,
            setIsLoadingTenantValues,
            setErrorMessage,
            throwError,
            servicesState.selectedValues,
        );
    }, []);

    const selectValue = (value: ValueElementIdAPI | null, id: number) => {
        setSelectedValues(set(lensPath([id, 'value']), value, selectedValues));
    };

    const serviceName = doesServiceAlreadyExist
        ? currentServiceData?.developerName
        : newServiceData?.name;

    const startingValueName = (itemName: string) => {
        const serviceName = doesServiceAlreadyExist
            ? currentServiceData?.developerName
            : newServiceData?.name;

        return `${serviceName} - ${itemName}`;
    };

    const inputs = isNullOrEmpty(errorMessage) ? (
        <Table<ConfigValue>
            wrapperClassName="margin-top"
            columns={[
                {
                    renderHeader: () => translations.COMMON_TABLE_name,
                    renderCell: ({ item }) => item.name,
                },
                {
                    renderHeader: () => translations.COMMON_TABLE_content_type,
                    renderCell: ({ item }) => {
                        const cleanContentType = CONTENT_TYPES_LIST.find(
                            (primitiveType) => primitiveType.key === item.contentType.toUpperCase(),
                        );
                        const shownContentType = cleanContentType
                            ? cleanContentType.label
                            : item.contentType;
                        return item.typeElementId
                            ? `${shownContentType} of ${item.typeElementId}`
                            : shownContentType;
                    },
                    size: '10rem',
                },
                {
                    renderHeader: () => translations.COMMON_TABLE_required,
                    renderCell: ({ item }) => (
                        <span
                            className={`glyphicon glyphicon-${item.required ? 'ok' : 'remove'}`}
                        />
                    ),
                    size: '5rem',
                },
                {
                    renderHeader: () => translations.COMMON_TABLE_default,
                    renderCell: ({ item }) => item.default?.toString() ?? 'No Default',
                    size: '15rem',
                },
                {
                    renderHeader: () => translations.COMMON_TABLE_value,
                    renderCell: ({ item, rowIndex }) => (
                        <ValueSelectorModal
                            value={item.value}
                            contentType={item.contentType as ContentType}
                            typeElementId={item.typeElementId}
                            startingValueName={startingValueName(item.name)}
                            onChange={(value) => selectValue(value, rowIndex)}
                            container={null}
                            includeSystemValues={true}
                            key={rowIndex}
                        />
                    ),
                },
            ]}
            items={selectedValues}
            isLoading={isLoadingTenantValues}
        />
    ) : (
        <div className="alert alert-danger">
            <span>{`${translations.SC_config_refresh_failed} "${errorMessage}".`}</span>
            <button
                type="button"
                className="btn btn-danger margin-left"
                onClick={() =>
                    refreshServiceConfig(
                        configurationValues,
                        doesServiceAlreadyExist,
                        currentServiceData,
                        newServiceData,
                        setSelectedValues,
                        setIsLoadingTenantValues,
                        setErrorMessage,
                        throwError,
                    )
                }
                disabled={isLoadingTenantValues}
            >
                {'Retry'}
            </button>
        </div>
    );

    function requiresConfigurationValues() {
        const url = doesServiceAlreadyExist ? currentServiceData?.uri : newServiceData?.url;

        const isPdfConnector = url?.includes(SERVICE_URLS.nextGenPDF);

        if (isPdfConnector || isNullOrEmpty(configurationValues)) {
            return false;
        }
        return true;
    }

    if (isLoadingTenantValues || isLoading) {
        return <Loader />;
    }

    return (
        <div className="full-height flex-column">
            <div className="admin-page">
                <div className="margin-bottom" data-testid="connector-breadcrumbs">
                    <Breadcrumb trail={breadCrumbs.trail} activeItemId={breadCrumbs.activeItemId} />
                </div>
                <h1>{`Connector: ${serviceName}`}</h1>
                {requiresConfigurationValues()
                    ? inputs
                    : translations.SC_configuration_values_not_required_message}
                {isOpenApi && doesServiceAlreadyExist && (
                    <div className="alert alert-warning">
                        {translations.OPENAPI_configuration_values_warning_message}
                    </div>
                )}
                {isOpenApi && (
                    <button
                        type="button"
                        className="btn btn-success outcome"
                        onClick={() => {
                            proceedToConfigureOpenApi(
                                selectedValues as ConfigurationAndSelection[],
                            );
                        }}
                    >
                        {'Configure OpenAPI'}
                    </button>
                )}
            </div>
            <div className="admin-footer">
                <button
                    type="button"
                    className="btn btn-default outcome"
                    onClick={() => switchView(breadCrumbs.trail[breadCrumbs.trail.length - 2].id)}
                >
                    {'Back'}
                </button>
                <button
                    type="button"
                    className="btn btn-primary outcome"
                    onClick={() => {
                        installService(selectedValues as ConfigurationAndSelection[]);
                    }}
                >
                    {'Install'}
                </button>
                <button
                    type="button"
                    className="btn btn-success outcome"
                    onClick={async () => {
                        await fetchServiceTypesAndActions(selectedValues);
                    }}
                >
                    {'Preview Actions & Types'}
                </button>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    throwError: addNotification,
};

export default connect(null, mapDispatchToProps)(ServiceConfiguration);
