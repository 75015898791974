import { useNavigate, type NavigateFunction } from 'react-router-dom';
import { clone } from 'ramda';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../../../css/flow/properties.less';
import {
    changeFlowName,
    registerFlowConfiguration,
    unregisterFlowConfiguration,
} from '../../../js/actions/reduxActions/flowConfigurations';
import { addNotification as addNotificationAction } from '../../../js/actions/reduxActions/notification';
import { closeTab, updateTab } from '../../../js/actions/reduxActions/tabs';
import FormGroup from '../generic/FormGroup';
import { TAB_TYPES } from '../../constants';
import { setLatest } from '../../sources/flow';
import translations from '../../translations';
import { generateRouteUrl } from '../../utils/routing';
import { isNullOrEmpty } from '../../utils/guard';
import ButtonDefault from '../buttons/ButtonDefault';
import ButtonPrimary from '../buttons/ButtonPrimary';
import Modal from '../generic/modal/GenericModal';
import { fetchAllFlows } from '../../../js/actions/reduxActions/flows';
import type { AddNotification, FlowRequestAPI, FlowResponseAPI, Tab } from '../../types';

interface Props {
    tenantId: string;
    flowId: string;
    flowData: FlowResponseAPI | undefined;
    addNotification: AddNotification;
    registerFlowConfiguration: (flow: FlowRequestAPI) => void;
    unregisterFlowConfiguration: (id: string) => void;
    changeFlowName: ({ flowId, flowName }: { flowId: string; flowName: string }) => void;
    container: HTMLElement;
    closeTab: (key: string, tenantId: string, navigate: NavigateFunction) => void;
    tabs: Tab[];
    updateTab: (tab: Tab) => void;
    fetchFlows: (tenantId: string) => void;
}

const NewFlow = ({
    tenantId,
    flowId,
    flowData,
    addNotification,
    registerFlowConfiguration,
    unregisterFlowConfiguration,
    changeFlowName,
    container,
    closeTab,
    tabs,
    updateTab,
    fetchFlows,
}: Props) => {
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const navigate = useNavigate();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        registerFlowConfiguration({
            id: {
                id: flowId,
                versionId: '',
            },
            developerName: '',
            developerSummary: '',
            startMapElementId: null,
            allowJumping: false,
            enableHistoricalNavigation: false,
            stateExpirationLength: 0,
            idleStateExpirationLength: 0,
            authorization: {
                serviceElementId: null,
                globalAuthenticationType: 'PUBLIC',
                streamBehaviourType: 'NONE',
                showPagesAsReadOnly: false,
                groups: [],
                users: [],
                locations: [],
            },
        });
    }, []);

    const onSave = async () => {
        setHasSubmitted(true);

        if (isFormValid && flowData) {
            const newFlowData: FlowRequestAPI = clone(flowData);

            // Set flow ID back to null so it is recognised as new by the API
            newFlowData.id = null;

            try {
                const response = await setLatest(newFlowData);

                createFlowDone(response);
            } catch (error) {
                // Catch error and notify
                addNotification({
                    type: 'error',
                    message: (error as Error).message,
                    isPersistent: true,
                });
            }
        }
    };

    const createFlowDone = async (response: FlowResponseAPI) => {
        const tab = tabs.find((tab) => tab.elementId === flowId);
        if (tab) {
            updateTab({
                ...tab,
                title: response.developerName,
            });
        }
        const route = generateRouteUrl({
            tabType: TAB_TYPES.flow,
            tenantId: tenantId,
            options: {
                elementId: response.id.id,
                tabKey: tab?.key,
                isNewFlowRedirect: true,
            },
        });

        if (flowData) {
            unregisterFlowConfiguration(flowData.id.id);
        }
        fetchFlows(tenantId);
        navigate(route);
    };

    const onCancel = () => {
        const tab = tabs.find((tab) => tab.elementId === flowId);
        if (flowData) {
            unregisterFlowConfiguration(flowData.id.id);
        }
        if (tab) {
            closeTab(tab.key, tenantId, navigate);
        }
    };

    const isFormValid = isNullOrEmpty(flowData) ? false : flowData.developerName !== '';

    const renderModalContent = () => {
        return (
            <>
                <FormGroup
                    label={'Name'}
                    isRequired
                    isValid={isFormValid}
                    showValidation={hasSubmitted}
                    validationMessage={'This field is required.'}
                    htmlFor="new-flow-name-field"
                >
                    <input
                        id="new-flow-name-field"
                        maxLength={150}
                        className="form-control form-control-dynamic name"
                        value={flowData?.developerName ?? ''}
                        onChange={({ target }) => {
                            if (flowData) {
                                changeFlowName({
                                    flowId: flowData.id.id,
                                    flowName: target.value,
                                });
                            }
                        }}
                        required
                        autoFocus
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                onSave();
                            }
                        }}
                    />
                </FormGroup>
            </>
        );
    };

    const renderFooter = () => {
        return (
            <>
                <ButtonDefault className="new-flow-cancel" onClick={onCancel}>
                    {translations.GRAPH_config_panel_cancel}
                </ButtonDefault>
                <ButtonPrimary className="new-flow-save" onClick={onSave}>
                    {translations.GRAPH_config_panel_save}
                </ButtonPrimary>
            </>
        );
    };

    return (
        <Modal
            className="config-modal"
            title={translations.FLOW_CREATE_NEW}
            renderBody={renderModalContent}
            renderFooter={renderFooter}
            container={container}
            bodyClassName="auto-height"
            onHide={onCancel}
        />
    );
};
// Take in Redux state and save as local props
const mapStateToProps = (
    { flowConfigurations, tabs }: { flowConfigurations: FlowResponseAPI[]; tabs: Tab[] },
    ownProps: { flowId: string },
) => ({
    flowData: flowConfigurations.find((flow) => flow.id.id === ownProps.flowId),
    tabs: tabs,
});

const mapDispatchToProps = {
    registerFlowConfiguration,
    unregisterFlowConfiguration,
    changeFlowName,
    addNotification: addNotificationAction,
    closeTab,
    updateTab,
    fetchFlows: fetchAllFlows,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewFlow);
