import { useState } from 'react';
import { connect } from 'react-redux';
import '../../../../css/tenant-name-input.less';
import { useAuth } from '../../../ts/components/AuthProvider';
import Loader from '../../../ts/components/loader/Loader';
import translations from '../../../ts/translations';
import { addNotification } from '../../actions/reduxActions/notification';
import { fetchTenants } from '../../actions/reduxActions/organizations';
import { SYSTEM_ROLES, REGEX_EMAIL, NOTIFICATION_TYPES } from '../../../ts/constants';
import { provisionTenantAndOrUser } from '../../../ts/sources/organization';
import FormGroup from '../../../ts/components/generic/FormGroup';
import TenantNameInput from '../../../ts/components/tenant/TenantNameInput';
import { getAllUsersForCurrentTenant } from '../../../ts/sources/user';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { getSharedStyles } from '../../../ts/utils/select';
import { isNullOrEmpty } from '../../../ts/utils/guard';

/**
 * @description Flow Org. provision a new tenant or a new user
 *
 * Display the form and button used to provision a new tenant or a new user.
 */
const Provision = ({ closeModal, addNotification }) => {
    const [tenantName, setTenantName] = useState('');
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [loading, setLoading] = useState(false);

    const { fetchUser } = useAuth();

    const clearProvisionData = () => {
        setTenantName('');
        setSelectedEmails([]);
    };

    const loadUsersForCurrentTenant = async (searchString) => {
        try {
            const response = await getAllUsersForCurrentTenant();

            const newUserOptions = response.map(({ email }) => ({
                label: email,
                value: email,
            }));

            if (newUserOptions.length === 0) {
                return [];
            }

            const formattedSearchString = searchString.trim().toLowerCase();

            const filteredOptions = formattedSearchString
                ? newUserOptions.filter(({ label }) =>
                      label.toLowerCase().includes(formattedSearchString),
                  )
                : newUserOptions;

            return filteredOptions;
        } catch ({ message }) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message,
                isPersistent: true,
            });

            return [];
        }
    };

    const addTenantAndOrUser = async ({ provisionData }) => {
        try {
            setLoading(true);

            await provisionTenantAndOrUser({
                provisionData,
            });

            fetchUser();
            fetchTenants();

            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: translations.FORG_tenant_user_provisioned,
                isPersistent: false,
            });

            return true;
        } catch ({ message }) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message,
                isPersistent: true,
            });

            return false;
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const success = await addTenantAndOrUser({
            provisionData: {
                developerName: tenantName,
                users: selectedEmails.map((email) => ({
                    email: email.value,
                    role: SYSTEM_ROLES.administrator.developerName,
                })),
            },
        });

        if (!success) {
            return;
        }

        clearProvisionData();
        closeModal();
    };

    return loading ? (
        <Loader />
    ) : (
        <>
            <p>{translations.FORG_provision_description}</p>
            <form onSubmit={handleSubmit}>
                <FormGroup
                    label={translations.FORG_provision_tenant_user_select_label}
                    labelId="user-select-label"
                    isRequired={true}
                >
                    <AsyncCreatableSelect
                        isMulti
                        className="full-width"
                        styles={getSharedStyles()}
                        isValidNewOption={(inputValue) => REGEX_EMAIL.test(inputValue.trim())}
                        loadOptions={loadUsersForCurrentTenant}
                        defaultOptions
                        cacheOptions
                        onChange={setSelectedEmails}
                        value={selectedEmails}
                        formatCreateLabel={(input) =>
                            `${translations.FORG_provision_add_user_text} ${input}`
                        }
                        noOptionsMessage={(input) =>
                            input.inputValue.length > 0
                                ? `${input.inputValue} ${translations.FORG_provision_invalid_email_text}`
                                : translations.FORG_provision_no_results_text
                        }
                        aria-labelledby="user-select-label"
                    />
                </FormGroup>
                <span className="help-block">
                    {translations.FORG_provision_tenant_user_select_help_text}
                </span>
                <TenantNameInput
                    value={tenantName}
                    inputId="tenant-name-input"
                    labelText={translations.FORG_provision_tenant_name_input_label}
                    onChange={({ value }) => setTenantName(value)}
                />
                <footer className="form-footer">
                    <button type="button" className="btn btn-sm btn-default" onClick={closeModal}>
                        {translations.FORG_provision_tenant_cancel_button_label}
                    </button>
                    <button
                        type="submit"
                        className="btn btn-sm btn-success"
                        disabled={selectedEmails.length === 0 || isNullOrEmpty(tenantName)}
                    >
                        {translations.FORG_provision_tenant_submit_button_label}
                    </button>
                </footer>
            </form>
        </>
    );
};

const mapDispatchToProps = {
    addNotification,
    fetchTenants,
};

export default connect(null, mapDispatchToProps)(Provision);
