import { useState, createContext, useContext } from 'react';
import {
    getAllEnvironments as loadEnvironments,
    saveEnvironment as save,
    deleteEnvironment as deleteEnv,
    getEnvironmentVariables as loadEnvironmentVariables,
    saveEnvironmentVariable as saveEnvironmentVar,
    deleteEnvironmentVariable as removeEnvironmentVariable,
    getEnvironmentFlows as loadEnvironmentFlows,
} from '../../../../ts/sources/environments';
import {
    getRelease as loadRelease,
    getReleases as loadReleases,
    removeFlowFromRelease as removeFlow,
    removeThemeFromRelease as removeTheme,
    deploy as deployApiCall,
    rollback as rollbackApiCall,
    deleteRelease as deleteRel,
} from '../../../../ts/sources/releases';
import { NOTIFICATION_TYPES } from '../../../../ts/constants';
import translations from '../../../../ts/translations';

const Context = createContext(undefined);

const EnvironmentsProvider = ({ addNotification, tenantId, children }) => {
    const SCREENS = {
        overview: 'environments',
    };

    const [environmentsLoading, setEnvironmentsLoading] = useState(false);
    const [environments, setEnvironments] = useState([]);
    const [releasesLoading, setReleasesLoading] = useState(false);
    const [releases, setReleases] = useState([]);
    const [releaseLoading, setReleaseLoading] = useState(false);
    const [currentScreen] = useState(SCREENS.overview);
    const [environmentVariablesLoading, setEnvironmentVariablesLoading] = useState(false);
    const [environmentVariables, setEnvironmentVariables] = useState([]);
    const [managingEnvironments, setIsManagingEnvironments] = useState(false);
    const [environmentFlowsLoading, setEnvironmentFlowsLoading] = useState(false);

    const getAllEnvironments = async () => {
        setEnvironmentsLoading(true);

        try {
            const results = await loadEnvironments();
            setEnvironments(results);
            setEnvironmentsLoading(false);
            return results;
        } catch (error) {
            setEnvironmentsLoading(false);
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const saveEnvironment = async (environment) => {
        try {
            await save(environment);
            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: 'Environment successfully saved',
            });
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const deleteEnvironment = async (environmentId) => {
        try {
            await deleteEnv(environmentId);
            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: 'Environment successfully deleted',
            });
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const getEnvironmentFlows = async (params) => {
        try {
            setEnvironmentFlowsLoading(true);
            const response = await loadEnvironmentFlows(params);
            setEnvironmentFlowsLoading(false);
            return response;
        } catch (error) {
            setEnvironmentVariablesLoading(false);
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const getEnvironmentVariables = async (environmentId, filter) => {
        setEnvironmentVariablesLoading(true);

        try {
            const response = await loadEnvironmentVariables(environmentId, filter);
            setEnvironmentVariables(response.items);
            setEnvironmentVariablesLoading(false);
            return response;
        } catch (error) {
            setEnvironmentVariablesLoading(false);
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const saveEnvironmentVariable = async (environmentId, environmentVariable) => {
        try {
            await saveEnvironmentVar(environmentId, environmentVariable);
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const deleteEnvironmentVariable = async (environmentId, environmentVariableId) => {
        try {
            await removeEnvironmentVariable(environmentId, environmentVariableId);
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const getReleases = async (filter) => {
        setReleasesLoading(true);
        let results = null;

        try {
            results = await loadReleases(filter);
            setReleases(results);
            setReleasesLoading(false);
            return results;
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
            setReleasesLoading(false);
        }
    };

    const getRelease = async (id) => {
        setReleaseLoading(true);
        try {
            const release = await loadRelease(id);
            setReleaseLoading(false);
            return release;
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
            setReleaseLoading(false);
        }
    };

    const deleteRelease = async (releaseId) => {
        try {
            await deleteRel(releaseId);
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const removeFlowFromRelease = async (releaseId, flowId) => {
        try {
            await removeFlow(releaseId, flowId);

            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: translations.ENVIRONMENT_remove_item_success,
            });
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const removeThemeFromRelease = async (releaseId, themeId, themeName) => {
        try {
            await removeTheme(releaseId, themeId, themeName);

            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: translations.ENVIRONMENT_remove_item_success,
            });
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const deploy = async (releaseId, environmentId) => {
        try {
            await deployApiCall(releaseId, environmentId);
            return true;
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const rollback = async (releaseId, environmentId) => {
        try {
            await rollbackApiCall(releaseId, environmentId);
            return true;
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const contextValue = {
        SCREENS,
        currentScreen,
        getAllEnvironments,
        getEnvironmentFlows,
        environmentsLoading,
        environments,
        saveEnvironment,
        deleteEnvironment,
        getEnvironmentVariables,
        saveEnvironmentVariable,
        environmentVariables,
        environmentVariablesLoading,
        deleteEnvironmentVariable,
        releases,
        getReleases,
        releasesLoading,
        getRelease,
        releaseLoading,
        deleteRelease,
        removeFlowFromRelease,
        removeThemeFromRelease,
        deploy,
        rollback,
        tenantId,
        addNotification,
        setIsManagingEnvironments,
        managingEnvironments,
        environmentFlowsLoading,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useEnvironmentsProviders = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useEnvironmentsProviders must be used within a EnvironmentsProvider');
    }
    return context;
};

export { EnvironmentsProvider, useEnvironmentsProviders };
