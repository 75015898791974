import TypesList from './TypesList';
import TypeDetail from './TypeDetail';
import TypePropertyDetail from './TypePropertyDetail';
import { useTypes } from './TypesProvider';
import ImportProfile from '../../../../ts/components/admin/types/ImportProfile';

const Types = () => {
    const { TYPE_SCREENS, currentScreen } = useTypes();

    const getScreen = () => {
        switch (currentScreen) {
            case TYPE_SCREENS.typeList:
                return <TypesList />;
            case TYPE_SCREENS.typeDetail:
                return <TypeDetail />;
            case TYPE_SCREENS.typePropertyDetail:
                return <TypePropertyDetail />;
            case TYPE_SCREENS.importProfile:
                return <ImportProfile />;
            default:
                return <TypesList />;
        }
    };
    return <div className="page-wrapper">{getScreen()}</div>;
};

export default Types;
