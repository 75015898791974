import { useState } from 'react';
import { connect } from 'react-redux';
import { fetchUsers } from '../../actions/reduxActions/organizations';
import { removeUser as requestLeaveOrganization } from '../../../ts/sources/organization';
import ConfirmModal from '../../../ts/components/generic/modal/ConfirmModal';
import translations from '../../../ts/translations';
import { addNotification } from '../../actions/reduxActions/notification';
import { NOTIFICATION_TYPES } from '../../../ts/constants';
import { useAuth } from '../../../ts/components/AuthProvider';
import { resolveOrgUserRole } from '../../../ts/utils/organization';
import { stringReplace } from '../../../ts/utils/string';

const LeaveOrg = ({ addNotification, container }) => {
    const [leaveConfirmationVisible, setLeaveConfirmationVisible] = useState(false);

    const { fetchUser, user, tenant } = useAuth();

    const showLeaveConfirmation = () => setLeaveConfirmationVisible(true);
    const hideLeaveConfirmation = () => setLeaveConfirmationVisible(false);

    const removeUser = async () => {
        try {
            await requestLeaveOrganization({
                userId: user.id,
            });

            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: stringReplace(translations.FORG_left_confirmation, {
                    user: user.email,
                }),
            });

            // reload the current user in case they have been removed from the current organization
            fetchUser();

            if (resolveOrgUserRole(user, tenant).isUserAdmin) {
                fetchUsers();
            }
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
            });
        }
    };

    return (
        <>
            <button className="btn btn-danger" onClick={showLeaveConfirmation} type="button">
                Leave this organization
            </button>
            <ConfirmModal
                show={leaveConfirmationVisible}
                onCancel={hideLeaveConfirmation}
                onConfirm={() => {
                    removeUser();
                    hideLeaveConfirmation();
                }}
                title={translations.FORG_leave_confirmation_title}
                messages={[translations.FORG_leave_confirmation]}
                container={container.current}
            />
        </>
    );
};

const mapDispatchToProps = {
    fetchUsers,
    addNotification,
};

export default connect(null, mapDispatchToProps)(LeaveOrg);
