import { ArrowRight } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ButtonPrimary from '../../../../ts/components/buttons/ButtonPrimary';
import FormGroup from '../../../../ts/components/generic/FormGroup';
import Glyphicon from '../../../../ts/components/generic/Glyphicon';
import Modal from '../../../../ts/components/generic/modal/GenericModal';
import Loader from '../../../../ts/components/loader/Loader';
import { TAB_TYPES } from '../../../../ts/constants';
import translations from '../../../../ts/translations';
import { isNullOrEmpty } from '../../../../ts/utils/guard';
import EnvironmentTile from './EnvironmentTile';
import { useEnvironmentsProviders } from './EnvironmentsProvider';
import ButtonDefault from '../../../../ts/components/buttons/ButtonDefault';

const EnvironmentsList = ({ tabs, modalContainerRef }) => {
    const {
        getAllEnvironments,
        environmentsLoading,
        environments,
        saveEnvironment,
        managingEnvironments,
        setIsManagingEnvironments,
    } = useEnvironmentsProviders();
    const [showAddEnvironmentModal, setShowAddEnvironmentModal] = useState(false);
    const [environmentName, setEnvironmentName] = useState(null);
    const [environmentDescription, setEnvironmentDescription] = useState(null);
    const [nextEnvironmentId, setNextEnvironmentId] = useState(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!isNullOrEmpty(tabs)) {
            const thisTab = tabs.find((t) => t.type === TAB_TYPES.environments);
            if (!isNullOrEmpty(thisTab) && thisTab.isActive) {
                getAllEnvironments();
            }
        }
    }, [tabs]);

    const save = async () => {
        const environment = {
            name: environmentName,
            nextEnvironmentId: nextEnvironmentId,
            description: environmentDescription,
            defaultThemeName: 'default',
            defaultPlayerName: 'default',
        };
        await saveEnvironment(environment);
        await getAllEnvironments();
        setIsManagingEnvironments(false);
    };

    let content;
    if (environmentsLoading) {
        content = <Loader message={translations.ENVIRONMENT_loading} />;
    } else {
        content = (
            <>
                <Modal
                    show={showAddEnvironmentModal}
                    container={modalContainerRef.current}
                    onHide={() => setShowAddEnvironmentModal(false)}
                    title={translations.ENVIRONMENT_add_new}
                    renderBody={() => (
                        <>
                            <FormGroup label="Name" htmlFor="environment-name">
                                <input
                                    id="environment-name"
                                    className="form-control form-control-dynamic"
                                    type="text"
                                    onChange={(e) => setEnvironmentName(e.target.value)}
                                />
                            </FormGroup>

                            <FormGroup label="Description" htmlFor="environment-description">
                                <textarea
                                    id="environment-description"
                                    className="form-control form-control-textarea"
                                    onChange={(e) => setEnvironmentDescription(e.target.value)}
                                    rows={3}
                                />
                            </FormGroup>
                        </>
                    )}
                    className="config-modal"
                    renderFooter={() => (
                        <>
                            <ButtonDefault
                                onClick={() => {
                                    setShowAddEnvironmentModal(false);
                                }}
                            >
                                {translations.ENVIRONMENT_cancel}
                            </ButtonDefault>

                            <ButtonPrimary
                                onClick={() => {
                                    save();
                                    setShowAddEnvironmentModal(false);
                                }}
                            >
                                {translations.ENVIRONMENT_save}
                            </ButtonPrimary>
                        </>
                    )}
                />

                <div className="margin-top wide-tiles-container">
                    {environments.map((environment) => (
                        <React.Fragment key={environment.id}>
                            <EnvironmentTile
                                environment={environment}
                                key={environment.id}
                                modalContainerRef={modalContainerRef}
                            />

                            {environment.nextEnvironmentId === null ? (
                                ''
                            ) : (
                                <>
                                    {managingEnvironments ? (
                                        <ButtonPrimary
                                            className="btn-sm wide-tile-add"
                                            onClick={() => {
                                                setNextEnvironmentId(environment.nextEnvironmentId);
                                                setShowAddEnvironmentModal(true);
                                            }}
                                            testId={`btnAddEnvironment${environment.name}`}
                                        >
                                            <Glyphicon glyph="plus" />
                                        </ButtonPrimary>
                                    ) : (
                                        <div className="wide-tile-arrow">
                                            <ArrowRight weight="fill" size={25} />
                                        </div>
                                    )}
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </>
        );
    }

    return <div>{content}</div>;
};

const mapStateToProps = ({ tabs }) => ({
    tabs,
});

export default connect(mapStateToProps)(EnvironmentsList);
