import { useState } from 'react';
import FormGroup from '../../../../ts/components/generic/FormGroup';
import ButtonDefault from '../../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../../ts/components/buttons/ButtonPrimary';
import Footer from '../../../../ts/components/generic/Footer';
import Breadcrumb from '../../../../ts/components/Breadcrumb';
import TypeElementDropdown from '../../../../ts/components/types/TypeElementDropdown';
import { connect } from 'react-redux';
import { useTypes } from './TypesProvider';
import { VALUE_TYPES } from '../../../../ts/components/values/selector/value-selector-utils';
import { pathOr } from 'ramda';
import { isNullOrEmpty } from '../../../../ts/utils/guard';

const TypePropertyDetail = () => {
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const { typeToEdit, propertyToEdit, returnToType, applyProperty, breadCrumbs } = useTypes();
    const [property, setProperty] = useState(propertyToEdit);

    const isComplexPropertyType =
        property.contentType === 'ContentObject' || property.contentType === 'ContentList';

    const isFormattablePropertyType =
        property.contentType === 'ContentDateTime' ||
        property.contentType === 'ContentDate' ||
        property.contentType === 'ContentNumber';

    const checkRequiredFields = () => {
        let requiredFields = {
            defaultDeveloperName: pathOr('', ['developerName'], property),
            defaultContentType: pathOr(null, ['contentType'], property),
        };

        if (isComplexPropertyType) {
            requiredFields = {
                ...requiredFields,
                defaultPropertyType: pathOr(null, ['typeElementId'], property),
            };
        }

        return requiredFields;
    };

    const onApply = () => {
        setHasSubmitted(true);

        const isFormValid = Object.values(checkRequiredFields()).every(
            (field) => !isNullOrEmpty(field),
        );

        if (isFormValid) {
            applyProperty(property);
        }
    };

    const updatePropertyName = (developerName) => setProperty({ ...property, developerName });

    const updatePropertyContentType = (contentType) => setProperty({ ...property, contentType });

    const updatePropertyType = (typeElementId) => setProperty({ ...property, typeElementId });

    const updatePropertyFormat = (contentFormat) => setProperty({ ...property, contentFormat });

    const { defaultDeveloperName, defaultContentType, defaultPropertyType } = checkRequiredFields();

    return (
        <>
            <div className="admin-page">
                <h1>Property for: {typeToEdit.developerName || ''}</h1>
                <div className="margin-bottom margin-top-large" data-testid="type-breadcrumbs">
                    <Breadcrumb trail={breadCrumbs.trail} activeItemId={breadCrumbs.activeItemId} />
                </div>
                <FormGroup
                    label="Name"
                    htmlFor="type-property-name"
                    isRequired
                    validationMessage="Name field is required"
                    isValid={!isNullOrEmpty(defaultDeveloperName)}
                    showValidation={hasSubmitted}
                >
                    <input
                        id="type-property-name"
                        className="form-control form-control-width"
                        value={defaultDeveloperName ?? ''}
                        onChange={(e) => updatePropertyName(e.target.value)}
                        type="text"
                        autoFocus
                    />
                </FormGroup>
                <FormGroup
                    label="What kind of Property is this?"
                    htmlFor="type-property-type"
                    isRequired
                    validationMessage="Content type field is required"
                    isValid={!isNullOrEmpty(defaultContentType)}
                    showValidation={hasSubmitted}
                >
                    <select
                        id="type-property-type"
                        value={defaultContentType ?? ''}
                        onChange={(e) => updatePropertyContentType(e.target.value)}
                        placeholder="What kind of Property is this?"
                        className="form-control form-control-width"
                        required
                    >
                        <option value="" disabled hidden>
                            What kind of Property is this?
                        </option>
                        {VALUE_TYPES.map((item) => (
                            <option key={item.value} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </FormGroup>
                {isComplexPropertyType ? (
                    <TypeElementDropdown
                        isRequired
                        showValidation={hasSubmitted}
                        isValid={!isNullOrEmpty(defaultPropertyType)}
                        validationMessage="Property type field is required"
                        typeElementId={property.typeElementId}
                        onChange={updatePropertyType}
                        className="form-control-width"
                    />
                ) : null}
                {isFormattablePropertyType ? (
                    <FormGroup
                        label="The format that should be applied to this Property"
                        htmlFor="type-property-format"
                    >
                        <input
                            id="type-property-format"
                            className="form-control form-control-width"
                            value={property.contentFormat ?? ''}
                            onChange={(e) => updatePropertyFormat(e.target.value)}
                            type="text"
                            placeholder="Enter an output format specification"
                        />
                    </FormGroup>
                ) : null}
            </div>

            <Footer>
                <ButtonDefault title="Back" className="flex-child-right" onClick={returnToType}>
                    Back
                </ButtonDefault>
                <ButtonPrimary title="Apply Property" onClick={onApply}>
                    Apply Property
                </ButtonPrimary>
            </Footer>
        </>
    );
};

const mapStateToProps = (state) => ({
    types: state.typeElements.types,
});

export default connect(mapStateToProps)(TypePropertyDetail);
