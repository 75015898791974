import translations from '../../../ts/translations';
import Invitations from './Invitations';

/**
 * @description The UI for not joined tenants
 *
 * Display the UI for not joined tenants.
 */
const NotLinkedTenantUI = () => (
    <div className="flow-organization admin-page">
        <h1>{translations.FORG_not_joined_tenant_title}</h1>
        <Invitations />
    </div>
);

export default NotLinkedTenantUI;
