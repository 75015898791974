import { fetchAndParse, filterToQuery } from '../utils/ajax';
import type { ElementAPI, Filter, ItemCollectionResponse } from '../types';
import { getTenantId } from '../utils/tenant';

export interface CustomPageComponentAPI extends ElementAPI {
    key: string;
    legacyScriptURL: string;
    scriptURL: string;
    legacyStyleSheetURL: string;
    styleSheetURL: string;
    icon: string;
    attributes: { [key: string]: string };
    configurationEditors: string[];
    designTimeImageURL: string;
    designTimeRenderType: string;
    dateCreated: string;
    dateModified: string;
}

export interface CustomPageComponentDependent {
    flowName: string;
    flowId: string | null;
    mapElementName: string;
    mapElementId: string | null;
    pageName: string;
    pageId: string | null;
    pageComponentName: string;
    pageComponentId: string | null;
}

export const getCustomPageComponents = (filter: Filter) => {
    const query = filterToQuery(filter);

    return fetchAndParse<ItemCollectionResponse<CustomPageComponentAPI>>({
        url: `/api/draw/1/element/customPageComponent${query}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const deleteCustomPageComponent = ({ id }: { id: string }) =>
    fetchAndParse<void>({
        url: `/api/draw/1/element/customPageComponent/${id}`,
        method: 'DELETE',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const saveCustomPageComponent = ({ request }: { request: CustomPageComponentAPI }) =>
    fetchAndParse({
        url: '/api/draw/1/element/customPageComponent',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: request,
    });

export const getCustomPageComponentDependents = ({ key }: { key: string }) =>
    fetchAndParse<CustomPageComponentDependent>({
        url: `/api/draw/1/element/customPageComponent/${key}/dependents`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
