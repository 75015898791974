import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchTenants } from '../../actions/reduxActions/organizations';
import translations from '../../../ts/translations';
import ConfirmModal from '../../../ts/components/generic/modal/ConfirmModal';
import TenantName from '../../../ts/components/generic/TenantName';
import SearchInput from '../../../ts/components/generic/SearchInput';
import { removeTenant as removeTenantFromOrg } from '../../../ts/sources/organization';
import { addNotification } from '../../actions/reduxActions/notification';
import { NOTIFICATION_TYPES } from '../../../ts/constants';
import { useAuth } from '../../../ts/components/AuthProvider';
import TenantUsersDetails from './TenantUsersDetails';
import Table from '../../../ts/components/generic/Table';
import { Trash } from '@phosphor-icons/react';
import { stringContains, stringReplace } from '../../../ts/utils/string';
import { isSubtenant } from '../../../ts/utils/tenant';

/**
 * @description Flow Org. joined list
 *
 * Display a list of tenants that have joined the organization.
 */
const JoinedTenantList = ({
    organizationTenants = [],
    fetchTenants,
    addNotification,
    container,
}) => {
    const [showConfirmRemove, setShowConfirmRemove] = useState(false);
    const [selectedTenant, setSelectedTenant] = useState({});
    const [searchTerm, setSearchTerm] = useState('');

    const { tenant, fetchUser } = useAuth();

    const items = organizationTenants.reduce((list, tenant) => {
        list.push({
            id: tenant.id,
            developerName: tenant.developerName,
            addedAt: tenant.addedAt,
            userCount: tenant.userCount,
        });

        tenant.subtenants.forEach((subtenant) => {
            list.push({
                id: subtenant.id,
                developerName: subtenant.developerName,
                addedAt: tenant.addedAt,
                userCount: subtenant.userCount,
            });
        });

        return list;
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchTenants();
    }, []);

    const onRemoveClick = (tenant) => {
        setSelectedTenant(tenant);
        setShowConfirmRemove(true);
    };

    const onRemoveConfirmClick = () => {
        removeTenant(selectedTenant.id);
        setSelectedTenant({});
        setShowConfirmRemove(false);
    };

    const [tenantsToDisplay, setTenantsToDisplay] = useState(items);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setTenantsToDisplay(items);
    }, [items.length]);

    const onSearch = (searchTerm) => {
        setSearchTerm(searchTerm);

        if (!searchTerm) {
            setTenantsToDisplay(items);
            return;
        }

        const queryResult = items.filter((tenant) =>
            stringContains(tenant.developerName, searchTerm, false),
        );

        setTenantsToDisplay(queryResult);
    };

    const removeTenant = async (idOfTenantToRemove) => {
        try {
            await removeTenantFromOrg({
                tenantId: idOfTenantToRemove,
            });

            if (idOfTenantToRemove === tenant.id) {
                // This will trigger the orgs UI to only display tenant invites
                // since the current authorized tenant has been removed from the org
                fetchUser();
            } else {
                // This will just refresh the list of org tenants currently listed.
                fetchTenants();
            }
        } catch (err) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            });
        }
    };

    return (
        <div data-testid="tenant-members">
            <h4>Tenants in the organization</h4>
            <SearchInput value={searchTerm} onChange={onSearch} />
            <Table
                wrapperClassName="margin-top"
                columns={[
                    {
                        renderHeader: () => translations.COMMON_TABLE_tenant,
                        renderCell: ({ item: tenant }) => (
                            <span title={`ID: ${tenant.id}`}>
                                <TenantName name={tenant.developerName} />
                            </span>
                        ),
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_users,
                        renderCell: ({ item: tenant }) => {
                            return <span>{tenant.userCount}</span>;
                        },
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_date_joined,
                        renderCell: ({ item: tenant }) =>
                            new Date(tenant.addedAt).toLocaleString(undefined, {
                                dateStyle: 'medium',
                                timeStyle: 'short',
                            }),
                        size: '11rem',
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_actions,
                        renderCell: ({ item: tenant }) =>
                            isSubtenant(tenant.developerName) ? (
                                <div className="table-icon" />
                            ) : (
                                <div className="action-btn-wrapper">
                                    <button
                                        title="Remove Tenant"
                                        className="table-icon table-icon-delete"
                                        aria-label="Remove Tenant"
                                        onClick={() => onRemoveClick(tenant)}
                                        type="button"
                                    >
                                        <Trash />
                                    </button>
                                </div>
                            ),
                        size: '5rem',
                    },
                ]}
                items={tenantsToDisplay}
                pagination={true}
                rowClassName={() => 'generic-row table-row-expandable'}
                renderExpandedRow={(item) => <TenantUsersDetails item={item} />}
                onRowSelect={() => true}
            />
            <ConfirmModal
                show={showConfirmRemove}
                title={translations.FORG_remove_tenant_title}
                messages={[
                    <TenantName
                        key={selectedTenant.developerName}
                        name={stringReplace(translations.FORG_remove_tenant_message, {
                            name: selectedTenant.developerName,
                        })}
                    />,
                ]}
                onConfirm={onRemoveConfirmClick}
                onCancel={() => setShowConfirmRemove(false)}
                buttonStyle="danger"
                buttonCaption="Remove"
                container={container.current}
            />
        </div>
    );
};

const mapStateToProps = ({ organizationTenants }) => ({
    organizationTenants,
});

const mapDispatchToProps = {
    fetchTenants,
    addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinedTenantList);
