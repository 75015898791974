import { type ChangeEvent, type ReactElement, createContext, useContext, useState } from 'react';
import type { OpenApiAPI, OpenApiSchemaInfo, OpenApiSecurityMe } from '../../../types';
import { isNullOrEmpty } from '../../../utils';

export type SetHasSubmitted = (state: boolean) => void;
export type SetSchemaInfo = (state: OpenApiSchemaInfo | null) => void;
export type SetOpenApiAPI = (state: OpenApiAPI | null) => void;
export type SelectInputHandler = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => void;
export type StringInputHandler = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => void;
export type TextAreaInputHandler = ({
    target: { value },
}: ChangeEvent<HTMLTextAreaElement>) => void;

interface Props {
    children?: ReactElement;
}

interface OpenApiContext {
    hasSubmitted: boolean;
    setHasSubmitted: SetHasSubmitted;
    setSchemaInfo: SetSchemaInfo;
    schemaInfo: OpenApiSchemaInfo | null;
    onSecurityChange: SelectInputHandler;
    onUserInfoUrlChange: StringInputHandler;
    onFirstNameChange: StringInputHandler;
    onLastNameChange: StringInputHandler;
    onEmailChange: StringInputHandler;
    onUsernameChange: StringInputHandler;
    onUserIdChange: StringInputHandler;
    onScopesChange: TextAreaInputHandler;
    setOpenApiAPI: SetOpenApiAPI;
    openApiAPI: OpenApiAPI | null;
    isSecurityValid: () => boolean;
}

const Context = createContext<OpenApiContext | undefined>(undefined);

const OpenApiConnectorProvider = (props: Props) => {
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [openApiAPI, setOpenApiAPI] = useState<OpenApiAPI | null>(null);
    const [schemaInfo, setSchemaInfo] = useState<OpenApiSchemaInfo | null>(null);

    const defaultMe: OpenApiSecurityMe = {
        userInfoUrl: '',
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        userId: '',
    };

    const onSecurityChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
        if (!schemaInfo) {
            return;
        }

        setHasSubmitted(false);

        if (value === 'OIDC' || value === 'NONE') {
            setSchemaInfo({ ...schemaInfo, security: value, me: defaultMe });
        } else {
            setSchemaInfo({ ...schemaInfo, security: value, scopes: '' });
        }
    };

    const onScopesChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
        if (!schemaInfo) {
            return;
        }
        setSchemaInfo({ ...schemaInfo, scopes: value });
    };

    const onUserInfoUrlChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (!schemaInfo) {
            return;
        }
        const schema = {
            ...schemaInfo,
            me: { ...(schemaInfo.me ?? defaultMe), userInfoUrl: value },
        };
        setSchemaInfo(schema);
    };

    const onFirstNameChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (!schemaInfo) {
            return;
        }
        const schema = {
            ...schemaInfo,
            me: { ...(schemaInfo.me ?? defaultMe), firstName: value },
        };
        setSchemaInfo(schema);
    };

    const onLastNameChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (!schemaInfo) {
            return;
        }
        const schema = {
            ...schemaInfo,
            me: { ...(schemaInfo.me ?? defaultMe), lastName: value },
        };
        setSchemaInfo(schema);
    };

    const onEmailChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (!schemaInfo) {
            return;
        }
        const schema = {
            ...schemaInfo,
            me: { ...(schemaInfo.me ?? defaultMe), email: value },
        };
        setSchemaInfo(schema);
    };

    const onUsernameChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (!schemaInfo) {
            return;
        }
        const schema = {
            ...schemaInfo,
            me: { ...(schemaInfo.me ?? defaultMe), username: value },
        };
        setSchemaInfo(schema);
    };

    const onUserIdChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (!schemaInfo) {
            return;
        }
        const schema = {
            ...schemaInfo,
            me: { ...(schemaInfo.me ?? defaultMe), userId: value },
        };
        setSchemaInfo(schema);
    };

    const isSecurityValid = () => {
        if (schemaInfo?.security === 'NONE') {
            return true;
        }

        if (schemaInfo?.security === 'OIDC') {
            return !isNullOrEmpty(schemaInfo?.scopes);
        }

        if (schemaInfo?.security === 'OAUTH2') {
            return !(
                isNullOrEmpty(schemaInfo.me) ||
                isNullOrEmpty(schemaInfo.me.userInfoUrl) ||
                isNullOrEmpty(schemaInfo.me.firstName) ||
                isNullOrEmpty(schemaInfo.me.lastName) ||
                isNullOrEmpty(schemaInfo.me.username) ||
                isNullOrEmpty(schemaInfo.me.email) ||
                isNullOrEmpty(schemaInfo.me.userId)
            );
        }

        if (schemaInfo?.security === 'BASIC') {
            //Either everything is filled or just the user url disallow half filled me mappings
            return (
                !isNullOrEmpty(schemaInfo.me) &&
                (!(
                    isNullOrEmpty(schemaInfo.me.userInfoUrl) ||
                    isNullOrEmpty(schemaInfo.me.firstName) ||
                    isNullOrEmpty(schemaInfo.me.lastName) ||
                    isNullOrEmpty(schemaInfo.me.username) ||
                    isNullOrEmpty(schemaInfo.me.email) ||
                    isNullOrEmpty(schemaInfo.me.userId)
                ) ||
                    (!isNullOrEmpty(schemaInfo.me.userInfoUrl) &&
                        isNullOrEmpty(schemaInfo.me.firstName) &&
                        isNullOrEmpty(schemaInfo.me.lastName) &&
                        isNullOrEmpty(schemaInfo.me.username) &&
                        isNullOrEmpty(schemaInfo.me.email) &&
                        isNullOrEmpty(schemaInfo.me.userId)))
            );
        }
        return true;
    };

    const contextValue: OpenApiContext = {
        hasSubmitted,
        setHasSubmitted,
        schemaInfo,
        setSchemaInfo,
        onSecurityChange,
        onFirstNameChange,
        onLastNameChange,
        onEmailChange,
        onUserIdChange,
        onUserInfoUrlChange,
        onUsernameChange,
        openApiAPI,
        setOpenApiAPI,
        onScopesChange,
        isSecurityValid,
    };

    return <Context.Provider value={contextValue}>{props.children}</Context.Provider>;
};

const useOpenApiConnector = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useOpenApiConnector must be used within a OpenApiConnectorProvider');
    }
    return context;
};

export { OpenApiConnectorProvider, useOpenApiConnector };
