import { type ElementRef, useEffect, useRef, useState } from 'react';
import translations from '../../../translations';
import type { ChartLine, ChartDataPoint, StateErrorsWithFilter } from '../../../types/dashboard';
import { Chart, type ChartConfiguration } from 'chart.js/auto';
import {
    insightDateFilterItems,
    convertDateFromUnixTime,
    convertDateToUnixTime,
    formatChartDate,
    getDashboardLineChartOptions,
    getFromDate,
    getSecondsToIncrement,
} from '../../../utils/dashboard';
import { View } from '../InsightsDashboard';
import InsightsDateFilter from '../InsightsDateFilter';
import classNames from 'classnames';
import { useInsights } from '../InsightsProvider';

const getChartData = (
    stateErrorsWithFilter: StateErrorsWithFilter,
    chartLine: ChartLine[],
    toDate: Date,
    fromDate: Date,
) => {
    const errorsLineData: ChartDataPoint[] = [];

    for (
        let time = convertDateToUnixTime(fromDate);
        time <= convertDateToUnixTime(toDate);
        time += getSecondsToIncrement(
            stateErrorsWithFilter.dateFilter,
            convertDateFromUnixTime(time),
        )
    ) {
        const dataWithinTimeRange = stateErrorsWithFilter.stateErrors.filter((se) => {
            const dataUnix = convertDateToUnixTime(se.dateTime);
            return (
                dataUnix > time &&
                dataUnix <
                    time +
                        getSecondsToIncrement(
                            stateErrorsWithFilter.dateFilter,
                            convertDateFromUnixTime(time),
                        )
            );
        });

        errorsLineData.push({
            x: formatChartDate(convertDateFromUnixTime(time), stateErrorsWithFilter.dateFilter),
            y: dataWithinTimeRange.length,
        });
    }

    chartLine.push({
        data: errorsLineData,
        label: 'Errors',
        tension: 0.3,
        backgroundColor: '#C73D58',
        borderColor: '#C73D58',
    });
};

interface Props {
    setCurrentView: (view: View) => void;
}

const ErrorsSummary = ({ setCurrentView }: Props) => {
    const [isHovering, setIsHovering] = useState(false);

    const canvasRef = useRef<ElementRef<'canvas'>>(null);

    const { errorsDateFilter, setErrorsDateFilter, stateErrorsWithFilter, setCustomErrorsToDate } =
        useInsights();

    // The summary page doesn't support customToDate so set the date to now and force a re-render with new data from the engine.
    useEffect(() => {
        setCustomErrorsToDate(new Date());
    }, [setCustomErrorsToDate]);

    useEffect(() => {
        let chart: Chart<
            'line',
            {
                x: string;
                y: number;
            }[],
            unknown
        > | null = null;

        const toDate = new Date();
        const fromDate = getFromDate(stateErrorsWithFilter.dateFilter, toDate);

        const chartLine: ChartLine[] = [];

        getChartData(stateErrorsWithFilter, chartLine, toDate, fromDate);

        const context = canvasRef.current?.getContext('2d');

        if (!context) {
            return;
        }

        const chartConfig: ChartConfiguration<'line', { x: string; y: number }[]> = {
            type: 'line',
            data: { datasets: chartLine },
            options: getDashboardLineChartOptions(),
        };

        chart = new Chart(context, chartConfig);

        // Cleanup the chart to prevent it from wildly animating when switching away and then back to Dashboard tab.
        return () => {
            chart?.destroy();
        };
    }, [stateErrorsWithFilter]);

    const tileClasses = classNames(
        'insights-chart-wrapper',
        'insights-tile',
        isHovering ? 'insights-tile-hover' : '',
    );

    return (
        <div
            className={tileClasses}
            onClick={() => setCurrentView(View.error)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    setCurrentView(View.error);
                }
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            role="button"
            tabIndex={0}
            data-testid="errors-dashboard-tile"
        >
            <span className="title-bar insight-tile-header">
                <h2>{translations.DASHBOARD_errors_header}</h2>
                <InsightsDateFilter
                    selectedDate={errorsDateFilter}
                    setSelectedDate={setErrorsDateFilter}
                    dateFilterItems={insightDateFilterItems}
                    setParentHovering={setIsHovering}
                />
            </span>
            <div className="insights-chart">
                <canvas ref={canvasRef} data-testid="errors-chart-canvas" />
            </div>
        </div>
    );
};

export default ErrorsSummary;
