export const BREADCRUMB_ACTION_TYPES = {
    push_breadcrumb: 'push_breadcrumb',
    set_active_breadcrumb: 'set_active_breadcrumb',
};

const breadCrumbReducer = (state, action) => {
    switch (action.type) {
        // biome-ignore lint/suspicious/noFallthroughSwitchClause: Falling through expected
        case BREADCRUMB_ACTION_TYPES.push_breadcrumb: {
            const { id, content, onClick } = action.payload;
            if (!state.trail.find((crumb) => crumb.id === id)) {
                const trail = [...state.trail, { id, content, onClick }];
                return {
                    trail,
                    activeItemId: id,
                };
            }
        }
        // biome-ignore lint/suspicious/noFallthroughSwitchClause: Falling through expected
        case BREADCRUMB_ACTION_TYPES.set_active_breadcrumb: {
            const { activeItemId } = action.payload;
            const pointer = state.trail.findIndex((crumb) => crumb.id === activeItemId);
            if (pointer !== -1) {
                const trail = state.trail.slice(0, pointer + 1);
                return {
                    trail,
                    activeItemId,
                };
            }
        }
        default:
            return state;
    }
};

export default breadCrumbReducer;
