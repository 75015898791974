import { useState, createContext, useContext, useReducer } from 'react';
import {
    getTypeListV2,
    saveType as saveTypeAPI,
    deleteType as deleteTypeAPI,
} from '../../../../ts/sources/type';
import breadCrumbReducer, { BREADCRUMB_ACTION_TYPES } from '../../../reducers/breadcrumbs';
import { guid } from '../../../../ts/utils/guid';

const Context = createContext(undefined);

const TypesProvider = ({ addNotification, tenantId, children }) => {
    const TYPE_SCREENS = {
        typeList: 'typeList',
        typeDetail: 'typeDetail',
        typePropertyDetail: 'typePropertyDetail',
        importProfile: 'importProfile',
    };

    const initialTypeToEdit = {
        serviceElementDeveloperName: null,
        properties: [],
        bindings: [],
        updateByName: false,
        serviceElementId: null,
        elementType: 'type',
        developerName: '',
        developerSummary: null,
    };

    const initialPropertyToEdit = {
        developerName: '',
        contentType: null,
        contentFormat: null,
        typeElementId: null,
        typeElementDeveloperName: null,
    };

    const [currentScreen, setCurrentScreen] = useState(TYPE_SCREENS.typeList);
    const [types, setTypes] = useState([]);
    const [typesLoading, setTypesLoading] = useState(true);
    const [typeToEdit, setTypeToEdit] = useState(initialTypeToEdit);
    const [typeToDelete, setTypeToDelete] = useState(null);
    const [propertyToEdit, setPropertyToEdit] = useState(initialPropertyToEdit);
    const [propertyToDelete, setPropertyToDelete] = useState(null);
    const [breadCrumbs, setBreadCrumbs] = useReducer(breadCrumbReducer, {
        trail: [
            {
                id: TYPE_SCREENS.typeList,
                content: 'Types',
                onClick: () => returnToTypeList(),
            },
        ],
        activeItemId: TYPE_SCREENS.typeList,
    });

    const [paging, updatePaging] = useState({
        page: 1,
        pageSize: 20,
        total: 0,
        search: '',
        orderBy: 'dateModified',
        orderDirection: 'DESC',
    });

    const [propertyPaging, updatePropertyPaging] = useState({
        page: 1,
        pageSize: 10,
        total: 0,
        search: '',
    });

    const fetchTypes = async (filters) => {
        setTypesLoading(true);

        try {
            const typesResults = await getTypeListV2(filters);
            const { items, _meta } = typesResults;

            setTypes(items);

            const { search, orderBy, orderDirection } = filters;

            updatePaging({
                ..._meta,
                search,
                orderBy,
                orderDirection,
            });
        } catch (error) {
            addNotification({
                type: 'error',
                message: error.message,
                isPersistent: true,
            });
        } finally {
            setTypesLoading(false);
        }
    };

    const saveType = async () => {
        try {
            await saveTypeAPI(typeToEdit);
            returnToTypeList();
            addNotification({
                type: 'success',
                message: `The type: ${typeToEdit.developerName} has been successfully saved.`,
                isPersistent: false,
            });
        } catch (error) {
            addNotification({
                type: 'error',
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const deleteType = async () => {
        const { id, developerName } = typeToDelete;

        try {
            await deleteTypeAPI(id);

            const remainingTypes = types.filter((type) => type.id !== id);

            setTypes(remainingTypes);
            returnToTypeList();
            addNotification({
                type: 'success',
                message: `Type: ${developerName} has been successfully deleted.`,
                isPersistent: false,
            });
        } catch (error) {
            addNotification({
                type: 'error',
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const createNewType = () => {
        setCurrentScreen(TYPE_SCREENS.typeDetail);
        setTypeToEdit(initialTypeToEdit);
        pushBreadCrumb(TYPE_SCREENS.typeDetail, 'New Type');
    };

    const importProfile = () => {
        setCurrentScreen(TYPE_SCREENS.importProfile);
    };

    const pushBreadCrumb = (id, content) => {
        setBreadCrumbs({
            type: BREADCRUMB_ACTION_TYPES.push_breadcrumb,
            payload: {
                id,
                content,
                onClick: () => setActiveBreadCrumb(id),
            },
        });
    };

    const setActiveBreadCrumb = (activeItemId) => {
        setBreadCrumbs({
            type: BREADCRUMB_ACTION_TYPES.set_active_breadcrumb,
            payload: {
                activeItemId,
            },
        });
        setCurrentScreen(activeItemId);
    };

    const editType = (type) => {
        setCurrentScreen(TYPE_SCREENS.typeDetail);
        setTypeToEdit(type);
        pushBreadCrumb(TYPE_SCREENS.typeDetail, type.developerName);
    };

    const editProperty = (property) => {
        setCurrentScreen(TYPE_SCREENS.typePropertyDetail);
        setPropertyToEdit(property);
        pushBreadCrumb(TYPE_SCREENS.typePropertyDetail, property.developerName);
    };

    const removeProperty = () => {
        setTypeToEdit({
            ...typeToEdit,
            properties: typeToEdit.properties.filter(
                (property) => property.id !== propertyToDelete.id,
            ),
        });

        returnToType();
    };

    const returnToTypeList = () => {
        setCurrentScreen(TYPE_SCREENS.typeList);
        setActiveBreadCrumb(TYPE_SCREENS.typeList);
        updatePropertyPaging({
            page: 1,
            pageSize: 10,
            total: 0,
            search: '',
        });
    };

    const returnToType = () => {
        setCurrentScreen(TYPE_SCREENS.typeDetail);
        setActiveBreadCrumb(TYPE_SCREENS.typeDetail);
    };

    const createNewTypeProperty = () => {
        setCurrentScreen(TYPE_SCREENS.typePropertyDetail);

        const newProperty = {
            id: guid(),
            developerName: '',
            contentType: null,
            contentFormat: null,
            typeElementId: null,
            typeElementDeveloperName: null,
        };

        setPropertyToEdit(newProperty);
        pushBreadCrumb(TYPE_SCREENS.typePropertyDetail, 'New Property');
    };

    const updateTypeName = (developerName) => {
        setTypeToEdit({ ...typeToEdit, developerName });
    };

    const updateTypeComments = (developerSummary) => {
        setTypeToEdit({ ...typeToEdit, developerSummary });
    };

    const applyProperty = (property) => {
        const propertyExists = typeToEdit.properties.find((p) => p.id === property.id);
        const properties = propertyExists
            ? typeToEdit.properties.map((existingProperty) =>
                  existingProperty.id === property.id ? property : existingProperty,
              )
            : [...typeToEdit.properties, property];

        setTypeToEdit({ ...typeToEdit, properties });
        returnToType();
    };

    const contextValue = {
        TYPE_SCREENS,
        fetchTypes,
        saveType,
        deleteType,
        types,
        typesLoading,
        currentScreen,
        createNewType,
        typeToEdit,
        updateTypeName,
        updateTypeComments,
        setTypeToEdit,
        createNewTypeProperty,
        editType,
        propertyToEdit,
        editProperty,
        breadCrumbs,
        pushBreadCrumb,
        returnToType,
        returnToTypeList,
        applyProperty,
        paging,
        propertyPaging,
        updatePropertyPaging,
        setTypeToDelete,
        typeToDelete,
        propertyToDelete,
        setPropertyToDelete,
        removeProperty,
        tenantId,
        addNotification,
        importProfile,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useTypes = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useTypes must be used within a TypesProvider');
    }
    return context;
};

export { TypesProvider, useTypes };
