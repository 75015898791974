import { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    fetchSentTenantInvitations,
    fetchSentUserInvitations,
} from '../../actions/reduxActions/organizations';
import TenantInvite from './TenantInvite';
import UserInvite from './UserInvite';
import PendingTenantInviteList from './PendingTenantInviteList';
import PendingUserInviteList from './PendingUserInviteList';
import JoinedTenantList from './JoinedTenantList';
import Invitations from './Invitations';
import Provision from './Provision';
import { useScrollSpy } from '../../../ts/hooks/scroll';
import Modal from '../../../ts/components/generic/modal/GenericModal';
import translations from '../../../ts/translations';
import CopyableText from '../../../ts/components/generic/CopyableText';
import { useAuth } from '../../../ts/components/AuthProvider';
import JoinedUserList from './JoinedUserList';
import LeaveOrg from './LeaveOrg';
import LocalRuntimeList from './localRuntimes/LocalRuntimesList';

/**
 * @description The UI for organization admins
 *
 * Display the UI when the user is an organization admin.
 */
const AdminUI = ({
    tenantInvitations = [],
    userInvitations = [],
    getSentTenantInvitations,
    getSentUserInvitations,
    navigateTo,
    setActiveSectionId,
    hasMenu,
}) => {
    const { tenant } = useAuth();

    const contentRef = useRef(null);

    const [showUserInvite, setShowUserInvite] = useState(false);
    const [showTenantInvite, setShowTenantInvite] = useState(false);
    const [showProvisionTenant, setShowProvisionTenant] = useState(false);

    // using a scrollspy based on the intersection observer to report back to
    // the side-menu the currently active section so the side-menu can update
    // itself (every content page with a side-menu will have to implement this
    // hook)
    useScrollSpy(contentRef, hasMenu, setActiveSectionId);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        getSentTenantInvitations();

        getSentUserInvitations();
    }, []);

    const organization = tenant.organization || {};

    return (
        <div data-testid="org-members" className="admin-page" ref={contentRef}>
            {/* Organization Details */}

            <h2 id="organizationDetails">Current Organization</h2>
            <div className="margin-bottom">
                <CopyableText
                    labelText="Name:"
                    copyableText={organization?.name ?? ''}
                    hasCopyButton={true}
                />
                <CopyableText
                    labelText="ID:"
                    copyableText={organization?.id ?? ''}
                    hasCopyButton={true}
                />
            </div>
            <LeaveOrg container={contentRef} />
            <hr />

            {/* Invitations */}

            <h2 id="organizationInvitations">Organization Invitations</h2>
            <Invitations />

            {/* Tenants */}

            <h2 id="organizationTenants">Tenants</h2>

            <div className="margin-bottom">
                <button
                    className="btn btn-primary"
                    onClick={() => setShowTenantInvite(true)}
                    type="button"
                >
                    <span className="glyphicon glyphicon-plus" />
                    Invite Tenant
                </button>
                <button
                    className="btn btn-default"
                    onClick={() => setShowProvisionTenant(true)}
                    type="button"
                >
                    <span className="glyphicon glyphicon-plus" />
                    Create Tenant
                </button>
            </div>

            <Modal
                show={showTenantInvite}
                title={translations.FORG_invite_tenant_title}
                renderBody={() => <TenantInvite closeModal={() => setShowTenantInvite(false)} />}
                onHide={() => setShowTenantInvite(false)}
            />

            <Modal
                show={showProvisionTenant}
                title={translations.FORG_provision_title}
                renderBody={() => <Provision closeModal={() => setShowProvisionTenant(false)} />}
                onHide={() => setShowProvisionTenant(false)}
            />

            <JoinedTenantList container={contentRef} />
            <PendingTenantInviteList
                invitations={tenantInvitations}
                organizationId={organization.id}
            />

            {/* Users */}

            <h2 id="organizationAdministrators">Administrators</h2>

            <div className="margin-bottom">
                <button
                    className="btn btn-primary"
                    onClick={() => setShowUserInvite(true)}
                    type="button"
                >
                    <span className="glyphicon glyphicon-plus" />
                    Invite User
                </button>
            </div>

            <Modal
                show={showUserInvite}
                title={translations.FORG_invite_user_title}
                renderBody={() => <UserInvite closeModal={() => setShowUserInvite(false)} />}
                onHide={() => setShowUserInvite(false)}
            />

            <JoinedUserList container={contentRef} />
            <PendingUserInviteList invitations={userInvitations} organizationId={organization.id} />

            {/* Local Runtimes */}

            <LocalRuntimeList navigateTo={navigateTo} container={contentRef} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    tenantInvitations: state.organizationSentTenantInvitations,
    userInvitations: state.organizationSentUserInvitations,
});

const mapDispatchToProps = {
    getSentTenantInvitations: fetchSentTenantInvitations,
    getSentUserInvitations: fetchSentUserInvitations,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUI);
