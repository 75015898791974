import ClipboardInput from '../../../../ts/components/inputs/ClipboardInput';
import translations from '../../../../ts/translations';
import { FLOW_ORG_PAGES } from '../../../../ts/constants/organization';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';

/**
 * @param {String} installationToken Installation token of the newly created connection
 * @param {Function} navigateTo Callback to navigate to a specific page
 *
 * @description Displays an installation token to be copied to the users clipboard
 */
const LocalRuntimeDisplayToken = ({ installationToken, navigateTo }) => (
    <>
        <div className="admin-page local-runtime-server-key-display">
            <h2>{translations.LOCAL_RUNTIME_token_display_h2}</h2>
            <ExAlertBanner type={AlertBannerType.WARNING} open className="block margin-bottom">
                {translations.LOCAL_RUNTIME_token_display_warning}
            </ExAlertBanner>
            <ClipboardInput
                value={installationToken}
                buttonTitle={translations.copy_server_key_title}
            />
        </div>
        <div className="admin-footer">
            <button
                type="button"
                className="btn btn-primary outcome"
                onClick={() => navigateTo(FLOW_ORG_PAGES.flowOrgAdminUI)}
            >
                {translations.LOCAL_RUNTIME_token_display_submit}
            </button>
        </div>
    </>
);

const mapStateToProps = (state, ownProps) => ({
    installationToken: pathOr(
        '',
        ['installationToken'],
        state.localRuntimes.runtimeList.find((runtime) => runtime.isEditing),
    ),
    navigateTo: ownProps.navigateTo,
});

export default connect(mapStateToProps, null)(LocalRuntimeDisplayToken);
