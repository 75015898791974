import type { AddNotification, ThemeBuildRequestAPI } from '../../types';
import ActivateBuild from '../../components/flow/ActivateBuild';
import { EnvironmentsProvider } from '../../../js/components/admin/environments/EnvironmentsProvider';
import { type Dispatch, type SetStateAction, useState, type FormEvent } from 'react';
import GenericModal from '../generic/modal/GenericModal';
import { buildTheme } from '../../sources/theme';
import { getTenantId } from '../../utils/tenant';
import { isNullOrUndefined } from '../../utils/guard';

interface Props {
    addNotification: AddNotification;
    themeId: string;
    showBuildModal: boolean;
    setShowBuildModal: Dispatch<SetStateAction<boolean>>;
    container?: HTMLDivElement | undefined;
    callback: () => void;
}

const BuildThemeModal = ({
    addNotification,
    themeId,
    showBuildModal,
    setShowBuildModal,
    container,
    callback,
}: Props) => {
    const [releaseName, setReleaseName] = useState<string | null>(null);
    const [releaseId, setReleaseId] = useState<string | null>(null);
    const [comment, setComment] = useState('');

    const closeModal = () => setShowBuildModal(false);

    const sendBuildThemeRequest = async () => {
        if (!(releaseId || releaseName)) {
            return;
        }

        const request: ThemeBuildRequestAPI = {
            themeIds: [themeId],
            releaseName,
            comment,
            releaseId,
        };

        await buildTheme(request);
        closeModal();
        callback();
    };

    const onCommentChanged = ({ currentTarget: { value } }: FormEvent<HTMLTextAreaElement>) =>
        setComment(value);

    const onReleaseIdChanged = (id: string | null) => {
        setReleaseId(id);
    };

    const onReleaseNameChanged = (name: string | null) => {
        setReleaseName(name);
    };

    return (
        <GenericModal
            container={container}
            title="Build Theme"
            onHide={closeModal}
            show={showBuildModal}
            renderBody={() => (
                <EnvironmentsProvider addNotification={addNotification} tenantId={getTenantId()}>
                    <ActivateBuild
                        isThemeBuild={true}
                        onReleaseIdChanged={onReleaseIdChanged}
                        onReleaseNameChanged={onReleaseNameChanged}
                        onCommentChanged={onCommentChanged}
                    />
                </EnvironmentsProvider>
            )}
            renderFooter={() => (
                <>
                    <button type="button" onClick={closeModal} className="btn btn-default">
                        Close
                    </button>
                    <button
                        type="button"
                        onClick={sendBuildThemeRequest}
                        className="btn btn-primary"
                        disabled={isNullOrUndefined(releaseId) && isNullOrUndefined(releaseName)}
                    >
                        Build Theme
                    </button>
                </>
            )}
        />
    );
};

export default BuildThemeModal;
