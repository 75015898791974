import { prop, sortBy, assocPath } from 'ramda';
import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import breadCrumbReducer, { BREADCRUMB_ACTION_TYPES } from '../../../../reducers/breadcrumbs';
import { getTenantId } from '../../../../../ts/utils/tenant';
import { guid } from '../../../../../ts/utils/guid';
import { isNullOrEmpty } from '../../../../../ts/utils/guard';
import { createNewOutcomeAndNewMapElement, filterOutNotes } from '../../../graph/utils';
import { FRIENDLY_SCREEN_NAMES } from '../common/constants';
import { COLLABORATION_ITEM_TYPES, FLOW_EDITING_TOKEN } from '../../../../../ts/constants';
import screens from '../common/screens';
import { useCollaboration } from '../../../../../ts/collaboration/CollaborationProvider';
import { getMapElement, saveMapElement } from '../../../../../ts/sources/graph';
import { useAuth } from '../../../../../ts/components/AuthProvider';
import { useGraph } from '../../../graph/GraphProvider';

const Context = createContext(undefined);

/**
 * The core map element context provider.
 * Every map element needs to subscribe to this context.
 */
const MapElementProvider = ({
    groupElementId = null,
    defaultScreen,
    mapElements,
    elementType,
    id,
    x,
    y,
    editingToken,
    flowId,
    notifyError,
    container,
    children,
    dismissMapElementConfig,
    fromMapElementId = null,
    isLoading,
    setIsLoading,
    refreshFlow,
    focusAndSelectElement,
}) => {
    const tenantId = getTenantId();
    const { user } = useAuth();

    const currentUserId = user.id;

    const [mapElementId, setMapElementId] = useState(id);
    const [isLoadingMapElement, setIsLoadingMapElement] = useState(true);
    const [selectedOutcomeIndex, setSelectedOutcomeIndex] = useState(null);
    const [selectedNavigationIndex, setSelectedNavigationIndex] = useState(null);
    const [currentScreen, setCurrentScreen] = useState(defaultScreen);
    const [configTitle, setConfigTitle] = useState('');

    try {
        // biome-ignore lint/correctness/useHookAtTopLevel: Treat warnings as errors, fix later
        const graphContext = useGraph();
        isLoading = graphContext.isLoading;
        setIsLoading = graphContext.setIsLoading;
    } catch (_event) {
        // This component is not being used within the GraphProvider.
        // Do nothing.
    }

    const { invoke, itemOpened, itemClosed, itemChanged, getItem } = useCollaboration();

    const breadCrumbLabel = FRIENDLY_SCREEN_NAMES[defaultScreen];

    const [breadCrumbs, setBreadCrumbs] = useReducer(breadCrumbReducer, {
        trail: [
            {
                id: defaultScreen,
                content: breadCrumbLabel ?? '',
                onClick: () => onReturnToDefaultScreen(),
            },
        ],
        activeItemId: defaultScreen,
    });

    const setMapElement = (newMapElement) => {
        itemChanged(mapElementId, 1, newMapElement, !!id);
    };

    const onSwitchScreen = (screen) => {
        if (!Object.prototype.hasOwnProperty.call(screens, screen)) {
            throw Error(`The screen named: ${screen} is not valid`);
        }

        setCurrentScreen(screen);
    };

    const onPushBreadCrumb = (id, content) => {
        setBreadCrumbs({
            type: BREADCRUMB_ACTION_TYPES.push_breadcrumb,
            payload: {
                id,
                content,
                onClick: () => onSetActiveBreadCrumb(id),
            },
        });
    };

    const onSetActiveBreadCrumb = (activeItemId) => {
        setBreadCrumbs({
            type: BREADCRUMB_ACTION_TYPES.set_active_breadcrumb,
            payload: {
                activeItemId,
            },
        });
        onSwitchScreen(activeItemId);
    };

    const onReturnToDefaultScreen = () => {
        onSetActiveBreadCrumb(defaultScreen);
    };

    const onSaveMapElement = async (newMapElement) => {
        setIsLoading(true);

        try {
            const newMapElementResponse = await saveMapElement(
                newMapElement,
                flowId,
                FLOW_EDITING_TOKEN,
            );
            invoke('GraphChanged', flowId, [newMapElementResponse.id], null);
            onClose(newMapElementResponse.id);
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }

        if (fromMapElementId) {
            await createNewOutcomeAndNewMapElement(
                fromMapElementId,
                mapElements,
                newMapElement.developerName,
                newMapElement.id,
                (mapElement) => saveMapElement(mapElement, flowId, FLOW_EDITING_TOKEN),
                (mapElementId) => getMapElement(mapElementId, flowId, FLOW_EDITING_TOKEN),
                notifyError,
            );
            onClose(fromMapElementId);
            setIsLoading(false);
        }
    };

    // Provide an optional ID that is used instead. Useful for new elements that don't have an ID yet.
    const onClose = (newElementId = id, refresh = true) => {
        if (refresh) {
            refreshFlow();
        }
        dismissMapElementConfig();
        itemClosed(id, COLLABORATION_ITEM_TYPES.mapElement, !!id);
        focusAndSelectElement(newElementId);
    };

    const onUpdateName = (developerName) => {
        setMapElement({ ...getItem(mapElementId), developerName });
    };

    const onUpdateTitle = (title) => {
        setMapElement({ ...getItem(mapElementId), title });
    };

    const onUpdateComments = (developerSummary) => {
        setMapElement({ ...getItem(mapElementId), developerSummary });
    };

    const onUpdateStatusMessage = (statusMessage) => {
        setMapElement({ ...getItem(mapElementId), statusMessage });
    };

    const onUpdateNotAuthorizedMessage = (notAuthorizedMessage) => {
        setMapElement({ ...getItem(mapElementId), notAuthorizedMessage });
    };

    const onUpdateSubFlowArguments = (selectors) => {
        const subFlowArguments = selectors
            ? selectors.reduce((acc, selector) => {
                  if (selector.selectedValueMeta) {
                      acc.push({
                          valueElementInSubflowId: {
                              id: selector.formalValueId || null,
                          },
                          valueElementToApplyId: {
                              id: selector.selectedValueMeta.id || null,
                              typeElementPropertyId:
                                  selector.selectedValueMeta.typeElementPropertyId || null,
                          },
                      });
                  }
                  return acc;
              }, [])
            : null;

        setMapElement(assocPath(['subflow', 'arguments'], subFlowArguments, getItem(mapElementId)));
    };

    const onEditOutcome = (outcomeIndex) => {
        setSelectedOutcomeIndex(outcomeIndex);
        onSwitchScreen(screens.outcome);
        onPushBreadCrumb(screens.outcome, screens.outcome);
    };

    const onDeleteOutcome = (outcomeIndex) => {
        const mapElement = getItem(mapElementId);
        const newMapElement = {
            ...mapElement,
            outcomes: mapElement.outcomes.filter((_, index) => index !== outcomeIndex),
        };

        setMapElement(newMapElement);
    };

    const onEditNavigation = (navigationIndex) => {
        setSelectedNavigationIndex(navigationIndex);
        onSwitchScreen(screens.navigationOverrides);
        onPushBreadCrumb(screens.navigationOverrides, screens.navigationOverrides);
    };

    const onDeleteNavigation = (navigationIndex) => {
        const mapElement = getItem(mapElementId);
        setMapElement({
            ...mapElement,
            navigationOverrides: mapElement.navigationOverrides.filter(
                (_, index) => index !== navigationIndex,
            ),
        });
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const load = async () => {
            setIsLoading(true);

            let mapElement = null;

            try {
                if (isNullOrEmpty(id)) {
                    mapElement = {
                        groupElementId,
                        elementType,
                        x,
                        y,
                        developerName: '',
                        id: guid(),
                        dataActions: [],
                        messageActions: [],
                        listeners: [],
                        operations: [],
                        processes: [],
                        subflow: null,
                    };
                } else {
                    mapElement = await getMapElement(id, flowId);
                }

                mapElement.operations = sortBy(prop('order'), mapElement?.operations ?? []);
                mapElement.dataActions = sortBy(prop('order'), mapElement?.dataActions ?? []);
                mapElement.messageActions = sortBy(prop('order'), mapElement?.messageActions ?? []);
                mapElement.processes = sortBy(prop('order'), mapElement?.processes ?? []);
                mapElement.listeners = mapElement?.listeners ?? [];
                mapElement.navigationOverrides = mapElement?.navigationOverrides ?? [];
                mapElement.outcomes = sortBy(prop('order'), mapElement?.outcomes ?? []);

                setMapElementId(mapElement.id);

                itemOpened(
                    mapElement.id,
                    flowId,
                    COLLABORATION_ITEM_TYPES.mapElement,
                    mapElement,
                    !!id,
                );
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
                setIsLoadingMapElement(false);
            }
        };

        load();
    }, []);

    const mapElement = getItem(mapElementId);

    if (mapElement) {
        mapElement.dataActions = sortBy(prop('order'), mapElement?.dataActions ?? []);
        mapElement.messageActions = sortBy(prop('order'), mapElement?.messageActions ?? []);
        mapElement.processes = sortBy(prop('order'), mapElement?.processes ?? []);
        mapElement.listeners = mapElement?.listeners ?? [];
        mapElement.navigationOverrides = mapElement?.navigationOverrides ?? [];
        mapElement.outcomes = sortBy(prop('order'), mapElement?.outcomes ?? []);
    }

    const contextValue = {
        id: id,
        title: configTitle && configTitle.length > 0 ? configTitle : mapElement?.developerName,
        elementType: mapElement?.elementType ?? elementType,
        onHide: onClose,
        container,
        currentUserId,
        tenantId,
        editingToken,
        flowId,
        mapElement,
        currentScreen,
        breadCrumbs,
        onReturnToDefaultScreen,
        onSaveMapElement,
        onClose,
        onUpdateName,
        onUpdateTitle,
        onUpdateComments,
        onUpdateStatusMessage,
        onUpdateNotAuthorizedMessage,
        onUpdateSubFlowArguments,
        onPushBreadCrumb,
        onSetActiveBreadCrumb,
        setMapElement,
        onSwitchScreen,
        setConfigTitle,
        mapElements: filterOutNotes(mapElements),
        onEditOutcome,
        onDeleteOutcome,
        selectedOutcomeIndex,
        setSelectedOutcomeIndex,
        defaultScreen,
        onEditNavigation,
        onDeleteNavigation,
        selectedNavigationIndex,
        setSelectedNavigationIndex,
        notifyError,
    };

    if (isLoading || isLoadingMapElement) {
        return <></>;
    }

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useMapElement = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useMapElement must be used within a MapElementProvider');
    }
    return context;
};

export { MapElementProvider, useMapElement };
