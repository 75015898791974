import { Provider } from 'react-redux';
import store from '../js/store/store';
import { AuthProvider } from '../ts/components/AuthProvider';
import Root from './components/Root';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import '@boomi/exosphere/dist/styles.css';

window.React = React;

const root = createRoot(document.getElementById('root') as Element);
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <AuthProvider>
                <Root />
            </AuthProvider>
        </Provider>
    </BrowserRouter>,
);

export default root;
