import Select, { type SingleValue } from 'react-select';
import { getAllServices } from '../../sources/service';
import { useState, useEffect } from 'react';
import type { ServiceElementResponseAPI } from '../../types/service';
import { getSharedStyles } from '../../utils/select';

interface ConnectorDropdownProps {
    selectedConnectorId: string | null;
    onChange: (selection: SingleValue<{ value: string; label: string | null }>) => void;
    onError: (message: unknown) => void;
}

const ConnectorDropdown = ({ selectedConnectorId, onChange, onError }: ConnectorDropdownProps) => {
    const [connectors, setConnectors] = useState<ServiceElementResponseAPI[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchConnectors = async () => {
        try {
            const connectorsResults: ServiceElementResponseAPI[] = await getAllServices();

            setConnectors(connectorsResults);
        } catch (error) {
            onError((error as Error).message);
        } finally {
            setIsLoading(false);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        fetchConnectors();
    }, []);

    const connectorOptions = connectors.map((connector) => ({
        value: connector.id,
        label: connector.developerName,
    }));

    const selectedConnector = connectors.find((connector) => connector.id === selectedConnectorId);

    return (
        <>
            {isLoading ? (
                'Loading connectors'
            ) : (
                <Select
                    styles={getSharedStyles<(typeof connectorOptions)[number]>()}
                    inputId="connector-dropdown"
                    name="connector-dropdown"
                    isMulti={false}
                    options={connectorOptions}
                    onChange={onChange}
                    placeholder="Select a connector"
                    value={
                        selectedConnector
                            ? {
                                  value: selectedConnector.id,
                                  label: selectedConnector.developerName,
                              }
                            : null
                    }
                    noOptionsMessage={() => 'No results found'}
                    isClearable
                />
            )}
        </>
    );
};

export default ConnectorDropdown;
