import { useState } from 'react';
import type { NotifyError } from '../../types';
import ScreenRouter from '../generic/ScreenRouter';
import translations from '../../translations';
import { AnomalyDashboardProvider } from '../anomalyDetection/AnomalyProvider';
import AnomalySummary from './insightsSummary/AnomalySummary';
import AnomalyDashboard from '../anomalyDetection/AnomalyDashboard';
import UsageSummary from './insightsSummary/UsageSummary';
import ErrorsSummary from './insightsSummary/ErrorsSummary';
import ErrorsInsights from './insightsDetailed/ErrorsInsights';
import { InsightsProvider } from './InsightsProvider';
import UsageInsights from './insightsDetailed/UsageInsights';

import './developer-dashboard.less';

export enum View {
    summary = 0,
    usage = 1,
    anomaly = 2,
    error = 3,
}

interface Props {
    notifyError: NotifyError;
}

const InsightsDashboard = ({ notifyError }: Props) => {
    const [currentView, setCurrentView] = useState<View>(View.summary);

    const SummaryDashboardView = (_: { screen?: string }) => (
        <>
            <span className="title-bar">
                <h1>{translations.DASHBOARD_header}</h1>
            </span>
            <div className="insights-content">
                <UsageSummary notifyError={notifyError} setCurrentView={setCurrentView} />
                <ErrorsSummary setCurrentView={setCurrentView} />
                <AnomalySummary setCurrentView={setCurrentView} />
            </div>
        </>
    );

    const UsageInsightsDetailed = (_: { screen?: string }) => (
        <UsageInsights notifyError={notifyError} setCurrentView={setCurrentView} />
    );

    const ErrorsInsightsDetailed = (_: { screen?: string }) => (
        <ErrorsInsights setCurrentView={setCurrentView} />
    );

    return (
        <div className="admin-page">
            <AnomalyDashboardProvider notifyError={notifyError} initialDateIndex={0}>
                <InsightsProvider notifyError={notifyError}>
                    <ScreenRouter currentScreen={currentView.toString()}>
                        <SummaryDashboardView screen={View.summary.toString()} />
                        <UsageInsightsDetailed screen={View.usage.toString()} />
                        <AnomalyDashboard
                            screen={View.anomaly.toString()}
                            setCurrentView={setCurrentView}
                        />
                        <ErrorsInsightsDetailed screen={View.error.toString()} />
                    </ScreenRouter>
                </InsightsProvider>
            </AnomalyDashboardProvider>
        </div>
    );
};

export default InsightsDashboard;
