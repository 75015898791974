import { useEffect, useRef, useState, type ElementRef } from 'react';
import type { NotifyError } from '../../../types';
import { useInsights } from '../InsightsProvider';
import { Chart, type ActiveElement, type ChartConfiguration, type ChartEvent } from 'chart.js';
import {
    getUsageForTenant,
    type FlowUsageDashboardResponse,
    type UsageDashboardBucket,
} from '../../../sources/dashboard';
import type { ChartDataPoint, ChartLine, TimeFilters } from '../../../types/dashboard';
import {
    convertDateFromUnixTime,
    convertDateToUnixTime,
    formatChartDate,
    getDashboardLineChartOptions,
    getFromDate,
    getLaunchesPercentage,
    getSecondsToIncrement,
    insightDateFilterItems,
    onGoToFlow,
    onHandlePointClick,
} from '../../../utils/dashboard';
import translations from '../../../translations';
import { View } from '../InsightsDashboard';
import FormGroup from '../../generic/FormGroup';
import InsightsDateFilter from '../InsightsDateFilter';
import Table, { type TableColumnList } from '../../generic/Table';
import { useNavigate } from 'react-router-dom';
import { useAnomalyDashboard } from '../../anomalyDetection/AnomalyProvider';
import { getSharedStyles, stringReplace } from '../../../utils';
import Select, { type MultiValue } from 'react-select';
import { ExEmptyState } from '@boomi/exosphere';
import InsightsDateRange from '../InsightsDateRange';

interface Props {
    notifyError: NotifyError;
    setCurrentView: (view: View) => void;
}

const getChartData = (
    flowUsage: FlowUsageDashboardResponse[],
    toDate: Date,
    fromDate: Date,
    dateRange: TimeFilters,
) => {
    const usageLine: ChartLine[] = [];
    const usagePoint: ChartDataPoint[] = [];

    for (
        let time = convertDateToUnixTime(fromDate);
        time <= convertDateToUnixTime(toDate);
        time += getSecondsToIncrement(dateRange, convertDateFromUnixTime(time))
    ) {
        const bucketsInRange: UsageDashboardBucket[] = [];

        flowUsage.forEach((usage) => {
            const dataInTimeRange = usage.flowLaunchData.filter((usageData) => {
                const dataUnix = convertDateToUnixTime(usageData.bucket);
                return (
                    dataUnix > time &&
                    dataUnix <
                        time + getSecondsToIncrement(dateRange, convertDateFromUnixTime(time))
                );
            });

            bucketsInRange.push(...dataInTimeRange);
        });

        const usageForTimeBucket = bucketsInRange.reduce(
            (accumulator, currentValue) => accumulator + currentValue.counter,
            0,
        );

        usagePoint.push({
            x: formatChartDate(convertDateFromUnixTime(time), dateRange),
            y: usageForTimeBucket,
            rawDate: convertDateFromUnixTime(time),
        });
    }

    usageLine.push({
        data: usagePoint,
        label: 'Usage',
        tension: 0.3,
    });

    return usageLine;
};

const filterFlowUsage = (usageToFilter: FlowUsageDashboardResponse[], selectedFlows: string[]) => {
    const filteringUsage = usageToFilter
        .filter((u) => (selectedFlows.length > 0 ? selectedFlows.includes(u.flowId) : true))
        .sort((uA, uB) => uB.flowLaunchTotal - uA.flowLaunchTotal);

    return filteringUsage;
};

const UsageInsights = ({ notifyError, setCurrentView }: Props) => {
    const [flowUsage, setFlowUsage] = useState<FlowUsageDashboardResponse[]>([]);
    const [filteredFlowUsage, setFilteredFlowUsage] = useState<FlowUsageDashboardResponse[]>([]);
    const [selectedFlows, setSelectedFlows] = useState<string[]>([]);

    const lineCanvasRef = useRef<ElementRef<'canvas'>>(null);
    const doughnutCanvasRef = useRef<ElementRef<'canvas'>>(null);

    const {
        usageDateFilter,
        setUsageDateFilter,
        stateErrorsForUsageData,
        customUsageToDate,
        setCustomUsageToDate,
    } = useInsights();
    const { data: anomalyEvents } = useAnomalyDashboard();
    const navigate = useNavigate();

    useEffect(() => {
        let lineChart: Chart<
            'line',
            {
                x: string;
                y: number;
            }[],
            unknown
        > | null = null;

        let doughnutChart: Chart<'doughnut', number[], unknown> | null = null;

        getUsageForTenant(usageDateFilter as TimeFilters, customUsageToDate)
            .then((response) => {
                setFlowUsage(response);
                const filteringUsage = filterFlowUsage(response, selectedFlows);

                // The Flow filter is on but nothing is found, likely due to a date filter change. Remove the filter.
                if (selectedFlows.length > 0 && filteringUsage.length === 0) {
                    setSelectedFlows([]);
                    return;
                }

                setFilteredFlowUsage(filteringUsage);

                if (filteringUsage.length === 0) {
                    return;
                }

                const generateLineChart = (
                    flowUsage: FlowUsageDashboardResponse[],
                    dateFilter: TimeFilters,
                ) => {
                    const fromDate = getFromDate(dateFilter, customUsageToDate);
                    const chartLine = getChartData(
                        flowUsage,
                        customUsageToDate,
                        fromDate,
                        dateFilter,
                    );

                    const context = lineCanvasRef.current?.getContext('2d');

                    if (!context) {
                        return null;
                    }

                    const chartConfig: ChartConfiguration<'line', { x: string; y: number }[]> = {
                        type: 'line',
                        data: { datasets: chartLine },
                        options: getDashboardLineChartOptions(
                            (_event: ChartEvent, elements: ActiveElement[]) =>
                                onHandlePointClick(
                                    chartLine[0].data[elements[0].index],
                                    usageDateFilter,
                                    setUsageDateFilter,
                                    setCustomUsageToDate,
                                ),
                        ),
                    };

                    return new Chart(context, chartConfig);
                };

                const generateDoughnutChart = (flowUsage: FlowUsageDashboardResponse[]) => {
                    const doughnutChartData: ChartDataPoint[] = flowUsage.map((fu) => ({
                        x: fu.flowName,
                        y: fu.flowLaunchData.reduce(
                            (accu, currentValue: UsageDashboardBucket) =>
                                accu + currentValue.counter,
                            0,
                        ),
                    }));

                    const context = doughnutCanvasRef.current?.getContext('2d');

                    if (!context) {
                        return null;
                    }

                    const chartConfig: ChartConfiguration<'doughnut', number[]> = {
                        type: 'doughnut',
                        data: {
                            datasets: [{ data: doughnutChartData.map((dd) => dd.y) }],
                            labels: doughnutChartData.map((dd) => dd.x),
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: true,
                            plugins: {
                                legend: {
                                    position: 'top',
                                    labels: { boxWidth: 35 },
                                    maxWidth: 150,
                                },
                            },
                        },
                    };

                    return new Chart(context, chartConfig);
                };

                lineChart = generateLineChart(filteringUsage, usageDateFilter);
                doughnutChart = generateDoughnutChart(filteringUsage);
            })
            .catch(notifyError);

        // Cleanup the chart to prevent it from wildly animating when switching away and then back to Dashboard tab.
        return () => {
            lineChart?.destroy();
            doughnutChart?.destroy();
        };
    }, [
        usageDateFilter,
        notifyError,
        selectedFlows,
        customUsageToDate,
        setCustomUsageToDate,
        setUsageDateFilter,
    ]);

    const handleSelectionChange = (option: MultiValue<{ value: string; label: string }>) => {
        if (option) {
            setSelectedFlows(option.map((o) => o.value));
        } else {
            setSelectedFlows([]);
        }
    };

    const getErrorsInFlow = (flowId: string) =>
        stateErrorsForUsageData.filter((se) => se.flowId === flowId).length;

    const columns: TableColumnList<FlowUsageDashboardResponse> = [
        {
            renderHeader: () => translations.COMMON_TABLE_flow_name,
            renderCell: ({ item }) => <span title={item.flowName}>{item.flowName}</span>,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_usage,
            renderCell: ({ item }) => <span className="dash-pill">{item.flowLaunchTotal}</span>,
            size: '7rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_usage_percentage,
            renderCell: ({ item }) => (
                <span className="dash-pill">
                    {getLaunchesPercentage(item.flowLaunchTotal, filteredFlowUsage)}%
                </span>
            ),
            size: '8rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_anomalies,
            renderCell: ({ item }) => (
                <span className="dash-pill">
                    {
                        anomalyEvents.filter(
                            (anomalyEvent) =>
                                anomalyEvent.flowId === item.flowId && anomalyEvent.isAnomalous,
                        ).length
                    }
                </span>
            ),
            size: '8rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_errors,
            renderCell: ({ item }) => (
                <span className="dash-pill">{getErrorsInFlow(item.flowId)}</span>
            ),
            size: '5rem',
        },
        {
            renderHeader: () => '',
            renderCell: ({ item }) => (
                <button
                    className="link-emulate"
                    onClick={() => onGoToFlow(item.flowId, navigate)}
                    title={stringReplace(translations.DASHBOARD_go_to_flow_title, item.flowName)}
                    type="button"
                >
                    {translations.DASHBOARD_inpect}
                </button>
            ),
            size: '5rem',
        },
    ];

    const hasData = filteredFlowUsage.length > 0;

    return (
        <>
            <span className="title-bar">
                <h1>
                    <button
                        className="link-emulate"
                        onClick={() => setCurrentView(View.summary)}
                        type="button"
                    >
                        {translations.DASHBOARD_header}
                    </button>
                    {' > '}
                    {translations.DASHBOARD_usage_header}
                </h1>
            </span>
            <div className="insights-control-bar">
                <div className="insights-control-group">
                    <div className="inights-filter-bar-control">
                        <FormGroup
                            label={translations.DASHBOARD_flow_name}
                            htmlFor="usageInsightsFlowFilter"
                        >
                            <Select
                                styles={getSharedStyles<{ label: string; value: string }, true>()}
                                inputId="usageInsightsFlowFilter"
                                name="usageInsightsFlowFilter"
                                isClearable={selectedFlows.length > 0}
                                placeholder={translations.DASHBOARD_errors_filter_flow_playerholder}
                                options={flowUsage.map((fu) => ({
                                    label: fu.flowName,
                                    value: fu.flowId,
                                }))}
                                onChange={(selectedOption) => handleSelectionChange(selectedOption)}
                                value={selectedFlows.map((sf) => ({
                                    label: flowUsage.find((f) => f.flowId === sf)?.flowName ?? '',
                                    value: sf,
                                }))}
                                isMulti={true}
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="insights-date-range">
                    <InsightsDateRange
                        toDate={customUsageToDate}
                        dateRange={usageDateFilter}
                        onChangeToDate={(toDate) => setCustomUsageToDate(toDate)}
                    />
                    <InsightsDateFilter
                        selectedDate={usageDateFilter}
                        setSelectedDate={setUsageDateFilter}
                        dateFilterItems={insightDateFilterItems}
                    />
                </div>
            </div>
            {/* We have to keep the canvas in the DOM otherwise the chart won't render if switching from a state of no data */}
            <span className={hasData ? '' : 'hidden'}>
                <div className="chart-area margin-bottom-large">
                    <div className="line-chart-section">
                        <h2>{translations.DASHBOARD_error_line_header}</h2>
                        <div className="insights-chart">
                            <canvas ref={lineCanvasRef} data-testid="usage-line-chart-canvas" />
                        </div>
                    </div>
                    <div className="pie-chart-section">
                        <h2>{translations.DASHBOARD_error_pie_header}</h2>
                        <div className="insights-pie-chart">
                            <canvas
                                ref={doughnutCanvasRef}
                                data-testid="usage-doughnut-chart-canvas"
                            />
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns}
                    items={filteredFlowUsage}
                    rowClassName={() => 'generic-row generic-row-tall'}
                />
            </span>
            {hasData ? null : (
                <div className="insights-empty-state">
                    <ExEmptyState
                        label={translations.DASHBOARD_no_usage}
                        text={translations.DASHBOARD_no_usage_unfilterd}
                    />
                </div>
            )}
        </>
    );
};

export default UsageInsights;
